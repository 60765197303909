import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import PrimaryButtonSize from "./PrimaryButtonSize";
import PrimaryButtonTheme from "./PrimaryButtonTheme";

import "./primary-button.scss";

interface IconComponentProps {
    className?: string;
}

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    size?: PrimaryButtonSize;
    theme?: PrimaryButtonTheme;
    disabled?: boolean;
    loading?: boolean;
    showOnlyIconOnMobile?: boolean;
    iconLeading?: Omit<React.DetailedReactHTMLElement<IconComponentProps, HTMLElement>, "ref">;
    iconTrailing?: Omit<React.DetailedReactHTMLElement<IconComponentProps, HTMLElement>, "ref">;
}

const PrimaryButton = ({
    className,
    size = PrimaryButtonSize.Regular,
    theme = PrimaryButtonTheme.Dandelion,
    children,
    iconLeading,
    iconTrailing,
    disabled = false,
    loading = false,
    showOnlyIconOnMobile = false,
    onClick,
    ...props
}: Props) => {
    const handleOnClick: React.MouseEventHandler<HTMLButtonElement> = e => {
        if (onClick && !loading && !disabled) {
            onClick(e);
        }
    };

    const classes = classNames(
        "primary-button",
        `primary-button--${size}`,
        `primary-button--${theme}`,
        className,
        {
            "primary-button--loading": loading,
            "primary-button--disabled": disabled,
            "primary-button--mobile-icon-only": showOnlyIconOnMobile
        }
    );

    return (
        <button
            type="button"
            className={classes}
            disabled={disabled}
            onClick={handleOnClick}
            {...props}
        >
            {iconLeading &&
                React.cloneElement(iconLeading, {
                    className: classNames(
                        "primary-button__icon",
                        "primary-button__icon--leading",
                        iconLeading.props.className
                    )
                })}
            <div className="primary-button__text">{children}</div>
            {iconTrailing &&
                React.cloneElement(iconTrailing, {
                    className: classNames(
                        "primary-button__icon",
                        "primary-button__icon--trailing",
                        iconTrailing.props.className
                    )
                })}
            {loading && <div className="primary-button__loader" />}
        </button>
    );
};

PrimaryButton.propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    theme: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    children: PropTypes.node,
    showOnlyIconOnMobile: PropTypes.bool,
    iconLeading: PropTypes.node,
    iconTrailing: PropTypes.node,
    onClick: PropTypes.func
};

PrimaryButton.Size = PrimaryButtonSize;
PrimaryButton.Theme = PrimaryButtonTheme;

export default PrimaryButton;
