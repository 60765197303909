import type { TFunction } from "react-i18next";

import ActivityType from "@core/enums/ActivityType";
import DeibSurveyStepType from "@core/enums/DeibSurveyStepType";

import buildDeibSurveyQuestion from "./buildDeibSurveyQuestion";
import type { DeibQuestionID } from "./types";

const buildTestDeibSurveyQuestionActivity = (
    questionId: DeibQuestionID,
    t: TFunction<"activities">
) => {
    const question = buildDeibSurveyQuestion(questionId, t);

    const askQuestionStep = {
        ...question,
        stepType: DeibSurveyStepType.AskQuestion
    };

    return {
        correlationId: "test-correlation-id",
        questionIds: [question.questionId],
        customizationWord: { sourceWord: "test", replacementWord: "test" },
        activityType: ActivityType.TestDeibSurvey as const,
        steps: [askQuestionStep]
    };
};

export default buildTestDeibSurveyQuestionActivity;
