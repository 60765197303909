import React from "react";

import PropTypes from "prop-types";

import DeibSurveyLikertQuestion from "@components/deib-survey-question/DeibSurveyLikertQuestion";
import DeibSurveyMultipleChoiceQuestion from "@components/deib-survey-question/DeibSurveyMultipleChoiceQuestion";
import DeibSurveyTwoWaySliderQuestion from "@components/deib-survey-question/DeibSurveyTwoWaySliderQuestion";

import mapDeibSurveyValueToScore from "@core/activities/deib-survey/mapDeibSurveyValueToScore";
import type DeibSurveyQuestionDisplayType from "@core/enums/DeibSurveyQuestionDisplayType";
import DisplayType from "@core/enums/DeibSurveyQuestionDisplayType";

interface Props {
    displayType: DeibSurveyQuestionDisplayType;
    questionId: string;
    question: string;
    minLabel?: string;
    maxLabel?: string;
    labels?: string[];
    imageUrls?: string[];
    answer?: number | null;
    onSliderQuestionAnswered: (value: number, score: number) => void;
    onLikertQuestionAnswered: (value: number, score: number) => void;
    onMultipleChoiceQuestionAnswered: (value: number, score: number) => void;
    onIntroAnimationCompleted: () => void;
    onAnimationCompleted: () => void;
}

const DeibSurveyActivityQuestionStep = ({
    displayType,
    questionId,
    question,
    answer,
    labels,
    imageUrls,
    minLabel,
    maxLabel,
    onSliderQuestionAnswered,
    onLikertQuestionAnswered,
    onMultipleChoiceQuestionAnswered,
    onAnimationCompleted,
    onIntroAnimationCompleted
}: Props) => {
    const handleOnSubmit = (callback: (value: number, score: number) => void) => (value: number) => {
        callback(value, mapDeibSurveyValueToScore(displayType, value));
    };

    switch (displayType) {
        // HACK: Our props are polymorphic based on displayType. This wasn't an
        // issue when we were in plain JavaScript, but this understandably makes
        // TypeScript balk. Until we decide to refactor this, we'll just use
        // non-null assertions (!).
        case DisplayType.TwoWaySlider:
            return (
                <DeibSurveyTwoWaySliderQuestion
                    key={questionId}
                    question={question}
                    minLabel={minLabel}
                    maxLabel={maxLabel}
                    imageUrls={imageUrls!}
                    onChange={handleOnSubmit(onSliderQuestionAnswered)}
                    initialValue={answer}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        case DisplayType.LikertScale:
            return (
                <DeibSurveyLikertQuestion
                    key={questionId}
                    question={question}
                    labels={labels!}
                    onChange={handleOnSubmit(onLikertQuestionAnswered)}
                    initialValue={answer}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        case DisplayType.MultipleChoice:
            return (
                <DeibSurveyMultipleChoiceQuestion
                    key={questionId}
                    question={question}
                    labels={labels!}
                    imageUrls={imageUrls!}
                    onChange={handleOnSubmit(onMultipleChoiceQuestionAnswered)}
                    initialValue={answer}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        default:
            throw new Error(`Display Type (${displayType}) not supported`);
    }
};

DeibSurveyActivityQuestionStep.propTypes = {
    displayType: PropTypes.string.isRequired,
    questionId: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    minLabel: PropTypes.string,
    maxLabel: PropTypes.string,
    labels: PropTypes.array,
    imageUrls: PropTypes.arrayOf(PropTypes.string),
    answer: PropTypes.any,
    onSliderQuestionAnswered: PropTypes.func.isRequired,
    onLikertQuestionAnswered: PropTypes.func.isRequired,
    onMultipleChoiceQuestionAnswered: PropTypes.func.isRequired,
    onIntroAnimationCompleted: PropTypes.func.isRequired,
    onAnimationCompleted: PropTypes.func.isRequired
};

export default DeibSurveyActivityQuestionStep;