import React, { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import useDelayedEffect from "@hooks/useDelayedEffect";

import RecognitionCategoryTheme from "@core/enums/RecognitionCategoryTheme";

import RecognitionPrompt from "./RecognitionPrompt";

import "./recognition-prompt-stack.scss";

enum AnimationState {
    Idle = "idle",
    GoToNextCard = "go-to-next-card",
    GoToPreviousCard = "go-to-previous-card"
}

const animationDuration = 600;
const animationSkippedCooldown = 600;

interface Props {
    className?: string;
    currentIndex: number;
    prompts: {
        categoryName: string;
        categoryTheme: RecognitionCategoryTheme;
        prompt: string;
    }[];
    buttonLabel: string;
    onSubmit: (prompt: {
        categoryName: string;
        categoryTheme: RecognitionCategoryTheme;
        prompt: string;
    }) => void;
    disabled?: boolean;
}

const RecognitionPromptStack = ({
    className,
    currentIndex,
    prompts,
    buttonLabel,
    onSubmit,
    disabled
}: Props) => {
    const [currentPromptIndex, setCurrentPromptIndex] = useState(currentIndex);
    const [animationState, setAnimationState] = useState(AnimationState.Idle);
    const [isInSkipCooldown, setIsInSkipCooldown] = useState(false);

    useEffect(() => {
        const delta = currentIndex - currentPromptIndex;
        const absoluteDelta = Math.abs(delta);

        if (
            (absoluteDelta > 1 && delta !== prompts.length - 1) ||
      isInSkipCooldown
        ) {
            setAnimationState(AnimationState.Idle);
            setCurrentPromptIndex(currentIndex);
            setIsInSkipCooldown(true);
        } else if (delta === 1) {
            setAnimationState(AnimationState.GoToNextCard);
        } else if (delta === -1) {
            setAnimationState(AnimationState.GoToPreviousCard);
        }
    }, [currentIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    useDelayedEffect(
        () => {
            if (animationState === AnimationState.GoToNextCard) {
                setCurrentPromptIndex(currentIndex);
                setAnimationState(AnimationState.Idle);
            } else if (animationState === AnimationState.GoToPreviousCard) {
                setCurrentPromptIndex(currentIndex);
                setAnimationState(AnimationState.Idle);
            }
        },
        [animationState],
        animationDuration
    );

    useDelayedEffect(
        () => {
            if (isInSkipCooldown) {
                setIsInSkipCooldown(false);
            }
        },
        [isInSkipCooldown, currentIndex],
        animationSkippedCooldown
    );

    const currentPrompt = useMemo(
        () => prompts[currentPromptIndex % prompts.length],
        [currentPromptIndex] // eslint-disable-line react-hooks/exhaustive-deps
    );
    const nextPrompt = useMemo(
        () => prompts[(currentPromptIndex + 1) % prompts.length],
        [currentPromptIndex] // eslint-disable-line react-hooks/exhaustive-deps
    );
    const secondNextPrompt = useMemo(
        () => prompts[(currentPromptIndex + 2) % prompts.length],
        [currentPromptIndex] // eslint-disable-line react-hooks/exhaustive-deps
    );
    const previousPrompt = useMemo(
        () => prompts[Math.max(0, currentPromptIndex - 1) % prompts.length],
        [currentPromptIndex] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const classes = classNames(
        "recognition-prompt-stack",
        `recognition-prompt-stack--${animationState}`,
        className
    );

    return (
        <div className={classes}>
            <div className="recognition-prompt-stack__cards">
                <RecognitionPrompt
                    className="recognition-prompt-stack__current-card"
                    category={currentPrompt.categoryName}
                    prompt={currentPrompt.prompt}
                    theme={currentPrompt.categoryTheme}
                    buttonLabel={buttonLabel}
                    onClick={() => onSubmit(currentPrompt)}
                    disabled={disabled || animationState !== AnimationState.Idle}
                />
                <RecognitionPrompt
                    className="recognition-prompt-stack__next-card"
                    category={nextPrompt.categoryName}
                    prompt={nextPrompt.prompt}
                    theme={nextPrompt.categoryTheme}
                    buttonLabel={buttonLabel}
                    disabled
                />
                <RecognitionPrompt
                    className="recognition-prompt-stack__second-next-card"
                    category={secondNextPrompt.categoryName}
                    prompt={secondNextPrompt.prompt}
                    theme={secondNextPrompt.categoryTheme}
                    buttonLabel={buttonLabel}
                    disabled
                />
                <RecognitionPrompt
                    className="recognition-prompt-stack__previous-card"
                    category={previousPrompt.categoryName}
                    prompt={previousPrompt.prompt}
                    theme={previousPrompt.categoryTheme}
                    buttonLabel={buttonLabel}
                    disabled
                />
                <div className="recognition-prompt-stack__empty-card" />
            </div>
        </div>
    );
};

RecognitionPromptStack.propTypes = {
    className: PropTypes.string,
    currentIndex: PropTypes.number.isRequired,
    prompts: PropTypes.arrayOf(
        PropTypes.shape({
            categoryName: PropTypes.string.isRequired,
            categoryTheme: PropTypes.oneOf(Object.values(RecognitionCategoryTheme))
                .isRequired,
            prompt: PropTypes.string.isRequired
        })
    ).isRequired,
    buttonLabel: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

export default RecognitionPromptStack;
