import React, { useEffect, useRef } from "react";

import autosize from "autosize";
import classNames from "classnames";
import PropTypes from "prop-types";

import KeyCodes from "@core/enums/KeyCodes";

import "./text-area.scss";

interface Props extends React.HTMLAttributes<HTMLTextAreaElement> {
    className?: string;
    value?: string;
    isAutoResize?: boolean;
    allowNewline?: boolean;
    autoFocus?: boolean;
    maxLength?: number;
    onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
    (
        {
            className,
            value,
            isAutoResize = false,
            allowNewline = true,
            autoFocus = false,
            maxLength,
            onChange,
            onKeyDown,
            ...props
        },
        ref
    ) => {
        const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

        const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = e => {
            onKeyDown?.(e);
            if (e.keyCode === KeyCodes.Enter) {
                e.stopPropagation();

                if (!allowNewline) {
                    e.preventDefault();
                }
            }
        };

        useEffect(() => {
            if (isAutoResize && textAreaRef.current) {
                autosize(textAreaRef.current);
                autosize.update(textAreaRef.current);
            }
        });

        useEffect(() => {
            if (autoFocus && textAreaRef.current) {
                textAreaRef.current?.focus();
            }
        }, [autoFocus, textAreaRef]);

        const classes = classNames("text-area", className);

        return (
            <textarea
                ref={element => {
                    textAreaRef.current = element;
                    if (typeof ref === "function") {
                        ref(element);
                    } else if (ref) {
                        // eslint-disable-next-line no-param-reassign
                        ref.current = element;
                    }
                }}
                className={classes}
                value={value ?? ""}
                maxLength={maxLength}
                onKeyDown={handleKeyDown}
                onChange={onChange}
                {...props}
            />
        );
    }
);

TextArea.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    isAutoResize: PropTypes.bool,
    allowNewline: PropTypes.bool,
    autoFocus: PropTypes.bool,
    maxLength: PropTypes.number,
    onChange: PropTypes.func.isRequired
};

TextArea.defaultProps = {
    isAutoResize: false,
    allowNewline: true,
    autoFocus: false
};

export default TextArea;
