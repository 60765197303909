import type {
    CustomPollDetails,
    QuestionDetails
} from "@api/CustomPollsActivityApiClient";
import ActivityType from "@core/enums/ActivityType";
import CustomPollQuestionDisplayType from "@core/enums/CustomPollQuestionDisplayType";

import buildCustomPollActivity from "./buildCustomPollActivity";

export interface TestCustomPollDetails
    extends Omit<CustomPollDetails, "questions"> {
    questions: QuestionDetails[];
}

const buildTestCustomPollActivity = () => {
    const activity = buildCustomPollActivity({
        customPollId: "8270f89e-a9df-47b6-bb2a-c742ddd7c8bd",
        title: "The custom survey's title",
        description: "The custom survey's description",
        isSentByCompany: false,
        sentByName: "Gene Herrera",
        sentByImageUrl: "https://randomuser.me/api/portraits/men/88.jpg",
        totalQuestions: 6,
        questions: [
            {
                questionId: "2163b05c-a3be-4d12-b19c-57eb15390b51",
                type: CustomPollQuestionDisplayType.Text,
                question: "A thought-provoking text question",
                minLabel: null,
                maxLabel: null,
                followUpQuestion: null,
                choices: null,
                hasCustomChoice: false,
                allowsMultipleChoices: false
            },
            {
                questionId: "cff0e4cb-18f1-4d66-bad0-c931893eac9b",
                type: CustomPollQuestionDisplayType.OpinionScale,
                question:
                    "An mind-shattering opinion scale question with a follow up question",
                minLabel: "The minimum label",
                maxLabel: "The maximum label",
                followUpQuestion: "A follow up question to the opinion scale",
                choices: null,
                hasCustomChoice: false,
                allowsMultipleChoices: false
            },
            {
                questionId: "84ea7b1c-5d14-402e-b5fe-5e98af9e27ea",
                type: CustomPollQuestionDisplayType.MultipleChoice,
                question:
                    "A existential-crisis inducing multiple choice question with a follow up question",
                minLabel: null,
                maxLabel: null,
                followUpQuestion: "A follow up question to the multiple choice",
                choices: [
                    "Choice 1",
                    "Choice 2",
                    "Choice 3",
                    "Choice 4",
                    "Choice 5",
                    "Choice 6",
                    "Choice 7",
                    "Choice 8",
                    "Choice 9",
                    "Choice 10"
                ],
                hasCustomChoice: false,
                allowsMultipleChoices: false
            },
            {
                questionId: "3cf0e94d-5ba2-4fb7-8459-47f90db98fc9",
                type: CustomPollQuestionDisplayType.MultipleChoice,
                question:
                    "A eye-opener multiple choice question with a custom choice",
                minLabel: null,
                maxLabel: null,
                followUpQuestion: null,
                choices: [
                    "Choice 1",
                    "Choice 2",
                    "Choice 3",
                    "Choice 4",
                    "Choice 5",
                    "Choice 6",
                    "Choice 7",
                    "Choice 8",
                    "Choice 9",
                    "Choice 10"
                ],
                hasCustomChoice: true,
                allowsMultipleChoices: false
            },
            {
                questionId: "c5c222b0-0c54-4424-9e31-35867272320b",
                type: CustomPollQuestionDisplayType.MultipleChoice,
                question:
                    "A tantalising multiple choice question that allows multiple choices",
                minLabel: null,
                maxLabel: null,
                followUpQuestion: null,
                choices: [
                    "Choice 1",
                    "Choice 2",
                    "Choice 3",
                    "Choice 4",
                    "Choice 5",
                    "Choice 6",
                    "Choice 7",
                    "Choice 8",
                    "Choice 9",
                    "Choice 10"
                ],
                hasCustomChoice: false,
                allowsMultipleChoices: true
            },
            {
                questionId: "5bb78e62-f079-46a9-87a7-7e604a201da5",
                type: CustomPollQuestionDisplayType.LikertScale,
                question:
                    "A remarkable likert scale question with a follow up question",
                minLabel: null,
                maxLabel: null,
                followUpQuestion: "A follow up question to the likert",
                choices: [
                    "Choice 1",
                    "Choice 2",
                    "Choice 3",
                    "Choice 4",
                    "Choice 5"
                ],
                hasCustomChoice: false,
                allowsMultipleChoices: false
            }
        ]
    });

    return {
        ...activity,
        activityType: ActivityType.TestCustomPoll as const
    };
};

export default buildTestCustomPollActivity;
