import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import OfficevibeLoadingWave from "@components/loading-wave/OfficevibeLoadingWave";

import EmptyLayout from "@layouts/empty/EmptyLayout";

import "./loading-page.scss";

const LoadingPage = ({ className }) => {
    const classes = classNames(
        "loading-page",
        className
    );

    return (
        <EmptyLayout className={classes}>
            <OfficevibeLoadingWave />
        </EmptyLayout>
    );
};

LoadingPage.propTypes = {
    className: PropTypes.string
};

export default LoadingPage;