import React, { useContext, useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import ActivitiesContext from "@contexts/activities/ActivitiesContext";

import ApiException from "@core/api-client/ApiException";

import PreviewCustomPollForbiddenPage from "../forbidden/PreviewCustomPollForbiddenPage";
import ActivitiesPage from "./components/ActivitiesPage";

const PreviewCustomPollPage = () => {
    const { customPollId } = useParams();
    const { initializePreviewCustomPollAsync } = useContext(ActivitiesContext);
    const [isPreviewCustomPollForbidden, setPreviewIsCustomPollForbidden] = useState(false);

    useEffect(() => {
        const initializePreviewCustomPoll = async() => {
            try {
                await initializePreviewCustomPollAsync(customPollId);
            }
            catch (ex) {
                if (ex instanceof ApiException && ex.errorCode === 403) {
                    setPreviewIsCustomPollForbidden(true);
                }
                else {
                    throw ex;
                }
            }
        };

        initializePreviewCustomPoll();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (isPreviewCustomPollForbidden) {
        return <PreviewCustomPollForbiddenPage />;
    }

    return (
        <ActivitiesPage />
    );
};

export default PreviewCustomPollPage;