import React from "react";

import PropTypes from "prop-types";

import Select from "@components/select/Select";

import SupportedSurveyLanguage from "@core/enums/SupportedSurveyLanguage";

const SurveyLanguageSelect = ({ className, value, onChange }) => {
    const options = [
        SupportedSurveyLanguage.German,
        SupportedSurveyLanguage.English,
        SupportedSurveyLanguage.Spanish,
        SupportedSurveyLanguage.French,
        SupportedSurveyLanguage.Portuguese
    ];

    const getLabel = (option) => {
        switch (option) {
            case SupportedSurveyLanguage.German:
                return "Deutsch (Deutschland)";
            case SupportedSurveyLanguage.English:
                return "English (U.S.)";
            case SupportedSurveyLanguage.Spanish:
                return "Español (Suramérica)";
            case SupportedSurveyLanguage.French:
                return "Français (Canada)";
            case SupportedSurveyLanguage.Portuguese:
                return "Português (Brasil)";
            default:
                throw new Error(`Language (${option}) not supported`);
        }
    };

    const renderValue = (option) => {
        return getLabel(option);
    };

    const renderOption = (option, isKeyboardFocused, onClick) => {
        return (
            <Select.Option key={option} onClick={onClick} selected={value === option} isKeyboardFocused={isKeyboardFocused}>
                {getLabel(option)}
            </Select.Option>
        );
    };

    return (
        <Select className={className}
            value={value}
            renderValue={renderValue}
            options={options}
            renderOption={renderOption}
            onChange={onChange} />
    );
};

SurveyLanguageSelect.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default SurveyLanguageSelect;