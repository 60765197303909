/* eslint-disable react/no-unused-state -- eslint seems unable to track usages outside of this context */
import React, { type ReactNode } from "react";

import { getContextAsync } from "@api/UserAuthenticationContextApiClient";
import ApiException from "@core/api-client/ApiException";
import bearerFactory from "@core/bearer/bearerFactory";
import csrfFactory from "@core/csrf/csrfFactory";

import AuthenticationContext, { type AuthenticationContextState } from "./AuthenticationContext";

interface Props {
    children: ReactNode;
}

class AuthenticationContextProvider extends React.Component<Props, AuthenticationContextState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            _initialized: false,
            isAuthenticating: true,
            isAuthenticated: false,
            initializeAsync: async surveyToken => {
                const { _initialized } = this.state;
                if (!_initialized) {
                    this.setState({ _initialized: true, isAuthenticating: true });

                    if (surveyToken) {
                        try {
                            const { requestVerificationToken, jwtToken, jwtExpiry } = await getContextAsync(surveyToken);
    
                            bearerFactory.setToken(jwtToken, jwtExpiry, surveyToken);
                            csrfFactory.setToken(requestVerificationToken);
    
                            this.setState({ isAuthenticating: false, isAuthenticated: true });
                        } catch (ex) {
                            if (ex instanceof ApiException && ex.errorCode === 401) {
                                window.location.href = `${window.env.MONOLITH_API_URL}/survey`;
                            } else {
                                throw ex;
                            }
                        }
                    } else {
                        this.setState({ isAuthenticating: false, isAuthenticated: false });
                    }
                }
            }
        };
    }

    render() { 
        const { children } = this.props;

        return (
            <AuthenticationContext.Provider value={this.state}>
                {children}
            </AuthenticationContext.Provider>
        );
    }
}

export default AuthenticationContextProvider;