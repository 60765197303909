import React, { useState } from "react";

import HelpSolid from "@igloo-ui/icons/dist/HelpSolid";
import Tooltip from "@igloo-ui/tooltip";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useKeyboard, { Handled } from "@hooks/useKeyboard";

import LabeledFormField from "@components/form/LabeledFormField";
import ValidatedFormField from "@components/form/ValidatedFormField";
import Input from "@components/input/Input";

import DeliveryChannel from "@core/enums/DeliveryChannel";
import KeyCode from "@core/enums/KeyCodes";
import SupportedDashboardLanguage from "@core/enums/SupportedDashboardLanguage";
import * as ensure from "@core/utils/validation";

import DashboardLanguageSelect from "./DashboardLanguageSelect";
import SignupSlide from "./SignupSlide";
import SurveyLanguageSelect from "./SurveyLanguageSelect";

import "./signup-profile-slide.scss";

function isSupportedDashboardLanguage(language) {
    return Object.values(SupportedDashboardLanguage).includes(language);
}

const SignupProfileSlide = ({ className, firstName, lastName, email, userName, communicationPreference, surveyLanguage, canEditDashboardLanguage, canEditName, dashboardLanguage, slideIndex, totalSlides, onSubmit, onNextSlideClick, onSurveyLanguageChange }) => {
    const { t } = useTranslation("activities");

    const [internalFirstName, setInternalFirstName] = useState(firstName);
    const [internalLastName, setInternalLastName] = useState(lastName);
    const [internalSurveyLanguage, setInternalSurveyLanguage] = useState(surveyLanguage);
    const [internalDashboardLanguage, setInternalDashboardLanguage] = useState(isSupportedDashboardLanguage(dashboardLanguage) ? dashboardLanguage : SupportedDashboardLanguage.English);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [showValidationErrors, setShowValidationErrors] = useState(false);
    const validations = {
        firstName: ensure.isNotNullOrEmpty(internalFirstName, t("onboarding.firstNameFieldLabel")),
        lastName: ensure.isNotNullOrEmpty(internalLastName, t("onboarding.lastNameFieldLabel"))
    };
    const isValid = validations.firstName.isValid && validations.lastName.isValid;
    const isFirstNameDisabled = !canEditName;
    const isLastNameDisabled = lastName ? (!canEditName && validations.lastName.isValid) : false;

    const handleOnSurveyLanguageChange = (newSurveyLanguage) => {
        setInternalSurveyLanguage(newSurveyLanguage);
        onSurveyLanguageChange(newSurveyLanguage);

        if (newSurveyLanguage === "en-US" || newSurveyLanguage === "fr-CA") {
            setInternalDashboardLanguage(newSurveyLanguage);
        }
    };

    const handleOnSubmit = async() => {
        if (isValid) {
            try {
                setShowValidationErrors(false);
                setIsSubmitting(true);
                await onSubmit(internalSurveyLanguage, canEditDashboardLanguage ? internalDashboardLanguage : internalSurveyLanguage, internalFirstName, internalLastName);
                onNextSlideClick();
            }
            catch (ex) {
                setIsSubmitting(false);

                throw ex;
            }
        }
        else {
            setShowValidationErrors(true);
        }
    };

    useKeyboard({
        [KeyCode.Enter]: () => {
            handleOnSubmit();

            return Handled;
        }
    });

    const classes = classNames(
        "signup-profile-slide",
        className
    );
    const userNameToolTipIcon = <Tooltip content={t("onboarding.usernameTooltip")}>
        <HelpSolid size="small" /></Tooltip>;

    return (
        <SignupSlide className={classes}>
            <SignupSlide.TitleHeader className="signup-profile-slide__header"
                title={t("onboarding.profileSlideTitle")}
                subtitle={t("onboarding.profileSlideSubtitle")} />
            <SignupSlide.Form className="signup-profile-slide__form">
                {canEditDashboardLanguage && (
                    <div className="signup-profile-slide__form-row">
                        <LabeledFormField label={t("onboarding.dashboardLanguageFieldLabel")}>
                            <DashboardLanguageSelect value={internalDashboardLanguage} onChange={dl => setInternalDashboardLanguage(dl)} />
                        </LabeledFormField>
                    </div>
                )}
                <div className="signup-profile-slide__form-row signup-profile-slide__full-name-form-row">
                    <LabeledFormField className="signup-profile-slide__first-name-field" label={t("onboarding.firstNameFieldLabel")}>
                        <ValidatedFormField message={validations.firstName.message} isValid={validations.firstName.isValid} enabled={showValidationErrors}>
                            <Input placeholder={t("onboarding.firstNameFieldPlaceholder")} value={internalFirstName} onChange={e => setInternalFirstName(e.target.value)} error={showValidationErrors && !validations.firstName.isValid} disabled={isFirstNameDisabled} id="st-first-name-input" />
                        </ValidatedFormField>
                    </LabeledFormField>
                    <LabeledFormField className="signup-profile-slide__last-name-field" label={t("onboarding.lastNameFieldLabel")}>
                        <ValidatedFormField message={validations.lastName.message} isValid={validations.lastName.isValid} enabled={showValidationErrors}>
                            <Input placeholder={t("onboarding.lastNameFieldPlaceholder")} value={internalLastName} onChange={e => setInternalLastName(e.target.value)} error={showValidationErrors && !validations.lastName.isValid} disabled={isLastNameDisabled} id="st-last-name-input" />
                        </ValidatedFormField>
                    </LabeledFormField>
                </div>
                <>
                    {communicationPreference === DeliveryChannel.Sms &&
                        <LabeledFormField label={t("onboarding.usernameLabel")} labelSuffix={userNameToolTipIcon}>
                            <Input placeholder={t("onboarding.lastNameFieldPlaceholder")} value={userName} disabled={true} id="st-username-field" />
                        </LabeledFormField>}
                    <LabeledFormField label={t("onboarding.emailLabel")}>
                        <Input placeholder={t("onboarding.lastNameFieldPlaceholder")} value={email} disabled={true} id="st-email-field" />
                    </LabeledFormField>
                    <div className="signup-profile-slide__form-row">
                        <LabeledFormField label={t("onboarding.surveyLanguageFieldLabel")}>
                            <SurveyLanguageSelect value={internalSurveyLanguage} onChange={handleOnSurveyLanguageChange} />
                        </LabeledFormField>
                    </div>
                </>
            </SignupSlide.Form>
            <SignupSlide.Footer className="signup-profile-slide__footer"
                currentStepIndex={slideIndex}
                totalSteps={totalSlides}
                showNextButton
                onNextClick={handleOnSubmit}
                isSubmitting={isSubmitting}
                nextButtonId="st-profile-slide-next-button" />
        </SignupSlide>
    );
};

SignupProfileSlide.propTypes = {
    className: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    userName: PropTypes.string,
    communicationPreference: PropTypes.string,
    surveyLanguage: PropTypes.string.isRequired,
    canEditDashboardLanguage: PropTypes.bool.isRequired,
    canEditName: PropTypes.bool.isRequired,
    dashboardLanguage: PropTypes.string.isRequired,
    slideIndex: PropTypes.number.isRequired,
    totalSlides: PropTypes.number.isRequired,
    onSurveyLanguageChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onNextSlideClick: PropTypes.func.isRequired
};

export default SignupProfileSlide;