import React, { useEffect } from "react";

import PropTypes from "prop-types";

import OnboardingSurveyFollowUpQuestion from "@components/onboarding-survey-question/OnboardingSurveyFollowUpQuestion";

interface Props {
    followUpQuestion: string;
    feedback?: string;
    isAnswered?: boolean;
    onSubmit: (feedback: string) => void;
    onGoBack: () => void;
    onAnimationCompleted: () => void;
    onTrackFeedbackShown: () => void;
}

const OnboardingSurveyActivityFollowUpQuestionStep = ({ followUpQuestion, feedback, isAnswered, onSubmit, onGoBack, onAnimationCompleted, onTrackFeedbackShown }: Props) => {
    useEffect(() => {
        onTrackFeedbackShown();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <OnboardingSurveyFollowUpQuestion
            question={followUpQuestion}
            initialFeedback={feedback}
            isAnswered={!!isAnswered}
            onChange={f => onSubmit(f)}
            onAnimationCompleted={onAnimationCompleted}
            onGoBack={onGoBack}
        />
    );
};

OnboardingSurveyActivityFollowUpQuestionStep.propTypes = {
    followUpQuestion: PropTypes.string.isRequired,
    feedback: PropTypes.string,
    isAnswered: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onGoBack: PropTypes.func.isRequired,
    onAnimationCompleted: PropTypes.func.isRequired,
    onTrackFeedbackShown: PropTypes.func.isRequired
};

export default OnboardingSurveyActivityFollowUpQuestionStep;