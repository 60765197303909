import React, { useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import useDelayedEffect from "@hooks/useDelayedEffect";

import AnonymityBanner from "@components/activities/banners/AnonymityBanner";
import CustomPollPreviewBanner from "@components/activities/banners/CustomPollPreviewBanner";
import PulseSurveyPreviewBanner from "@components/activities/banners/PulseSurveyPreviewBanner";
import RecognitionBanner from "@components/activities/banners/RecognitionBanner";

import ActivityBannerType from "@core/enums/ActivityBannerType";

import "./activities-banner.scss";

const bannerSlideOutDuration = 600;

const ActivitiesBanner = ({ className, type, isHidden, ...metadata }) => {
    const [displayedBannerType, setDisplayedBannerType] = useState(type);
    const [displayedBannerIsAnonymous, setDisplayedBannerIsAnonymous] = useState(metadata.isAnonymous);

    // Change banner type only after the slide out animation is completed
    useDelayedEffect(() => {
        setDisplayedBannerType(type);
    }, [type, isHidden], bannerSlideOutDuration, type === ActivityBannerType.None && !isHidden);

    // Change isAnonymous property only after the slide out animation is completed
    useDelayedEffect(() => {
        setDisplayedBannerIsAnonymous(metadata.isAnonymous);
    }, [type, isHidden, metadata.isAnonymous], bannerSlideOutDuration, isHidden || type === ActivityBannerType.None);

    const classes = classNames(
        "activities-banner",
        className, {
            "activities-banner--hidden": isHidden || type === ActivityBannerType.None
        }
    );

    switch (displayedBannerType) {
        case ActivityBannerType.GeneralAnonymity:
            return <AnonymityBanner key={displayedBannerType} className={classes} isAnonymous={displayedBannerIsAnonymous} />;

        case ActivityBannerType.PulseSurveyPreview:
            return <PulseSurveyPreviewBanner key={displayedBannerType} className={classes} />;

        case ActivityBannerType.CustomPollPreview:
            return <CustomPollPreviewBanner key={displayedBannerType} className={classes} />;

        case ActivityBannerType.Recognition:
            return <RecognitionBanner key={displayedBannerType} className={classes} />;

        case ActivityBannerType.None:
            return null;

        default:
            throw new Error(`Activity Banner Type (${displayedBannerType}) not supported`);
    }
};

ActivitiesBanner.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
    isHidden: PropTypes.bool.isRequired
};

export default ActivitiesBanner;