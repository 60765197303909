import React, { useEffect } from "react";

import type { DeibSurveyActivityType, OrchestratedDeibSurveyQuestionStep, OrchestratedDeibSurveyStep } from "@/types/activities/deib-survey";
import PropTypes from "prop-types";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";

import ActivityType from "@core/enums/ActivityType";
import DeibSurveyStepType from "@core/enums/DeibSurveyStepType";
import { trackActivityCompleted, trackActivityStarted, trackQuestionAnswered } from "@core/tracking/track";
import TrackingInteractionType from "@core/tracking/TrackingInteractionType";

import DeibIntro from "../deib-survey-question/DeibIntro";
import DeibSurveyActivityQuestionStep from "./DeibSurveyActivityQuestionStep";

interface Props {
    className?: string;
    activityType: DeibSurveyActivityType;
    isFirstActivity: boolean;
    isLastActivity: boolean;
    correlationId: string;
    steps: OrchestratedDeibSurveyStep[];
    currentStepIndex: number;
    onSliderQuestionAnswered: () => void;
    onLikertQuestionAnswered: () => void;
    onMultipleChoiceQuestionAnswered: () => void;
    onGoToPreviousStep: () => void;
    onGoToNextStep: () => void;
    onGoToNextActivity: () => void;
    onGoToOutro: () => void;
    onActivityEnded: (correlationId: string) => void;
    onIntroAnimationCompleted: () => void;
}

const DeibSurveyActivity = ({
    className,
    activityType,
    isFirstActivity,
    isLastActivity,
    correlationId,
    steps,
    currentStepIndex,
    onSliderQuestionAnswered,
    onLikertQuestionAnswered,
    onMultipleChoiceQuestionAnswered,
    onGoToNextStep,
    onGoToNextActivity,
    onGoToOutro,
    onActivityEnded,
    onIntroAnimationCompleted
}: Props) => {
    const { isNetworkAdmin, isCompanyManager, isTeamManager } = useAuthenticatedUser();
    const currentStep = steps[currentStepIndex];

    useEffect(() => {
        trackActivityStarted(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, isFirstActivity);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isQuestionStep = (step: OrchestratedDeibSurveyStep): step is OrchestratedDeibSurveyQuestionStep => "questionId" in step;

    const handleOnQuestionAnswered = (callback: (correlationId: string, questionId: string, answer: number, score: number) => void) => (answer: number, score: number) => {
        if (!isQuestionStep(currentStep)) {
            return;
        }

        trackQuestionAnswered(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, TrackingInteractionType.Answered, currentStep.questionId);

        callback(correlationId, currentStep.questionId, answer, score);
    };

    const handleOnAnimationCompleted = () => {
        if (currentStep.isLastStep) {
            trackActivityCompleted(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, isFirstActivity);
            onActivityEnded(correlationId);

            if (isLastActivity) {
                onGoToOutro();
            } else {
                onGoToNextActivity();
            }
        } else {
            onGoToNextStep();
        }
    };

    const renderIntroStep = () => {
        return (
            <DeibIntro
                onGoToNextStep={onGoToNextStep}
            />
        );
    };

    const renderAskQuestionStep = (step: OrchestratedDeibSurveyQuestionStep) => {
        return (
            <DeibSurveyActivityQuestionStep
                {...step}
                onSliderQuestionAnswered={handleOnQuestionAnswered(onSliderQuestionAnswered)}
                onLikertQuestionAnswered={handleOnQuestionAnswered(onLikertQuestionAnswered)}
                onMultipleChoiceQuestionAnswered={handleOnQuestionAnswered(onMultipleChoiceQuestionAnswered)}
                onAnimationCompleted={handleOnAnimationCompleted}
                onIntroAnimationCompleted={onIntroAnimationCompleted}
            />
        );
    };

    const renderCurrentStep = () => {
        const { stepType } = currentStep;

        switch (stepType) {
            // TODO: rework the step types so that TypeScript can make sense of the discriminator [EP-1904]
            case DeibSurveyStepType.Intro:
                return renderIntroStep();
            case DeibSurveyStepType.AskQuestion:
                return renderAskQuestionStep(currentStep as OrchestratedDeibSurveyQuestionStep);
            default:
                throw new Error(`Deib Survey Step Type (${stepType}) not supported`);
        }
    };

    return (
        <div className={className}>
            {renderCurrentStep()}
        </div>
    );
};

DeibSurveyActivity.propTypes = {
    className: PropTypes.string,
    activityType: PropTypes.oneOf(Object.values(ActivityType)).isRequired,
    isFirstActivity: PropTypes.bool.isRequired,
    isLastActivity: PropTypes.bool.isRequired,
    correlationId: PropTypes.string,
    steps: PropTypes.array.isRequired,
    currentStepIndex: PropTypes.number.isRequired,
    onSliderQuestionAnswered: PropTypes.func.isRequired,
    onLikertQuestionAnswered: PropTypes.func.isRequired,
    onMultipleChoiceQuestionAnswered: PropTypes.func.isRequired,
    onGoToPreviousStep: PropTypes.func.isRequired,
    onGoToNextStep: PropTypes.func.isRequired,
    onGoToNextActivity: PropTypes.func.isRequired,
    onGoToOutro: PropTypes.func.isRequired,
    onActivityEnded: PropTypes.func.isRequired,
    onIntroAnimationCompleted: PropTypes.func.isRequired
};

export default DeibSurveyActivity;