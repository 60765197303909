import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import useDelayedEffect from "@hooks/useDelayedEffect";

import SliderQuestion from "@components/slider-question/SliderQuestion";

import BaseOnboardingSurveyQuestion from "./BaseOnboardingSurveyQuestion";

import "./onboarding-survey-slider-question.scss";

interface Props {
    className?: string;
    question: string;
    initialValue?: number | null;
    minLabel: React.ReactNode;
    maxLabel: React.ReactNode;
    onChange: (value: number) => void;
    isIntroAnimated: boolean;
    onIntroAnimationCompleted: () => void;
    onAnimationCompleted: () => void;
}

const OnboardingSurveySliderQuestion = ({
    className,
    question,
    initialValue,
    minLabel,
    maxLabel,
    onChange,
    isIntroAnimated,
    onIntroAnimationCompleted,
    onAnimationCompleted
}: Props) => {
    useDelayedEffect(
        () => {
            if (isIntroAnimated) {
                onIntroAnimationCompleted();
            }
        },
        [],
        900
    );

    const classes = classNames("onboarding-survey-slider-question", className, {
        "onboarding-survey-slider-question--animated-intro": isIntroAnimated
    });

    return (
        <BaseOnboardingSurveyQuestion className={classes} question={question}>
            <SliderQuestion
                className="onboarding-survey-slider-question__slider"
                initialValue={initialValue}
                minLabel={minLabel}
                maxLabel={maxLabel}
                onChange={onChange}
                onAnimationCompleted={onAnimationCompleted}
            />
        </BaseOnboardingSurveyQuestion>
    );
};

OnboardingSurveySliderQuestion.propTypes = {
    className: PropTypes.string,
    question: PropTypes.string.isRequired,
    initialValue: PropTypes.number,
    minLabel: PropTypes.node.isRequired,
    maxLabel: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    isIntroAnimated: PropTypes.bool.isRequired,
    onIntroAnimationCompleted: PropTypes.func.isRequired,
    onAnimationCompleted: PropTypes.func.isRequired
};

export default OnboardingSurveySliderQuestion;
