import type { StringCustomizationToken } from "@/types/StringCustomizationToken";
import upperFirst from "lodash/upperFirst";
import type { TFunction } from "react-i18next";
import type { PulseSurveyStep } from "src/types/activities/pulse-survey";

import type { PulseSurveyActivityResponse } from "@api/PulseSurveyActivityApiClient";
import type { TryPulseSurveyActivityResponse } from "@api/TryPulseSurveyActivityApiClient";
import ActivityType from "@core/enums/ActivityType";
import PulseSurveyQuestionDisplayType from "@core/enums/PulseSurveyQuestionDisplayType";
import PulseSurveyStepType from "@core/enums/PulseSurveyStepType";

import buildPulseSurveyQuestion from "./pulseSurveyQuestionBuilder";
import type { PulseSurveyActivityCustomQuestionOptions } from "./types";

const mergeMicroCustomizations = (
    networkDefinedMicroCustomizations: StringCustomizationToken[],
    genericMicroCustomizations: StringCustomizationToken[]
) => {
    const result: Record<string, StringCustomizationToken> = {};

    const addMicroCustomization = (
        sourceWord: string,
        replacementWord: string
    ) => {
        result[sourceWord] = { sourceWord: `[${sourceWord}]`, replacementWord };
    };

    for (const microCustomization of genericMicroCustomizations) {
        addMicroCustomization(
            microCustomization.sourceWord,
            microCustomization.replacementWord
        );
    }

    for (const microCustomization of networkDefinedMicroCustomizations) {
        addMicroCustomization(
            microCustomization.sourceWord,
            microCustomization.replacementWord
        );
    }

    return Object.values(result);
};

const buildPulseSurveyQuestions = <
    TPulseSurveyActivityResponse extends
    | PulseSurveyActivityResponse
    | TryPulseSurveyActivityResponse =
    | PulseSurveyActivityResponse
    | TryPulseSurveyActivityResponse
>(
    activity: TPulseSurveyActivityResponse,
    t: TFunction<"activities">
) => {
    const microCustomizations = mergeMicroCustomizations(
        "microCustomizations" in activity ? activity.microCustomizations : [],
        [
            {
                sourceWord: "directmanager",
                replacementWord: t("pulseSurveyCustomizableTerms.directmanager")
            },
            {
                sourceWord: "directmanagers",
                replacementWord: t(
                    "pulseSurveyCustomizableTerms.directmanagers"
                )
            },
            {
                sourceWord: "teammember",
                replacementWord: t("pulseSurveyCustomizableTerms.teammember")
            },
            {
                sourceWord: "teammembers",
                replacementWord: t("pulseSurveyCustomizableTerms.teammembers")
            },
            {
                sourceWord: "organization",
                replacementWord: t("pulseSurveyCustomizableTerms.organization")
            },
            {
                sourceWord: "organizations",
                replacementWord: t("pulseSurveyCustomizableTerms.organizations")
            },
            {
                sourceWord: "peer",
                replacementWord: t("pulseSurveyCustomizableTerms.peer")
            },
            {
                sourceWord: "peers",
                replacementWord: t("pulseSurveyCustomizableTerms.peers")
            }
        ]
    );

    const replaceTags = (text: string) => {
        let result = text;

        for (const microCustomization of microCustomizations) {
            result = result.replaceAll(
                microCustomization.sourceWord,
                microCustomization.replacementWord
            );
        }

        return result;
    };

    return activity.questionIds
        .map(questionId => buildPulseSurveyQuestion(questionId, t))
        .map(q => ({
            ...q,
            question: upperFirst(replaceTags(q.question)),
            constructiveFollowUpQuestion: upperFirst(
                replaceTags(q.constructiveFollowUpQuestion)
            ),
            positiveFollowUpQuestion: upperFirst(
                replaceTags(q.positiveFollowUpQuestion)
            ),
            askFollowUpQuestion:
                q.questionId === activity.askFollowUpForQuestionId
        }));
};

const buildPulseSurveyActivity = <
    TPulseSurveyActivityResponse extends
    | PulseSurveyActivityResponse
    | TryPulseSurveyActivityResponse =
    | PulseSurveyActivityResponse
    | TryPulseSurveyActivityResponse
>(
    activity: TPulseSurveyActivityResponse,
    pulseSurveyActivityCustomQuestionOptions: PulseSurveyActivityCustomQuestionOptions,
    t: TFunction<"activities">
) => {
    if (activity.questionIds.length === 0) {
        return null;
    }

    const questions = buildPulseSurveyQuestions(activity, t);

    const questionSteps: PulseSurveyStep[] = questions
        .map(q => {
            const {
                constructiveFollowUpQuestion,
                positiveFollowUpQuestion,
                ...stepData
            } = q;

            const askQuestionStep = {
                ...stepData,
                customQuestionId: "",
                stepType: PulseSurveyStepType.AskQuestion
            };

            if (q.askFollowUpQuestion) {
                const askFollowUpQuestionStep = {
                    questionId: askQuestionStep.questionId,
                    constructiveFollowUpQuestion,
                    positiveFollowUpQuestion,
                    stepType: PulseSurveyStepType.AskFollowUpQuestion
                };

                return [askQuestionStep, askFollowUpQuestionStep];
            }

            return askQuestionStep;
        })
        .flat();

    if (
        pulseSurveyActivityCustomQuestionOptions.isPulseSurveyCustomTextualQuestionsEnabled &&
        pulseSurveyActivityCustomQuestionOptions.customQuestion
    ) {
        const minIndex =
            pulseSurveyActivityCustomQuestionOptions.insertCustomQuestionAfterFirst
                ? 1
                : 0;
        const maxIndex = questionSteps.length - 1;

        const customQuestionIndex = Math.floor(
            Math.random() * maxIndex + minIndex
        );

        questionSteps.splice(customQuestionIndex, 0, {
            questionId: "Q-CUSTOM",
            customQuestionId:
                pulseSurveyActivityCustomQuestionOptions.customQuestion.id,
            question:
                pulseSurveyActivityCustomQuestionOptions.customQuestion
                    .question,
            askFollowUpQuestion: false,
            displayType: PulseSurveyQuestionDisplayType.Custom,
            stepType: PulseSurveyStepType.AskCustomQuestion
        });
    }

    return {
        ...activity,
        activityType: ActivityType.PulseSurvey as const,
        steps: questionSteps
    };
};

export default buildPulseSurveyActivity;
