import React, { useState } from "react";

import { CheckmarkIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import PropTypes from "prop-types";

import useDelayedEffect from "@hooks/useDelayedEffect";

import KeyCodes from "@core/enums/KeyCodes";
import sleep from "@core/utils/sleep";

import BasePulseSurveyQuestion from "./BasePulseSurveyQuestion";

import "./pulse-survey-multiple-choice-question.scss";

interface Props {
    className?: string;
    question: string;
    initialValue?: number | null;
    labels: string[];
    imageUrls: string[];
    onChange: (value: number) => void;
    isIntroAnimated: boolean;
    onIntroAnimationCompleted: () => void;
    onAnimationCompleted: () => void;
}

const PulseSurveyMultipleChoiceQuestion = ({
    className,
    question,
    initialValue,
    labels,
    imageUrls,
    onChange,
    isIntroAnimated,
    onIntroAnimationCompleted,
    onAnimationCompleted
}: Props) => {
    const [confirmed, setConfirmed] = useState(false);
    const [value, setValue] = useState(initialValue ?? null);
    const [focusedValue, setFocusedValue] = useState<number | null>(null);
    const [hasUserInteracted, setHasUserInteracted] = useState(
        initialValue !== null
    );

    useDelayedEffect(
        () => {
            if (isIntroAnimated) {
                onIntroAnimationCompleted();
            }
        },
        [],
        1500
    );

    const handleOnConfirm = async(newValue: number) => {
        if (confirmed) {
            return;
        }

        setValue(newValue);
        setConfirmed(true);
        setHasUserInteracted(true);

        onChange(newValue);
        await sleep(700);
        onAnimationCompleted();
    };

    const handleConfirmOnClick = (newValue: number, e: React.MouseEvent) => {
        e.preventDefault();
        handleOnConfirm(newValue);
    };

    const handleConfirmOnEnter = (
        newValue: number,
        e: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (e.keyCode === KeyCodes.Enter) {
            e.currentTarget.blur();

            handleOnConfirm(newValue);
        }
    };

    const handleOnInputBlur = () => {
        setFocusedValue(null);
    };

    const renderChoice = (label: string, i: number) => {
        const choiceClasses = classNames(
            "pulse-survey-multiple-choice-question__choice-label",
            {
                "pulse-survey-multiple-choice-question__choice-label--selected":
                    hasUserInteracted && i === value,
                "pulse-survey-multiple-choice-question__choice-label--highlighted":
                    !confirmed && i === focusedValue
            }
        );

        return (
            <div
                key={`multiple-choice-choice-${i}`}
                className="pulse-survey-multiple-choice-question__choice"
                role="radio"
                aria-checked={value === i}
            >
                <input
                    id={`multiple-choice-choice-${i}`}
                    className="pulse-survey-multiple-choice-question__radio-input"
                    type="radio"
                    name="multiple-choice"
                    value={i}
                    checked={
                        focusedValue !== null ? i === focusedValue : i === value
                    }
                    onChange={() => setFocusedValue(i)}
                    disabled={confirmed}
                    onFocus={() => setFocusedValue(i)}
                    onBlur={handleOnInputBlur}
                    onKeyDown={e => handleConfirmOnEnter(i, e)}
                />
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
                <label
                    className={choiceClasses}
                    htmlFor={`multiple-choice-choice-${i}`}
                    aria-label={label}
                    onClick={e => handleConfirmOnClick(i, e)}
                >
                    <div className="pulse-survey-multiple-choice-question__choice-box">
                        <img
                            className="pulse-survey-multiple-choice-question__image"
                            src={imageUrls[i]}
                            aria-hidden="true"
                            alt=""
                        />
                        <div className="pulse-survey-multiple-choice-question__label">
                            {label}
                        </div>
                        <CheckmarkIcon
                            className="pulse-survey-multiple-choice-question__selected-icon"
                            size="md"
                            aria-hidden="true"
                        />

                    </div>
                </label>
            </div>
        );
    };

    const classes = classNames(
        "pulse-survey-multiple-choice-question",
        className,
        {
            "pulse-survey-multiple-choice-question--animated-intro":
                isIntroAnimated,
            "pulse-survey-multiple-choice-question--confirmed": confirmed
        }
    );

    return (
        <BasePulseSurveyQuestion className={classes} question={question}>
            <img
                className="pulse-survey-multiple-choice-question__mobile-image"
                src={imageUrls[value !== null ? value : 3]}
                aria-hidden="true"
                alt=""
            />
            <div
                className="pulse-survey-multiple-choice-question__group"
                role="radiogroup"
            >
                {labels.map(renderChoice)}
            </div>
        </BasePulseSurveyQuestion>
    );
};

PulseSurveyMultipleChoiceQuestion.propTypes = {
    className: PropTypes.string,
    question: PropTypes.string.isRequired,
    initialValue: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    imageUrls: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    isIntroAnimated: PropTypes.bool.isRequired,
    onIntroAnimationCompleted: PropTypes.func.isRequired,
    onAnimationCompleted: PropTypes.func.isRequired
};

export default PulseSurveyMultipleChoiceQuestion;
