import React from "react";

import PropTypes from "prop-types";

import SliderQuestion from "@components/slider-question/SliderQuestion";

import BaseCustomPollQuestion from "./BaseCustomPollQuestion";

interface Props {
    className?: string;
    question: string;
    initialValue?: number | null;
    minLabel: React.ReactNode;
    maxLabel: React.ReactNode;
    onSubmit: (value: number) => void;
    onAnimationCompleted: () => void;
}

const CustomPollOpinionScaleQuestion = ({
    className,
    question,
    initialValue,
    minLabel,
    maxLabel,
    onSubmit,
    onAnimationCompleted
}: Props) => (
    <BaseCustomPollQuestion className={className} question={question}>
        <SliderQuestion
            initialValue={initialValue}
            minLabel={minLabel}
            maxLabel={maxLabel}
            onChange={onSubmit}
            onAnimationCompleted={onAnimationCompleted}
        />
    </BaseCustomPollQuestion>
);

CustomPollOpinionScaleQuestion.propTypes = {
    className: PropTypes.string,
    question: PropTypes.string.isRequired,
    initialValue: PropTypes.number,
    minLabel: PropTypes.node.isRequired,
    maxLabel: PropTypes.node.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onAnimationCompleted: PropTypes.func.isRequired
};

export default CustomPollOpinionScaleQuestion;
