import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import useDelayedEffect from "@hooks/useDelayedEffect";

import LikertQuestion from "@components/likert-question/LikertQuestion";

import BaseDeibSurveyQuestion from "./BaseDeibSurveyQuestion";

import "./deib-survey-likert-question.scss";

interface Props {
    className?: string;
    question: string;
    initialValue?: number | null;
    labels: string[];
    onChange: (value: number) => void;
    onAnimationCompleted: () => void;
    onIntroAnimationCompleted: () => void;
}

const DeibSurveyLikertQuestion = ({
    className,
    question,
    initialValue,
    labels,
    onChange,
    onAnimationCompleted,
    onIntroAnimationCompleted
}: Props) => {
    useDelayedEffect(() => {
        onIntroAnimationCompleted();
    }, [], 900);

    const classes = classNames(
        "deib-survey-likert-question",
        className,
        "deib-survey-likert-question--animated-intro"
    );

    return (
        <BaseDeibSurveyQuestion className={classes} question={question}>
            <LikertQuestion
                className="deib-survey-likert-question__content"
                initialValue={initialValue}
                labels={labels}
                onChange={onChange}
                onAnimationCompleted={onAnimationCompleted}
            />
        </BaseDeibSurveyQuestion>
    );
};

DeibSurveyLikertQuestion.propTypes = {
    className: PropTypes.string,
    question: PropTypes.string.isRequired,
    initialValue: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    onAnimationCompleted: PropTypes.func.isRequired,
    onIntroAnimationCompleted: PropTypes.func.isRequired
};

export default DeibSurveyLikertQuestion;