import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import PrimaryButton from "@components/button/PrimaryButton";

import { ReactComponent as Illustration } from "@svg/illustrations/illustration-not-found.svg";
import { ReactComponent as IllustrationLegacy } from "@svg/illustrations/legacy/illustration-not-found.svg";
import { ReactComponent as Logo } from "@svg/logo.svg";

import "./custom-poll-forbidden-page.scss";

const CustomPollForbiddenPage = ({isCustomSurveyCompleted}) => {
    const { t } = useTranslation("activities");
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);

    const handleOnRedirectToDashboard = () => {
        window.location.href = window.env.APP_DASHBOARD_URL;
    };

    const forbiddenPage = isCustomSurveyCompleted ? "customPollForbiddenAlreadyCompleted" : "customPollForbidden";

    return (
        <div className="custom-poll-forbidden-page">
            <div className="custom-poll-forbidden-page__header">
                <Logo className="custom-poll-forbidden-page__logo" />
            </div>
            <div className="custom-poll-forbidden-page__image-container">
                {isWorkleapBrandEnabled
                    ? <Illustration className="custom-poll-forbidden-page__image" />
                    : <IllustrationLegacy className="custom-poll-forbidden-page__image" />
                }
            </div>
            <div className="custom-poll-forbidden-page__title">
                {t(`${forbiddenPage}.title`)}
            </div>
            <div className="custom-poll-forbidden-page__subtitle">
                {t(`${forbiddenPage}.forbidden-description`)}
            </div>
            <PrimaryButton
                className="custom-poll-forbidden-page__go-to-officevibe-button"
                onClick={handleOnRedirectToDashboard}>
                {t(`${forbiddenPage}.go-to-officevibe`)}
            </PrimaryButton>
        </div>
    );
};

CustomPollForbiddenPage.propTypes = {
    isCustomSurveyCompleted: PropTypes.bool.isRequired
};

export default CustomPollForbiddenPage;