import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import ProgressBar from "@components/progress-bar/ProgressBar";

import ActivityControlBarStepType from "@core/enums/ActivityControlBarStepType";

import "./control-bar-tracked-questions.scss";

const ControlBarTrackedQuestions = ({ className, questions }) => {
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);
    
    const currentQuestion = questions
        .filter(q => q.type === ActivityControlBarStepType.QuestionSubStep)
        .filter(q => q.isInProgress || q.isCompleted)
        .length;

    const totalQuestions = questions
        .filter(q => q.type === ActivityControlBarStepType.QuestionSubStep)
        .length;

    const renderQuestion = (question, index) => {
        switch (question.type) {
            case ActivityControlBarStepType.QuestionSubStep:
                return (
                    <ProgressBar.Step
                        key={index}
                        isInProgress={question.isInProgress}
                        isCompleted={question.isCompleted} />
                );

            case ActivityControlBarStepType.RelatedQuestionSubStep:
            case ActivityControlBarStepType.None:
                return null;

            default:
                throw new Error(`Question Type (${question.type}) not suppoted`);
        }
    };

    const classes = classNames(
        "control-bar-tracked-questions",
        className
    );

    return (
        <div className={classes}>
            <div className="control-bar-tracked-questions__progress">
                {isWorkleapBrandEnabled 
                    ? `${currentQuestion}/${totalQuestions}`
                    : `${currentQuestion} / ${totalQuestions}`}
            </div>
            <ProgressBar>
                {questions.map(renderQuestion)}
            </ProgressBar>
        </div>
    );
};

ControlBarTrackedQuestions.propTypes = {
    className: PropTypes.string,
    questions: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
        isInProgress: PropTypes.bool.isRequired,
        isCompleted: PropTypes.bool.isRequired
    })).isRequired
};

export default ControlBarTrackedQuestions;