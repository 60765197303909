import React, { useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";
import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import InlineButton from "@components/button/InlineButton";
import InteractionBox from "@components/interaction-box/InteractionBox";
import QuestionAskedBy from "@components/question-asked-by/QuestionAskedBy";

import { ReactComponent as OvAvatarLegacy } from "@svg/legacy/ov-avatar.svg";
import { ReactComponent as OvAvatar } from "@svg/ov-avatar.svg";

import "./onboarding-survey-follow-up-question.scss";

interface Props {
    className?: string;
    question: string;
    initialFeedback?: string;
    isAnswered: boolean;
    onChange: (feedback: string, isAnonymous: boolean) => void;
    onChangeAnonymity?: (isAnonymous: boolean) => void;
    onGoBack: () => void;
    onAnimationCompleted: () => void;
}

const OnboardingSurveyFollowUpQuestion = ({
    className,
    question,
    initialFeedback,
    isAnswered,
    onChange,
    onChangeAnonymity,
    onGoBack,
    onAnimationCompleted
}: Props) => {
    const isWorkleapBrandEnabled = useIsFeatureEnabled(
        feature => feature.useWorkleapBrand
    );
    const { t } = useTranslation("activities");
    const authenticatedUserContext = useAuthenticatedUser();
    const { imageUrl, fullName }: { imageUrl?: string; fullName?: string } =
        authenticatedUserContext.isLoading ? {} : authenticatedUserContext;
    const [isAnsweredSnapshot] = useState(isAnswered);

    const [confirmed, setConfirmed] = useState(false);

    const handleOnChangeAnonymity = (isAnonymous: boolean) => {
        if (confirmed) {
            return;
        }

        if (onChangeAnonymity) {
            onChangeAnonymity(isAnonymous);
        }
    };

    const handleOnConfirm = async(feedback: string, isAnonymous: boolean) => {
        if (confirmed) {
            return;
        }

        setConfirmed(true);

        onChange(feedback, isAnonymous);
        onAnimationCompleted();
    };

    const classes = classNames(
        "onboarding-survey-follow-up-question",
        className,
        {
            "onboarding-survey-follow-up-question--confirmed": confirmed
        }
    );

    return (
        <div className={classes}>
            <div className="onboarding-survey-follow-up-question__previous-step">
                <span className="onboarding-survey-follow-up-question__previous-step-text">
                    {t("onboardingSurveyFollowUpQuestion.title")}
                </span>
                <InlineButton
                    className="onboarding-survey-follow-up-question__previous-step-button"
                    onClick={onGoBack}
                >
                    {t(
                        "onboardingSurveyFollowUpQuestion.edit-answer-button-label"
                    )}
                </InlineButton>
            </div>
            <QuestionAskedBy
                className="onboarding-survey-follow-up-question__question-asked-by"
                question={question}
                icon={
                    isWorkleapBrandEnabled ? <OvAvatar /> : <OvAvatarLegacy />
                }
            />
            <div className="onboarding-survey-follow-up-question__input">
                <InteractionBox
                    initialFeedback={initialFeedback}
                    initialIsAnonymous={false}
                    authorImageUrl={imageUrl}
                    authorFullName={fullName}
                    placeholder={t(
                        "onboardingSurveyFollowUpQuestion.feedback-input-placeholder"
                    )}
                    submitLabel={
                        isAnsweredSnapshot
                            ? t(
                                "onboardingSurveyFollowUpQuestion.update-feedback-button-label"
                            )
                            : t(
                                "onboardingSurveyFollowUpQuestion.send-feedback-button-label"
                            )
                    }
                    onSubmit={handleOnConfirm}
                    onChangeIsAnonymous={handleOnChangeAnonymity}
                    disabled={confirmed}
                    disableAnonymous
                    allowEmptyFeedback={isAnsweredSnapshot}
                    tooltip={t(
                        "onboardingSurveyFollowUpQuestion.labeled-toggle-tooltip"
                    )}
                />
            </div>
        </div>
    );
};

OnboardingSurveyFollowUpQuestion.propTypes = {
    className: PropTypes.string,
    question: PropTypes.string.isRequired,
    initialFeedback: PropTypes.string,
    isAnswered: PropTypes.bool.isRequired,
    onChangeAnonymity: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onGoBack: PropTypes.func.isRequired,
    onAnimationCompleted: PropTypes.func.isRequired
};

export default OnboardingSurveyFollowUpQuestion;
