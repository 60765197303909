import React, { useContext } from "react";

import type { BuiltActivity } from "@/types/activities/common";
import type { GuidString } from "@/types/utilityTypes";

import type { CustomPollDetails, CustomPollsActivityControllerSaveAnswerRequest } from "@api/CustomPollsActivityApiClient";
import type { UserIdentity } from "@api/RecognitionActivityApiClient";
import type buildCustomPollActivity from "@core/activities/custom-poll/buildCustomPollActivity";
import type buildPreviewCustomPollActivity from "@core/activities/custom-poll/buildPreviewCustomPollActivity";
import type buildTestCustomPollActivity from "@core/activities/custom-poll/buildTestCustomPollActivity";
import type buildTestDeibSurveyQuestionActivity from "@core/activities/deib-survey/buildTestDeibSurveyQuestionActivity";
import type { DeibQuestionID } from "@core/activities/deib-survey/types";
import type buildOnboardingSurveyActivity from "@core/activities/onboarding-survey/buildOnboardingSurveyActivity";
import type buildTestOnboardingSurveyQuestionActivity from "@core/activities/onboarding-survey/buildTestOnboardingSurveyQuestionActivity";
import type { OnboardingQuestionID } from "@core/activities/onboarding-survey/types";
import type buildTestPulseSurveyQuestionActivity from "@core/activities/pulse-survey/buildTestPulseSurveyQuestionActivity";
import type buildTryPulseSurveyActivity from "@core/activities/pulse-survey/buildTryPulseSurveyActivity";
import type { PulseSurveyQuestionId } from "@core/activities/pulse-survey/types";
import type buildRecognitionActivity from "@core/activities/recognition/buildRecognitionActivity";
import type buildTestRecognitionActivity from "@core/activities/recognition/buildTestRecognitionActivity";
import type buildTestSmartQuestionActivity from "@core/activities/smart-question/buildTestSmartQuestionActivity";
import type RecognitionCategory from "@core/enums/RecognitionCategory";

interface Context {

    getCompleteSurveyActivitiesAsync: (onPulseSurveyCustomTextQuestionAnswered: boolean) => Promise<BuiltActivity[]>;
    getContinueSurveyActivitiesAsync: <QuestionId extends string>(questionId: QuestionId, answerScore: number, answerValue: number, onPulseSurveyCustomTextQuestionAnswered: boolean) => Promise<BuiltActivity[]>;
    getTryPulseSurveyActivitiesAsync: () => Promise<[ReturnType<typeof buildTryPulseSurveyActivity>]>;
    getCustomPollActivitiesAsync: (customPollId: string) => Promise<[ReturnType<typeof buildCustomPollActivity<CustomPollDetails>>]>;
    getPreviewCustomPollActivityAsync: (customPollId: string) => Promise<[ReturnType<typeof buildPreviewCustomPollActivity>]>;
    getRecognitionActivityAsync: () => Promise<[ReturnType<typeof buildRecognitionActivity>] | []>;
    getOnboardingSurveyActivityAsync: () => Promise<(ReturnType<typeof buildOnboardingSurveyActivity> | ReturnType<typeof buildCustomPollActivity<CustomPollDetails>> | null)[]>;
    getTestPulseSurveyQuestionActivitiesAsync: (questionId: PulseSurveyQuestionId) => Promise<[ReturnType<typeof buildTestPulseSurveyQuestionActivity>]>;
    getTestCustomPollActivitiesAsync: () => Promise<[ReturnType<typeof buildTestCustomPollActivity>]>;
    getTestSmartQuestionActivitiesAsync: (metricId: string, subMetricId: string) => Promise<[ReturnType<typeof buildTestSmartQuestionActivity>]>;
    getTestRecognitionActivityAsync: () => Promise<[ReturnType<typeof buildTestRecognitionActivity>]>;
    getTestOnboardingSurveyActivityAsync: (questionId: OnboardingQuestionID) => Promise<[ReturnType<typeof buildTestOnboardingSurveyQuestionActivity>]>;
    upsertOnboardingSurveyAnswerAsync: (correlationId: GuidString, questionId: OnboardingQuestionID, score: number) => Promise<void>;
    upsertOnboardingSurveyAnswerSkippedAsync: (correlationId: GuidString, questionId: OnboardingQuestionID) => Promise<void>;
    upsertOnboardingSurveyFollowUpAnswerSkippedAsync: (correlationId: GuidString, questionId: OnboardingQuestionID) => Promise<void>;
    upsertOnboardingSurveyFollowUpAnswerAsync: (correlationId: GuidString, questionId: OnboardingQuestionID, feedback: string) => Promise<void>;
    deleteOnboardingSurveyFollowUpAnswerAsync: (correlationId: GuidString, questionId: OnboardingQuestionID) => Promise<void>;
    onOnboardingSurveyActivityEndedAsync: (correlationId: GuidString) => Promise<void>;
    getTestDeibSurveyActivityAsync: (questionId: DeibQuestionID) => Promise<[ReturnType<typeof buildTestDeibSurveyQuestionActivity>]>;
    upsertDeibSurveyAnswerAsync: (correlationId: GuidString, questionId: DeibQuestionID, score: number) => Promise<void>;
    upsertDeibSurveyAnswerSkippedAsync: (correlationId: GuidString, questionId: DeibQuestionID) => Promise<void>;
    onDeibSurveyActivityEndedAsync: (correlationId: GuidString) => Promise<void>;
    upsertPulseSurveyAnswerAsync: (correlationId: GuidString, questionId: PulseSurveyQuestionId, score: number) => Promise<void>;
    upsertPulseSurveyAnswerSkippedAsync: (correlationId: GuidString, questionId: PulseSurveyQuestionId) => Promise<void>;
    upsertPulseSurveyFollowUpAnswerSkippedAsync: (correlationId: GuidString, questionId: PulseSurveyQuestionId) => Promise<void>;
    upsertPulseSurveyFollowUpAnswerAsync: (correlationId: GuidString, questionId: PulseSurveyQuestionId, isConstructive: boolean, feedback: string, isAnonymous: boolean) => Promise<void>;
    deletePulseSurveyFollowUpAnswerAsync: (correlationId: GuidString, questionId: PulseSurveyQuestionId) => Promise<void>;
    onPulseSurveyActivityEnded: (correlationId: GuidString) => Promise<void>;
    upsertCustomPollAnswerAsync: (customPollId: string, questionId: string, answer: CustomPollsActivityControllerSaveAnswerRequest) => Promise<void>;
    upsertCustomPollAnswerSkippedAsync: (customPollId: string, questionId: string) => Promise<void>;
    upsertCustomPollFollowUpAnswerSkippedAsync: (customPollId: string, questionId: string) => Promise<void>;
    upsertCustomPollFollowUpAnswerAsync: (customPollId: string, questionId: string, feedback: string, isAnonymous: boolean) => Promise<void>;
    deleteCustomPollTextAnswerAsync: (customPollId: string, questionId: string) => Promise<void>;
    deleteCustomPollFollowUpAnswerAsync: (customPollId: string, questionId: string) => Promise<void>;
    onCustomPollActivityEnded: (customPollId: string) => Promise<void>;
    upsertSmartQuestionFeedbackAsync: (subMetricId: string, feedback: string, isAnonymous: boolean) => Promise<void>;
    upsertSmartQuestionSkippedAsync: (subMetricId: string) => Promise<void>;
    onSmartQuestionActivityEnded: (subMetricId: string) => Promise<void>;
    searchPeersAsync: (query: string) => Promise<UserIdentity[]>;
    upsertOfficevibeRecognitionAsync: (correlationId: GuidString, recipientId: string, categoryId: RecognitionCategory, promptId: string, message: string) => Promise<void>;
    upsertCustomRecognitionAsync: (correlationId: GuidString, recipientId: string, categoryId: string, promptId: string, message: string) => Promise<void>;
    upsertRecognitionSkippedAsync: (correlationId: GuidString) => Promise<void>;
    onRecognitionActivityEnded: (correlationId: GuidString) => Promise<void>;
}

const ActivitiesServerSyncContext = React.createContext<Context | null>(null);

export default ActivitiesServerSyncContext;

export const useActivitiesServerSyncContext = () => {
    const context = useContext(ActivitiesServerSyncContext);

    if (!context) {
        throw new Error("ActivitiesServerSyncContext not found; are you missing ActivitiesServerSyncContextProvider?");
    }

    return context;
};