import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./inline-labeled-form-field.scss";

interface Props {
    className?: string;
    label: React.ReactNode;
    children: React.ReactNode;
    htmlFor?: string;
}

const InlineLabeledFormField = ({ className, label, children, htmlFor }: Props) => {
    const classes = classNames(
        "inline-labeled-form-field",
        className
    );

    return (
        <div className={classes}>
            <label className="inline-labeled-form-field__label" htmlFor={htmlFor}>
                {label}
            </label>
            <div className="inline-labeled-form-field__content">
                {children}
            </div>
        </div>
    );
};

InlineLabeledFormField.propTypes = {
    className: PropTypes.string,
    label: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    htmlFor: PropTypes.string
};

export default InlineLabeledFormField;