import { type RefObject, useEffect } from "react";

const Handled = "handled";
const NotHandled = "not-handled";

type HandlerResult = "handled" | "not-handled";

type KeyboardHandler = ((e: KeyboardEvent) => HandlerResult) | {
    shift?: boolean;
    ctrl?: boolean;
    alt?: boolean;
    method: () => HandlerResult;
};

// An exercise left to the next TypeScript guru: create a template literal type
// that matches any number of 'KeyCodes' values separated by pipes ('|'), to
// replace 'string' here.

export default function useKeyboard(keyMap: Record<string, KeyboardHandler>, ref: RefObject<HTMLElement> | null = null) {
    const handler = (e: KeyboardEvent) => {
        const { cancelBubble, keyCode } = e;

        if (cancelBubble) {
            return;
        }

        const match = Object.keys(keyMap).find(keyChain => keyChain.split("|").some(key => key === keyCode.toString()));

        if (match) {
            const data = keyMap[match];

            if (typeof data === "function") {
                const result = data(e);

                if (result !== NotHandled) {
                    // If the method returns nothing, we should still prevent default
                    e.preventDefault();
                    e.stopPropagation();
                }
            } else {
                const shouldShiftBePressed = !!data["shift"];
                const shouldCtrlBePressed = !!data["ctrl"];
                const shouldAltBePressed = !!data["alt"];

                const isShiftConditionCleared = !shouldShiftBePressed || (shouldShiftBePressed && !!e.shiftKey);
                const isCtrlConditionCleared = !shouldCtrlBePressed || (shouldCtrlBePressed && (!!e.ctrlKey || !!e.metaKey));
                const isAltConditionCleared = !shouldAltBePressed || (shouldAltBePressed && !!e.altKey);

                if (isShiftConditionCleared && isCtrlConditionCleared && isAltConditionCleared) {
                    const result = data.method();

                    if (result !== NotHandled) {
                        // If the method returns nothing, we should still prevent default
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }
            }
        }
    };

    useEffect(() => {
        // HACK: Window and HTMLElement don't overlap beyond EventTarget, but
        // both have an event map based on GlobalEventHandlersEventMap, so this
        // is good enough for our needs.
        const handle = ref?.current || window as unknown as HTMLElement;
        handle.addEventListener("keydown", handler);

        return () => {
            handle.removeEventListener("keydown", handler);
        };
    });
}

export {
    Handled,
    NotHandled
};