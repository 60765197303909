import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Select from "@components/select/Select";

import DeliveryChannel from "@core/enums/DeliveryChannel";

const DeliveryChannelSelect = ({ className, value, defaultDeliveryChannel, onChange }) => {
    const { t } = useTranslation("activities");
    
    const options = [
        defaultDeliveryChannel,
        DeliveryChannel.PersonalEmail,
        DeliveryChannel.Sms
    ];

    const getLabel = (option) => {
        return t(`onboarding.deliveryChannel${option}`);
    };

    const renderValue = (option) => {
        return getLabel(option);
    };

    const renderOption = (option, isKeyboardFocused, onClick) => {
        return (
            <Select.Option key={option} onClick={onClick} selected={value === option} isKeyboardFocused={isKeyboardFocused}>
                {getLabel(option)}
            </Select.Option>
        );
    };

    return (
        <Select className={className}
            value={value}
            renderValue={renderValue}
            options={options}
            renderOption={renderOption}
            onChange={onChange} />
    );
};

DeliveryChannelSelect.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string.isRequired,
    defaultDeliveryChannel: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default DeliveryChannelSelect;