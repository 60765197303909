import React, { useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";
import useDelayedEffect from "@hooks/useDelayedEffect";
import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import CustomPollActivity from "@components/activities/CustomPollActivity";
import DeibSurveyActivity from "@components/activities/DeibSurveyActivity";
import OnboardingSurveyActivity from "@components/activities/OnboardingSurveyActivity";
import PreviewCompletedOutro from "@components/activities/outro/PreviewCompletedOutro";
import PulseSurveyOutro from "@components/activities/outro/PulseSurveyOutro";
import RecognitionOutro from "@components/activities/outro/RecognitionOutro";
import PulseSurveyActivity from "@components/activities/PulseSurveyActivity";
import RecognitionActivity from "@components/activities/RecognitionActivity";
import SmartQuestionActivity from "@components/activities/SmartQuestionActivity";

import ActivityBackgroundTheme from "@core/enums/ActivityBackgroundTheme";
import ActivityBackgroundType from "@core/enums/ActivityBackgroundType";
import ActivityType from "@core/enums/ActivityType";
import CustomPollStepType from "@core/enums/CustomPollStepType";
import OnboardingSurveyStepType from "@core/enums/OnboardingSurveyStepType";
import OutroType from "@core/enums/OutroType";
import PulseSurveyStepType from "@core/enums/PulseSurveyStepType";
import TipType from "@core/enums/TipType";
import { trackActivityCompleted, trackButtonClicked, trackFeedbackGiven, trackQuestionAnswered } from "@core/tracking/track";
import TrackingInteractionType from "@core/tracking/TrackingInteractionType";
import TrackingPropertyName from "@core/tracking/TrackingPropertyName";
import sleep from "@core/utils/sleep";

import ActivitiesBanner from "./ActivitiesBanner";
import ActivitiesControlBar from "./ActivitiesControlBar";
import Tip from "./Tip";

import { ReactComponent as Logo } from "@svg/logo.svg";

const AnimationState = {
    Intro: "intro",
    Idle: "idle",
    PendingBetweenSteps: "pending-between-steps",
    PendingBetweenActivities: "pending-between-activities",
    ActivitySlideIn: "activity-slide-in",
    ActivitySlideOut: "activity-slide-out",
    StepSlideIn: "step-slide-in",
    StepSlideOut: "step-slide-out",
    StepSlideInReverse: "step-slide-in-reverse",
    StepSlideOutReverse: "step-slide-out-reverse",
    Outro: "outro"
};

const activitySlideInDuration = 800;
const activitySlideOutDuration = 800;
const stepSlideInDuration = 400;
const stepSlideOutDuration = 400;
const exitToOutroDuration = 1000;

import "./activities-flow.scss";

const ActivitiesFlow = ({ className, outro, activity, controlBarState, bannerState, backgroundState, currentTip, ...callbacks }) => {
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);
    const { isNetworkAdmin, isCompanyManager, isGroupManager } = useAuthenticatedUser();

    const [controlBarSnapshotRefreshKey, setControlBarSnapshotRefreshKey] = useState(0);
    const [displayContent, setDisplayContent] = useState(false);
    const [displayControlBar, setDisplayControlBar] = useState(false);
    const [displayBanner, setDisplayBanner] = useState(false);
    const [animationState, setAnimationState] = useState(AnimationState.Intro);
    const areInteractionsDisabled = isWorkleapBrandEnabled
        ? animationState !== AnimationState.Idle && animationState !== AnimationState.Outro
        : animationState !== AnimationState.Idle;

    useDelayedEffect(() => {
        setDisplayContent(true);
    }, [], 600);

    const refreshControlBarShapshotKey = () => {
        setControlBarSnapshotRefreshKey(k => k + 1);
    };

    const handleOnIntroAnimationCompleted = () => {
        setDisplayControlBar(true);
        setDisplayBanner(true);
        setAnimationState(AnimationState.Idle);
    };

    const handleOnGoToOutro = async() => {
        // To dismiss event triggered by the keyboard if interactions are disabled
        if (areInteractionsDisabled) {
            return;
        }

        setAnimationState(AnimationState.ActivitySlideOut);
        await sleep(activitySlideOutDuration);
        setAnimationState(AnimationState.Outro);
        await sleep(exitToOutroDuration);
        callbacks.onGoToOutro();
    };

    const handleOnAnimateToNextActivity = async() => {
        // To dismiss event triggered by the keyboard if interactions are disabled
        if (areInteractionsDisabled) {
            return;
        }

        setAnimationState(AnimationState.ActivitySlideOut);
        await sleep(activitySlideOutDuration);
        setAnimationState(AnimationState.PendingBetweenActivities);
        callbacks.onGoToNextActivity();
        refreshControlBarShapshotKey();
        setAnimationState(AnimationState.ActivitySlideIn);
        await sleep(activitySlideInDuration);
        setAnimationState(AnimationState.Idle);
    };

    const handleOnAnimateToPreviousStep = async() => {
        setAnimationState(AnimationState.StepSlideOutReverse);
        await sleep(stepSlideOutDuration);
        setAnimationState(AnimationState.PendingBetweenSteps);
        callbacks.onGoToPreviousStep();
        refreshControlBarShapshotKey();
        setAnimationState(AnimationState.StepSlideInReverse);
        await sleep(stepSlideInDuration);
        setAnimationState(AnimationState.Idle);
    };

    const handleOnAnimateToNextStep = async(isSkip = false) => {
        setAnimationState(AnimationState.StepSlideOut);
        await sleep(stepSlideOutDuration);
        setAnimationState(AnimationState.PendingBetweenSteps);
        callbacks.onGoToNextStep(isSkip);
        refreshControlBarShapshotKey();
        setAnimationState(AnimationState.StepSlideIn);
        await sleep(stepSlideInDuration);
        setAnimationState(AnimationState.Idle);
    };

    const goToNextActivityOrOutro = () => {
        switch (activity.activityType) {
            case ActivityType.PulseSurvey:
            case ActivityType.ContinuePulseSurvey:
            case ActivityType.TryPulseSurvey:
            case ActivityType.TestPulseSurveyQuestion:
                callbacks.onPulseSurveyActivityEnded(activity.correlationId);
                break;
            case ActivityType.CustomPoll:
            case ActivityType.PreviousCustomPoll:
                callbacks.onCustomPollActivityEnded(activity.customPollId);
                break;
            case ActivityType.SmartQuestion:
            case ActivityType.TrySmartQuestion:
                callbacks.onSmartQuestionActivityEnded(activity.subMetricId);
                break;
            case ActivityType.DeibSurvey:
            case ActivityType.TestDeibSurvey:
                callbacks.onDeibSurveyActivityEnded(activity.correlationId);
                break;
            case ActivityType.OnboardingSurvey:
            case ActivityType.TestOnboardingSurvey:
            case ActivityType.ContinueOnboardingSurvey:
                callbacks.onOnboardingSurveyActivityEnded(activity.correlationId);
                break;
        }

        trackActivityCompleted(isNetworkAdmin, isCompanyManager, isGroupManager, activity.activityType, activity.isFirstActivity);

        if (activity.isLastActivity) {
            handleOnGoToOutro();
        }
        else {
            handleOnAnimateToNextActivity();
        }
    };

    const goToPreviousStep = () => {
        // To dismiss event triggered by the keyboard if interactions are disabled
        if (areInteractionsDisabled) {
            return;
        }

        if (activity.activityType !== ActivityType.PreviewCustomPoll) {
            trackButtonClicked(isNetworkAdmin, isCompanyManager, isGroupManager, activity.activityType, "Previous");
        }

        handleOnAnimateToPreviousStep();
    };

    const goToNextStep = (isSkip = false) => {
        // To dismiss event triggered by the keyboard if interactions are disabled
        if (areInteractionsDisabled) {
            return;
        }

        switch (activity.activityType) {
            case ActivityType.PulseSurvey:
            case ActivityType.ContinuePulseSurvey:
            case ActivityType.TryPulseSurvey:
            case ActivityType.TestPulseSurveyQuestion:
            {
                const pulseSurveyStep = activity.steps[activity.currentStepIndex];
                const nextPulseSurveyStep = activity.steps[activity.currentStepIndex + 1];

                if (isSkip && !pulseSurveyStep.isLastStep && nextPulseSurveyStep.isLastStep && nextPulseSurveyStep.stepType === PulseSurveyStepType.AskFollowUpQuestion) {
                    goToNextActivityOrOutro();

                    return;
                }
                else if (!pulseSurveyStep.isLastStep) {
                    handleOnAnimateToNextStep(true);

                    return;
                }
                break;
            }

            case ActivityType.CustomPoll:
            case ActivityType.PreviewCustomPoll:
            case ActivityType.TestCustomPoll:
            {
                const customPollStep = activity.steps[activity.currentStepIndex];
                const nextCustomPollStep = activity.steps[activity.currentStepIndex + 1];

                if (isSkip && !customPollStep.isLastStep && nextCustomPollStep.isLastStep && nextCustomPollStep.stepType === CustomPollStepType.AskFollowUpQuestion) {
                    goToNextActivityOrOutro();

                    return;
                }
                else if (!customPollStep.isLastStep) {
                    handleOnAnimateToNextStep(true);

                    return;
                }
                break;
            }

            case ActivityType.OnboardingSurvey:
            case ActivityType.TestOnboardingSurvey:
            case ActivityType.ContinueOnboardingSurvey:
            {
                const onboardingSurveyStep = activity.steps[activity.currentStepIndex];
                const nextOnboardingSurveyStep = activity.steps[activity.currentStepIndex + 1];

                if (isSkip && !onboardingSurveyStep.isLastStep && nextOnboardingSurveyStep.isLastStep && nextOnboardingSurveyStep.stepType === OnboardingSurveyStepType.AskFollowUpQuestion) {
                    goToNextActivityOrOutro();

                    return;
                }
                else if (!onboardingSurveyStep.isLastStep) {
                    handleOnAnimateToNextStep(true);

                    return;
                }
                break;
            }

            case ActivityType.DeibSurvey:
            case ActivityType.TestDeibSurvey:
            {
                const deibSurveyStep = activity.steps[activity.currentStepIndex];

                if (!deibSurveyStep.isLastStep) {
                    handleOnAnimateToNextStep(true);

                    return;
                }

                goToNextActivityOrOutro();

                return;
            }
        }

        goToNextActivityOrOutro();
    };

    const handleOnSkip = () => {
        switch (activity.activityType) {
            case ActivityType.PulseSurvey:
            case ActivityType.ContinuePulseSurvey:
            case ActivityType.TryPulseSurvey:
            case ActivityType.TestPulseSurveyQuestion:
            {
                const pulseSurveyStep = activity.steps[activity.currentStepIndex];
                const isFollowUpQuestionStep = pulseSurveyStep.stepType === PulseSurveyStepType.AskFollowUpQuestion;

                if (isFollowUpQuestionStep) {
                    trackFeedbackGiven(isNetworkAdmin, isCompanyManager, isGroupManager, activity.activityType, TrackingInteractionType.Skipped, undefined, pulseSurveyStep.questionId);
                }
                else {
                    trackQuestionAnswered(isNetworkAdmin, isCompanyManager, isGroupManager, activity.activityType, TrackingInteractionType.Skipped, pulseSurveyStep.questionId);
                }

                callbacks.onPulseSurveyQuestionSkipped(activity.correlationId, pulseSurveyStep.questionId, isFollowUpQuestionStep);

                break;
            }

            case ActivityType.CustomPoll:
            case ActivityType.PreviewCustomPoll:
            case ActivityType.TestCustomPoll:
            {
                const customPollStep = activity.steps[activity.currentStepIndex];
                const isFollowUpQuestionStep = customPollStep.stepType === CustomPollStepType.AskFollowUpQuestion;

                if (activity.activityType === ActivityType.CustomPoll) {
                    if (isFollowUpQuestionStep) {
                        trackFeedbackGiven(isNetworkAdmin, isCompanyManager, isGroupManager, activity.activityType, TrackingInteractionType.Skipped, undefined, customPollStep.questionId);
                    }
                    else {
                        trackQuestionAnswered(isNetworkAdmin, isCompanyManager, isGroupManager, activity.activityType, TrackingInteractionType.Skipped, customPollStep.questionId);
                    }
                }

                callbacks.onCustomPollQuestionSkipped(activity.customPollId, customPollStep.questionId, isFollowUpQuestionStep);

                break;
            }

            case ActivityType.SmartQuestion:
            case ActivityType.TestSmartQuestion:
            {
                trackFeedbackGiven(isNetworkAdmin, isCompanyManager, isGroupManager, activity.activityType, TrackingInteractionType.Skipped, undefined, undefined, activity.subMetricId);

                callbacks.onSmartQuestionSkipped(activity.subMetricId);

                break;
            }

            case ActivityType.Recognition:
            case ActivityType.TestRecognition:
            {
                trackButtonClicked(isNetworkAdmin, isCompanyManager, isGroupManager, "Recognition select card", "Skip recognition", { [TrackingPropertyName.CorrelationId]: activity.correlationId });
                callbacks.onRecognitionSkipped(activity.correlationId);

                break;
            }

            case ActivityType.OnboardingSurvey:
            case ActivityType.TestOnboardingSurvey:
            case ActivityType.ContinueOnboardingSurvey:
            {
                const onboardingSurveyStep = activity.steps[activity.currentStepIndex];
                const isFollowUpQuestionStep = onboardingSurveyStep.stepType === OnboardingSurveyStepType.AskFollowUpQuestion;

                if (isFollowUpQuestionStep) {
                    trackFeedbackGiven(isNetworkAdmin, isCompanyManager, isGroupManager, activity.activityType, TrackingInteractionType.Skipped, undefined, onboardingSurveyStep.questionId);
                }
                else {
                    trackQuestionAnswered(isNetworkAdmin, isCompanyManager, isGroupManager, activity.activityType, TrackingInteractionType.Skipped, onboardingSurveyStep.questionId);
                }

                callbacks.onOnboardingSurveyQuestionSkipped(activity.correlationId, onboardingSurveyStep.questionId, isFollowUpQuestionStep);

                break;
            }

            case ActivityType.DeibSurvey:
            case ActivityType.TestDeibSurvey:
            {
                const deibSurveyStep = activity.steps[activity.currentStepIndex];

                trackQuestionAnswered(isNetworkAdmin, isCompanyManager, isGroupManager, activity.activityType, TrackingInteractionType.Skipped, deibSurveyStep.questionId);

                callbacks.onDeibSurveyQuestionSkipped(activity.correlationId, deibSurveyStep.questionId);

                break;
            }

            default:
                throw new Error(`Activity Type (${activity.activityType}) not supported`);
        }

        goToNextStep(true);
    };

    const renderActivity = () => {
        switch (activity.activityType) {
            case ActivityType.PulseSurvey:
            case ActivityType.ContinuePulseSurvey:
            case ActivityType.TryPulseSurvey:
            case ActivityType.TestPulseSurveyQuestion:
                return (
                    <PulseSurveyActivity key={activity.correlationId}
                        {...activity}
                        isIntroAnimated={animationState === AnimationState.Intro}
                        onIntroAnimationCompleted={handleOnIntroAnimationCompleted}

                        onSliderQuestionAnswered={callbacks.onPulseSurveyQuestionAnswered}
                        onTwoWaySliderQuestionAnswered={callbacks.onPulseSurveyQuestionAnswered}
                        onStarQuestionAnswered={callbacks.onPulseSurveyQuestionAnswered}
                        onLikertQuestionAnswered={callbacks.onPulseSurveyQuestionAnswered}
                        onMultipleChoiceQuestionAnswered={callbacks.onPulseSurveyQuestionAnswered}
                        onChangeFeedbackAnonymity={callbacks.onPulseSurveyFeedbackAnonymityChanged}
                        onFollowUpFeedbackLeft={callbacks.onPulseSurveyFollowUpAnswerLeft}
                        onPulseSurveyCustomTextQuestionAnswered={callbacks.onPulseSurveyCustomTextQuestionAnswered}
                        onActivityEnded={callbacks.onPulseSurveyActivityEnded}

                        onGoToPreviousStep={handleOnAnimateToPreviousStep}
                        onGoToNextStep={handleOnAnimateToNextStep}
                        onGoToNextActivity={handleOnAnimateToNextActivity}
                        onGoToOutro={handleOnGoToOutro} />
                );

            case ActivityType.CustomPoll:
            case ActivityType.PreviewCustomPoll:
            case ActivityType.TestCustomPoll:
                return (
                    <CustomPollActivity key={activity.customPollId}
                        {...activity}
                        isIntroAnimated={animationState === AnimationState.Intro}
                        onIntroAnimationCompleted={handleOnIntroAnimationCompleted}

                        onTextQuestionAnswered={callbacks.onCustomPollTextQuestionAnswered}
                        onOpinionScaleQuestionAnswered={callbacks.onCustomPollOpinionScaleQuestionAnswered}
                        onMultipleChoiceQuestionAnswered={callbacks.onCustomPollMultipleChoiceQuestionAnswered}
                        onMultipleChoiceQuestionWithMultiSelectionAnswered={callbacks.onCustomPollMultipleChoiceQuestionWithMultiSelectionAnswered}
                        onLikertScaleQuestionAnswered={callbacks.onCustomPollLikertScaleQuestionAnswered}
                        onChangeFeedbackAnonymity={callbacks.onCustomPollFeedbackAnonymityChanged}
                        onFollowUpFeedbackLeft={callbacks.onCustomPollFollowUpAnswerLeft}
                        onActivityEnded={callbacks.onCustomPollActivityEnded}

                        onGoToPreviousStep={handleOnAnimateToPreviousStep}
                        onGoToNextStep={handleOnAnimateToNextStep}
                        onGoToNextActivity={handleOnAnimateToNextActivity}
                        onGoToOutro={handleOnGoToOutro} />
                );

            case ActivityType.SmartQuestion:
            case ActivityType.TestSmartQuestion:
                return (
                    <SmartQuestionActivity key={activity.subMetricId}
                        {...activity}
                        isIntroAnimated={animationState === AnimationState.Intro}
                        onIntroAnimationCompleted={handleOnIntroAnimationCompleted}

                        onChangeFeedbackAnonymity={callbacks.onSmartQuestionFeedbackAnonymityChanged}
                        onFeedbackLeft={callbacks.onSmartQuestionFeedbackLeft}
                        onActivityEnded={callbacks.onSmartQuestionActivityEnded}

                        onGoToNextActivity={handleOnAnimateToNextActivity}
                        onGoToOutro={handleOnGoToOutro} />
                );

            case ActivityType.Recognition:
            case ActivityType.TestRecognition:
            {
                return (
                    <RecognitionActivity key={activity.correlationId}
                        {...activity}
                        isIntroAnimated={animationState === AnimationState.Intro}
                        onIntroAnimationCompleted={handleOnIntroAnimationCompleted}

                        searchPeersAsync={callbacks.searchPeersAsync}

                        onPromptSelected={callbacks.onRecognitionPromptSelected}
                        onRecognitionLeft={callbacks.onRecognitionLeft}
                        onActivityEnded={callbacks.onRecognitionActivityEnded}

                        onGoToPreviousStep={handleOnAnimateToPreviousStep}
                        onGoToNextStep={handleOnAnimateToNextStep}
                        onGoToNextActivity={handleOnAnimateToNextActivity}
                        onGoToOutro={handleOnGoToOutro} />
                );
            }
            case ActivityType.OnboardingSurvey:
            case ActivityType.TestOnboardingSurvey:
            case ActivityType.ContinueOnboardingSurvey:
                return (
                    <OnboardingSurveyActivity key={activity.correlationId}
                        {...activity}
                        isIntroAnimated={animationState === AnimationState.Intro}
                        onIntroAnimationCompleted={handleOnIntroAnimationCompleted}
                        onSliderQuestionAnswered={callbacks.onOnboardingSurveyQuestionAnswered}
                        onLikertQuestionAnswered={callbacks.onOnboardingSurveyQuestionAnswered}
                        onMultipleChoiceQuestionAnswered={callbacks.onOnboardingSurveyQuestionAnswered}
                        onFollowUpFeedbackLeft={callbacks.onOnboardingSurveyFollowUpAnswerLeft}
                        onActivityEnded={callbacks.onOnboardingSurveyActivityEnded}
                        onGoToPreviousStep={handleOnAnimateToPreviousStep}
                        onGoToNextStep={handleOnAnimateToNextStep}
                        onGoToNextActivity={handleOnAnimateToNextActivity}
                        onGoToOutro={handleOnGoToOutro} />
                );

            case ActivityType.DeibSurvey:
            case ActivityType.TestDeibSurvey:
                return (
                    <DeibSurveyActivity key={activity.correlationId}
                        {...activity}
                        onIntroAnimationCompleted={handleOnIntroAnimationCompleted}
                        onSliderQuestionAnswered={callbacks.onDeibSurveyQuestionAnswered}
                        onLikertQuestionAnswered={callbacks.onDeibSurveyQuestionAnswered}
                        onMultipleChoiceQuestionAnswered={callbacks.onDeibSurveyQuestionAnswered}
                        onActivityEnded={callbacks.onDeibSurveyActivityEnded}
                        onGoToPreviousStep={handleOnAnimateToPreviousStep}
                        onGoToNextStep={handleOnAnimateToNextStep}
                        onGoToNextActivity={handleOnAnimateToNextActivity}
                        onGoToOutro={handleOnGoToOutro} />
                );

            default:
                throw new Error(`Activity Type (${activity.activityType}) not supported`);
        }
    };

    const renderBanner = () => {
        const isHidden = animationState === AnimationState.ActivitySlideOut ||
            animationState === AnimationState.PendingBetweenActivities ||
            animationState === AnimationState.Outro ||
            !!outro;

        return <ActivitiesBanner {...bannerState} isHidden={isHidden} />;
    };

    const renderControlBar = () => {
        const isHidden = animationState === AnimationState.ActivitySlideOut ||
            animationState === AnimationState.PendingBetweenActivities ||
            animationState === AnimationState.Outro ||
            !!outro;

        return (
            <ActivitiesControlBar {...controlBarState}
                snapshotRefreshKey={controlBarSnapshotRefreshKey}
                isHidden={isHidden || controlBarState.isHidden}
                onPreviousClick={goToPreviousStep}
                onSkipClick={handleOnSkip}
                onNextClick={goToNextStep} />
        );
    };

    const renderTip = () => {
        const isHidden = animationState !== AnimationState.Idle || !!outro;

        return (
            <Tip type={currentTip} isHidden={isHidden} />
        );
    };

    const renderOutro = () => {
        switch (outro) {
            case OutroType.PreviewCompleted:
                return <PreviewCompletedOutro />;

            case OutroType.PulseSurveyRedirect:
                return <PulseSurveyOutro onRedirectToDashboard={callbacks.onRedirectToDashboard} />;

            case OutroType.RecognitionSentRedirect:
                return <RecognitionOutro onRedirectToDashboard={callbacks.onRedirectToDashboard} />;

            case OutroType.RecognitionSkippedRedirect:
                return <RecognitionOutro onRedirectToDashboard={callbacks.onRedirectToDashboard} isRecognitionSkipped />;
        }
    };

    const activitiesStepTypeClassName = isWorkleapBrandEnabled &&
                                        activity.currentStepIndex !== undefined &&
                                        activity.steps?.length > activity.currentStepIndex
        ? `activities-flow--${activity.activityType.toLowerCase()}-${activity.steps[activity.currentStepIndex].stepType.toLowerCase()}`
        : undefined;

    const classes = classNames(
        "activities-flow",
        `activities-flow--${animationState}`, {
            "activities-flow--interactions-disabled": areInteractionsDisabled
        },
        `activities-flow--${bannerState.type}`,
        activitiesStepTypeClassName,
        className
    );

    const logoClasses = classNames(
        "activities-flow__logo",
        `activities-flow__logo--on-${backgroundState.theme}-background`
    );

    if (outro) {
        if (isWorkleapBrandEnabled) {
            return (
                <div className={classes}>
                    <header className="activities-flow__header">
                        <Logo id="logo" className={logoClasses} />
                    </header>
                    {renderOutro()}
                </div>
            );
        }

        return renderOutro();
    }

    return (
        <div className={classes}>
            <header className="activities-flow__header">
                <Logo id="logo" className={logoClasses} />
            </header>
            <div className="activities-flow__tip">
                {renderTip()}
            </div>
            {displayContent && (
                <div className="activities-flow__content-section">
                    {renderActivity()}
                </div>
            )}
            {displayControlBar && (
                <div className="activities-flow__control-bar-section">
                    {renderControlBar()}
                </div>
            )}
            {displayBanner && (
                <div className="activities-flow__banner-section">
                    {renderBanner()}
                </div>
            )}
        </div>
    );
};

ActivitiesFlow.propTypes = {
    className: PropTypes.string,
    activity: PropTypes.object,
    controlBarState: PropTypes.object.isRequired,
    bannerState: PropTypes.shape({
        type: PropTypes.string.isRequired,
        metadata: PropTypes.object
    }).isRequired,
    backgroundState: PropTypes.shape({
        type: PropTypes.oneOf(Object.values(ActivityBackgroundType)).isRequired,
        theme: PropTypes.oneOf(Object.values(ActivityBackgroundTheme)).isRequired
    }).isRequired,
    currentTip: PropTypes.oneOf(Object.values(TipType)).isRequired,
    outro: PropTypes.string,
    onPulseSurveyQuestionAnswered: PropTypes.func,
    onPulseSurveyQuestionSkipped: PropTypes.func,
    onPulseSurveyFeedbackAnonymityChanged: PropTypes.func,
    onPulseSurveyFollowUpAnswerLeft: PropTypes.func,
    onPulseSurveyCustomTextQuestionAnswered: PropTypes.func,
    onPulseSurveyActivityEnded: PropTypes.func,
    onCustomPollTextQuestionAnswered: PropTypes.func,
    onCustomPollOpinionScaleQuestionAnswered: PropTypes.func,
    onCustomPollMultiplChoiceQuestionAnswered: PropTypes.func,
    onCustomPollQuestionSkipped: PropTypes.func,
    onCustomPollFeedbackLeft: PropTypes.func,
    onCustomPollActivityEnded: PropTypes.func,
    onSmartQuestionSkipped: PropTypes.func,
    onSmartQuestionFeedbackAnonymityChanged: PropTypes.func,
    onSmartQuestionFeedbackLeft: PropTypes.func,
    onSmartQuestionActivityEnded: PropTypes.func,
    searchPeersAsync: PropTypes.func,
    onRecognitionPromptSelected: PropTypes.func,
    onRecognitionSkipped: PropTypes.func,
    onGoToPreviousStep: PropTypes.func,
    onGoToNextStep: PropTypes.func,
    onGoToNextActivity: PropTypes.func,
    onGoToOutro: PropTypes.func,
    onRedirectToDashboard: PropTypes.func
};

export default ActivitiesFlow;