import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./signup-slide-title-header.scss";

const SignupSlideTitleHeader = ({ className, title, subtitle }) => {
    const classes = classNames(
        "signup-slide-title-header",
        className
    );

    return (
        <div className={classes}>
            <div className="signup-slide-title-header__title">
                {title}
            </div>
            {subtitle && (
                <div className="signup-slide-title-header__subtitle">
                    {subtitle}
                </div>
            )}
        </div>
    );
};

SignupSlideTitleHeader.propTypes = {
    className: PropTypes.string,
    title: PropTypes.node.isRequired,
    subtitle: PropTypes.node
};

export default SignupSlideTitleHeader;