import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./labeled-form-field.scss";

interface Props {
    className?: string;
    label: React.ReactNode;
    children: React.ReactNode;
    labelSuffix?: React.ReactNode;
    htmlFor?: string;
}

const LabeledFormField = ({ className, label, labelSuffix, children, htmlFor }: Props) => {
    const classes = classNames(
        "labeled-form-field",
        className
    );

    return (
        <div className={classes}>
            <label className="labeled-form-field__label" htmlFor={htmlFor}>
                {label}
                {labelSuffix}
            </label>
            {children}
        </div>
    );
};

LabeledFormField.propTypes = {
    className: PropTypes.string,
    label: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    labelSuffix: PropTypes.node,
    htmlFor: PropTypes.string
};

export default LabeledFormField;