import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { ReactComponent as IllustrationCurveArrow } from "@svg/illustrations/illustration-scribble-curve-arrow.svg";
import { ReactComponent as IllustrationCurveLine } from "@svg/illustrations/illustration-scribble-curve-line.svg";
import { ReactComponent as IllustrationExplosion } from "@svg/illustrations/illustration-scribble-explosion.svg";
import { ReactComponent as IllustrationHeart } from "@svg/illustrations/illustration-scribble-heart.svg";
import { ReactComponent as IllustrationLightning } from "@svg/illustrations/illustration-scribble-lightning.svg";
import { ReactComponent as IllustrationZigzag } from "@svg/illustrations/illustration-scribble-zigzag.svg";

import "./illustrated-background.scss";

interface Props {
    className?: string;
}

const IllustratedBackground = ({ className }: Props) => {
    const classes = classNames(
        "illustrated-background",
        className
    );

    return (
        <div className={classes}>
            <IllustrationLightning className="illustrated-background__lightning" />
            <IllustrationHeart className="illustrated-background__heart" />
            <IllustrationCurveLine className="illustrated-background__curve-line" />
            <IllustrationZigzag className="illustrated-background__zigzag" />
            <IllustrationCurveArrow className="illustrated-background__curve-arrow" />
            <IllustrationExplosion className="illustrated-background__explosion" />
        </div>
    );
};

IllustratedBackground.propTypes = {
    className: PropTypes.string
};

export default IllustratedBackground;