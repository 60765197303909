import React, { useRef } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useDelayedEffect from "@hooks/useDelayedEffect";

import "./continue-onboarding-survey-intro.scss";

const differentIntroTextsCount = 6;

interface Props {
    className?: string;
    onDisplayTimeoutEnd: () => void;
    isIntroAnimated: boolean;
}

const ContinueOnboardingSurveyIntro = ({
    className,
    onDisplayTimeoutEnd,
    isIntroAnimated
}: Props) => {
    const { t } = useTranslation("activities");
    const randomIndexForIntroTextRef = useRef(
        Math.floor(Math.random() * differentIntroTextsCount) + 1
    );
    const randomIndexForIntroText = randomIndexForIntroTextRef.current;

    useDelayedEffect(
        () => {
            if (isIntroAnimated) {
                onDisplayTimeoutEnd();
            }
        },
        [],
        1800
    );

    const classes = classNames("onboarding-survey-intro", className, {
        "onboarding-survey-intro--animated-intro": isIntroAnimated
    });

    return (
        <div className={classes}>
            <div className="onboarding-survey-intro__title">
                {t(
                    `onboardingSurveyFrame.continue-onboarding-survey-intro-title_${randomIndexForIntroText}`
                )}
            </div>
            <div className="onboarding-survey-intro__subtitle">
                {t(
                    `onboardingSurveyFrame.continue-onboarding-survey-intro-subtitle_${randomIndexForIntroText}`
                )}
            </div>
        </div>
    );
};

ContinueOnboardingSurveyIntro.propTypes = {
    className: PropTypes.string,
    onDisplayTimeoutEnd: PropTypes.func.isRequired,
    isIntroAnimated: PropTypes.bool.isRequired
};

export default ContinueOnboardingSurveyIntro;
