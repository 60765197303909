import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import PreviewBanner from "./PreviewBanner";

interface Props {
    className?: string;
}

const CustomPollPreviewBanner = ({ className }: Props) => {
    const { t } = useTranslation("activities");

    return (
        <PreviewBanner className={className}>
            {t("banners.preview-poll-banner")}
        </PreviewBanner>
    );
};

CustomPollPreviewBanner.propTypes = {
    className: PropTypes.string
};

export default CustomPollPreviewBanner;