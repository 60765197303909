import type { DateString } from "@/types/utilityTypes";

import { buildApiClient } from "@core/api-client/RetryableBearerApiClient";

export interface ActivitiesAuthContext {
    requestVerificationToken: string;
    jwtToken: string;
    jwtExpiry?: DateString;
}

export async function getContextAsync(token: string): Promise<ActivitiesAuthContext> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).getAsync("/api/v1/authentication/context", { token });
}