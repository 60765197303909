
import type { Step } from "@/types/activities/common";
import type { BuiltDeibSurveyActivity } from "@/types/activities/deib-survey";

import type DeibSurveyQuestionDisplayType from "@core/enums/DeibSurveyQuestionDisplayType";
import DeibSurveyStepType from "@core/enums/DeibSurveyStepType";

function insertAnswerFieldFor(step: Step<DeibSurveyStepType, DeibSurveyQuestionDisplayType>) {
    const { stepType } = step;

    switch (stepType) {
        case DeibSurveyStepType.Intro:
            return {};
        case DeibSurveyStepType.AskQuestion:
            return { answer: step.answer ?? null, score: step.score ?? null, isAnswered: step.isAnswered ?? false };
        default:
            throw new Error(`Step Type (${stepType}) not supported`);
    }
}

const buildDeibSurveyActivityOrchestrator = (activity: BuiltDeibSurveyActivity, isFirstActivity: boolean, isLastActivity: boolean) => {
    if (!activity.steps || activity.steps.length === 0) {
        throw new Error("Cannot orchestrate a DEIB survey with no steps");
    }

    return ({
        ...activity,
        isFirstActivity,
        isLastActivity,
        currentStepIndex: 0,
        steps: activity.steps.map((s, i) => ({
            ...s,
            isFirstStep: i === 0,
            isLastStep: i === activity.steps.length - 1,
            ...(insertAnswerFieldFor(s))
        }))
    });
};

export default buildDeibSurveyActivityOrchestrator;