import React from "react";

import { AngleRightIcon } from "@hopper-ui/icons";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import PrimaryButton from "@components/button/PrimaryButton";

import { ReactComponent as Illustration } from "@svg/illustrations/illustration-deib-survey.svg";
import { ReactComponent as LegacyIllustration } from "@svg/illustrations/legacy/illustration-deib-survey.svg";

import "./deib-intro.scss";

interface Props {
    onGoToNextStep: () => void;
}

const DeibIntro = ({ onGoToNextStep }: Props) => {
    const { t } = useTranslation("activities");
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);

    return (
        <div className="deib-intro__container">
            {isWorkleapBrandEnabled ? <Illustration className="deib-intro__illustration" /> : <LegacyIllustration />}
            <span className="deib-intro__title">{t("deibIntro.pageTitle")}</span>
            <span className="deib-intro__description">{t("deibIntro.pageDescription")}</span>
            <PrimaryButton
                className="deib-intro__button"
                onClick={onGoToNextStep}
                iconTrailing={<AngleRightIcon size="md" />}
            >
                {t("deibIntro.buttonText")}
            </PrimaryButton>
            <span className="deib-intro__subtext">{t("deibIntro.pageSubtext")}</span>
        </div>

    );
};

DeibIntro.propTypes = {
    onGoToNextStep: PropTypes.func.isRequired
};

export default DeibIntro;