import React from "react";

import PropTypes from "prop-types";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import RecognitionCategoryImage from "@core/enums/RecognitionCategoryImage";

import { ReactComponent as Illustration1 } from "@svg/recognition/illustration-1.svg";
import { ReactComponent as Illustration2 } from "@svg/recognition/illustration-2.svg";
import { ReactComponent as Illustration3 } from "@svg/recognition/illustration-3.svg";
import { ReactComponent as Illustration4 } from "@svg/recognition/illustration-4.svg";
import { ReactComponent as Illustration5 } from "@svg/recognition/illustration-5.svg";
import { ReactComponent as Illustration6 } from "@svg/recognition/illustration-6.svg";
import { ReactComponent as Illustration7 } from "@svg/recognition/illustration-7.svg";
import { ReactComponent as Illustration8 } from "@svg/recognition/illustration-8.svg";
import { ReactComponent as ExcellenceIllustration } from "@svg/recognition/illustration-excellence.svg";
import { ReactComponent as LeadershipIllustration } from "@svg/recognition/illustration-leadership.svg";
import { ReactComponent as PositivityIllustration } from "@svg/recognition/illustration-positivity.svg";
import { ReactComponent as ShowingUpIllustration } from "@svg/recognition/illustration-showingup.svg";
import { ReactComponent as TeamPlayerIllustration } from "@svg/recognition/illustration-teamplayer.svg";
import { ReactComponent as Illustration1Legacy } from "@svg/recognition/legacy/illustration-1.svg";
import { ReactComponent as Illustration2Legacy } from "@svg/recognition/legacy/illustration-2.svg";
import { ReactComponent as Illustration3Legacy } from "@svg/recognition/legacy/illustration-3.svg";
import { ReactComponent as Illustration4Legacy } from "@svg/recognition/legacy/illustration-4.svg";
import { ReactComponent as Illustration5Legacy } from "@svg/recognition/legacy/illustration-5.svg";
import { ReactComponent as Illustration6Legacy } from "@svg/recognition/legacy/illustration-6.svg";
import { ReactComponent as Illustration7Legacy } from "@svg/recognition/legacy/illustration-7.svg";
import { ReactComponent as Illustration8Legacy } from "@svg/recognition/legacy/illustration-8.svg";
import { ReactComponent as ExcellenceIllustrationLegacy } from "@svg/recognition/legacy/illustration-excellence.svg";
import { ReactComponent as LeadershipIllustrationLegacy } from "@svg/recognition/legacy/illustration-leadership.svg";
import { ReactComponent as PositivityIllustrationLegacy } from "@svg/recognition/legacy/illustration-positivity.svg";
import { ReactComponent as ShowingUpIllustrationLegacy } from "@svg/recognition/legacy/illustration-showingup.svg";
import { ReactComponent as TeamPlayerIllustrationLegacy } from "@svg/recognition/legacy/illustration-teamplayer.svg";

interface Props {
    className?: string;
    image: RecognitionCategoryImage;
}

const RecognitionCategorySvgImage = ({ className, image }: Props) => {
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);

    switch (image) {
        case RecognitionCategoryImage.Leadership:
            return isWorkleapBrandEnabled
                ? <LeadershipIllustration className={className} />
                : <LeadershipIllustrationLegacy className={className} />;

        case RecognitionCategoryImage.TeamPlayer:
            return isWorkleapBrandEnabled
                ? <TeamPlayerIllustration className={className} />
                : <TeamPlayerIllustrationLegacy className={className} />;

        case RecognitionCategoryImage.Positivity:
            return isWorkleapBrandEnabled
                ? <PositivityIllustration className={className} />
                : <PositivityIllustrationLegacy className={className} />;

        case RecognitionCategoryImage.Excellence:
            return isWorkleapBrandEnabled
                ? <ExcellenceIllustration className={className} />
                : <ExcellenceIllustrationLegacy className={className} />;

        case RecognitionCategoryImage.ShowingUp:
            return isWorkleapBrandEnabled
                ? <ShowingUpIllustration className={className} />
                : <ShowingUpIllustrationLegacy className={className} />;

        case RecognitionCategoryImage.Illustration1:
            return isWorkleapBrandEnabled
                ? <Illustration1 className={className} />
                : <Illustration1Legacy className={className} />;

        case RecognitionCategoryImage.Illustration2:
            return isWorkleapBrandEnabled
                ? <Illustration2 className={className} />
                : <Illustration2Legacy className={className} />;

        case RecognitionCategoryImage.Illustration3:
            return isWorkleapBrandEnabled
                ? <Illustration3 className={className} />
                : <Illustration3Legacy className={className} />;

        case RecognitionCategoryImage.Illustration4:
            return isWorkleapBrandEnabled
                ? <Illustration4 className={className} />
                : <Illustration4Legacy className={className} />;

        case RecognitionCategoryImage.Illustration5:
            return isWorkleapBrandEnabled
                ? <Illustration5 className={className} />
                : <Illustration5Legacy className={className} />;

        case RecognitionCategoryImage.Illustration6:
            return isWorkleapBrandEnabled
                ? <Illustration6 className={className} />
                : <Illustration6Legacy className={className} />;

        case RecognitionCategoryImage.Illustration7:
            return isWorkleapBrandEnabled
                ? <Illustration7 className={className} />
                : <Illustration7Legacy className={className} />;

        case RecognitionCategoryImage.Illustration8:
            return isWorkleapBrandEnabled
                ? <Illustration8 className={className} />
                : <Illustration8Legacy className={className} />;

        default:
            throw new Error(`Recognition Category image (${image}) not supported`);
    }
};

RecognitionCategorySvgImage.propTypes = {
    className: PropTypes.string,
    image: PropTypes.oneOf(Object.values(RecognitionCategoryImage)).isRequired
};

export default RecognitionCategorySvgImage;