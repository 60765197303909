import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import IllustratedBackground from "@components/activities/backgrounds/IllustratedBackground";

import ActivityBackgroundTheme from "@core/enums/ActivityBackgroundTheme";
import ActivityBackgroundType from "@core/enums/ActivityBackgroundType";

import "./activities-background.scss";

const ActivitiesBackground = ({ className, backgroundState }) => {
    const classes = classNames(
        "activities-background",
        `activities-background--${backgroundState.theme}`,
        className
    );

    return (
        <div className={classes}>
            {(() => {
                switch (backgroundState.type) {
                    case ActivityBackgroundType.None:
                        return null;
                    case ActivityBackgroundType.Illustrated:
                        return <IllustratedBackground key={backgroundState.type} className={backgroundState.className} />;
                    default:
                        throw new Error(`Activity Background Type (${backgroundState.type}) not supported`);
                }
            })()}
        </div>
    );
};

ActivitiesBackground.propTypes = {
    className: PropTypes.string,
    backgroundState: PropTypes.shape({
        type: PropTypes.oneOf(Object.values(ActivityBackgroundType)).isRequired,
        theme: PropTypes.oneOf(Object.values(ActivityBackgroundTheme)).isRequired,
        className: PropTypes.string
    }).isRequired
};

export default ActivitiesBackground;