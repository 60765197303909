import React, { useState } from "react";

import Textarea from "@igloo-ui/textarea";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import AnonymityToggle from "@components/anonymity-toggle/AnonymityToggle";
import PrimaryButton from "@components/button/PrimaryButton";

import { ReactComponent as AnonymousImage } from "@svg/anonymous-avatar.svg";

import "./interaction-box.scss";

const maxLength = 1500;

interface Props {
    initialFeedback?: string | null;
    initialIsAnonymous?: boolean;
    authorImageUrl?: string;
    authorFullName?: string;
    placeholder: string;
    submitLabel: string;
    disabled: boolean;
    onSubmit: (feedback: string, isAnonymous: boolean) => void;
    onChangeIsAnonymous: (isAnonymous: boolean) => void;
    allowEmptyFeedback?: boolean;
    disableAnonymous?: boolean;
    tooltip?: React.ReactNode;
}

const InteractionBox = ({
    initialFeedback,
    initialIsAnonymous,
    authorImageUrl,
    authorFullName,
    placeholder,
    submitLabel,
    disabled,
    onSubmit,
    onChangeIsAnonymous,
    allowEmptyFeedback,
    disableAnonymous,
    tooltip
}: Props) => {
    const { t } = useTranslation("activities");

    const [feedback, setFeedback] = useState(initialFeedback ?? "");
    const [isAnonymous, setIsAnonymous] = useState(initialIsAnonymous ?? true);

    const handleOnChangeIsAnonymous = (newIsAnonymous: boolean) => {
        setIsAnonymous(newIsAnonymous);
        onChangeIsAnonymous(newIsAnonymous);
    };

    const handleOnSubmit = () => {
        onSubmit(feedback.trim(), isAnonymous);
    };

    const imageUrl =
        authorImageUrl || `${window.env.CDN_URL}/assets/avatars/avatar-7.png`;
    const isFeedbackEmpty = !feedback || !feedback.trim();
    const canSubmitFeedback = !isFeedbackEmpty || allowEmptyFeedback;

    return (
        <div className="interaction-box">
            <div className="interaction-box__header">
                <div className="interaction-box__author">
                    {isAnonymous ? (
                        <AnonymousImage className="interaction-box__author-image" />
                    ) : (
                        <div
                            className="interaction-box__author-image"
                            style={{ backgroundImage: `url(${imageUrl})` }}
                        ></div>
                    )}
                    <div className="interaction-box__author-name">
                        {isAnonymous
                            ? t("interactionBox.anonymous-author")
                            : authorFullName ||
                              t("interactionBox.not-authenticated-author")}
                    </div>
                </div>
                <AnonymityToggle
                    className="interaction-box__anonymity-toggle"
                    isAnonymous={isAnonymous}
                    onChange={() => handleOnChangeIsAnonymous(!isAnonymous)}
                    disabled={disableAnonymous ?? disabled}
                    tooltip={tooltip}
                />
            </div>
            <Textarea
                className="interaction-box__text-area"
                placeholder={placeholder}
                rows={4}
                value={feedback}
                maxLength={maxLength}
                onChange={e => setFeedback(e.target.value)}
                isAutoResize
                autoFocus
                disabled={disabled}
            />
            <div className="interaction-box__footer">
                <PrimaryButton
                    className="interaction-box__button"
                    theme={PrimaryButton.Theme.Coral}
                    size={PrimaryButton.Size.Small}
                    onClick={handleOnSubmit}
                    disabled={disabled || !canSubmitFeedback}
                >
                    {submitLabel}
                </PrimaryButton>
            </div>
        </div>
    );
};

InteractionBox.propTypes = {
    initialFeedback: PropTypes.string,
    initialIsAnonymous: PropTypes.bool,
    authorImageUrl: PropTypes.string,
    authorFullName: PropTypes.string,
    placeholder: PropTypes.node.isRequired,
    submitLabel: PropTypes.node.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChangeIsAnonymous: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    allowEmptyFeedback: PropTypes.bool,
    disableAnonymous: PropTypes.bool,
    tooltip: PropTypes.node
};

export default InteractionBox;
