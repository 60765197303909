import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import Step from "./Step";

import "./stepper.scss";

interface Props {
    className?: string;
    currentStepIndex: number;
    totalSteps: number;
}

const Stepper = ({ className, currentStepIndex, totalSteps }: Props) => {
    const classes = classNames(
        "stepper",
        className
    );

    return (
        <div className={classes}>
            {Array.from({ length: totalSteps }, (_, i) => (
                <Step key={i} isCurrentStep={currentStepIndex === i} />
            ))}
        </div>
    );
};

Stepper.propTypes = {
    className: PropTypes.string,
    currentStepIndex: PropTypes.number.isRequired,
    totalSteps: PropTypes.number.isRequired
};

export default Stepper;