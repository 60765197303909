import React, { useContext, useEffect } from "react";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import ActivitiesContext from "@contexts/activities/ActivitiesContext";
import TipsContext from "@contexts/tips/TipsContext";

import ActivitiesPage from "./components/ActivitiesPage";

const CompletePulseSurveyPage = () => {
    const { initializeCompleteSurveyAsync } = useContext(ActivitiesContext);
    const { initializeTipsContextAsync } = useContext(TipsContext);
    const isPulseSurveyCustomTextualQuestionsEnabled = useIsFeatureEnabled(feature => feature.pulseSurveyCustomTextualQuestions);

    useEffect(() => {
        initializeCompleteSurveyAsync(isPulseSurveyCustomTextualQuestionsEnabled);
        initializeTipsContextAsync();
    }, [isPulseSurveyCustomTextualQuestionsEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ActivitiesPage />
    );
};

export default CompletePulseSurveyPage;