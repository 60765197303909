import type { Step } from "@/types/activities/common";
import type { BuiltPulseSurveyActivity, BuiltTryPulseSurveyActivity } from "@/types/activities/pulse-survey";

import type PulseSurveyQuestionDisplayType from "@core/enums/PulseSurveyQuestionDisplayType";
import PulseSurveyStepType from "@core/enums/PulseSurveyStepType";

function insertAnswerFieldFor(
    step: Step<PulseSurveyStepType, PulseSurveyQuestionDisplayType>
) {
    const { stepType } = step;

    switch (stepType) {
        case PulseSurveyStepType.Intro:
            return {};

        case PulseSurveyStepType.AskQuestion:
            return {
                answer: step.answer ?? null,
                score: step.score ?? null,
                isAnswered: step.isAnswered ?? false
            };

        case PulseSurveyStepType.AskFollowUpQuestion:
        case PulseSurveyStepType.AskCustomQuestion:
            return { feedback: null, isAnonymous: true, isAnswered: false };

        default:
            throw new Error(`Step Type (${stepType}) not supported`);
    }
}

const buildPulseSurveyActivityOrchestrator = <
    TPulseSurveyActivity extends
    | BuiltPulseSurveyActivity
    | BuiltTryPulseSurveyActivity =
    | BuiltPulseSurveyActivity
    | BuiltTryPulseSurveyActivity
>(
    activity: TPulseSurveyActivity,
    isFirstActivity: boolean,
    isLastActivity: boolean
) => {
    if (!activity.steps || activity.steps.length === 0) {
        throw new Error("Cannot orchestrate a pulse survey with no steps");
    }

    return {
        ...activity,
        isFirstActivity,
        isLastActivity,
        currentStepIndex: 0,
        steps: activity.steps.map((s, i) => ({
            ...s,
            isFirstStep: i === 0,
            isLastStep: i === activity.steps.length - 1,
            ...insertAnswerFieldFor(s)
        }))
    };
};

export default buildPulseSurveyActivityOrchestrator;
