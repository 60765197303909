import React, { useEffect } from "react";

import PropTypes from "prop-types";

import PulseSurveyFollowUpQuestion from "@components/pulse-survey-question/PulseSurveyFollowUpQuestion";

interface Props {
    questionScore: number;
    positiveQuestion: string;
    constructiveQuestion: string;
    feedback?: string;
    isAnonymous?: boolean;
    isAnswered?: boolean;
    onChangeAnonymity: (isAnonymous: boolean) => void;
    onSubmit: (isConstructuve: boolean, feedback: string, isAnonymous: boolean) => void;
    onGoBack: () => void;
    onAnimationCompleted: () => void;
    onTrackFeedbackShown: () => void;
}

const PulseSurveyActivityFollowUpQuestionStep = ({ questionScore, positiveQuestion, constructiveQuestion, feedback, isAnonymous, isAnswered, onChangeAnonymity, onSubmit, onGoBack, onAnimationCompleted, onTrackFeedbackShown }: Props) => {
    const isConstructive = questionScore <= 5;

    useEffect(() => {
        onTrackFeedbackShown();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <PulseSurveyFollowUpQuestion
            question={isConstructive ? constructiveQuestion : positiveQuestion}
            initialFeedback={feedback}
            initialIsAnonymous={isAnonymous}
            isAnswered={!!isAnswered}
            onChangeAnonymity={onChangeAnonymity}
            onChange={(f, ia) => onSubmit(isConstructive, f, ia)}
            onAnimationCompleted={onAnimationCompleted}
            onGoBack={onGoBack}
        />
    );
};

PulseSurveyActivityFollowUpQuestionStep.propTypes = {
    questionScore: PropTypes.number.isRequired,
    positiveQuestion: PropTypes.string.isRequired,
    constructiveQuestion: PropTypes.string.isRequired,
    feedback: PropTypes.string,
    isAnonymous: PropTypes.bool.isRequired,
    isAnswered: PropTypes.bool.isRequired,
    onChangeAnonymity: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onGoBack: PropTypes.func.isRequired,
    onAnimationCompleted: PropTypes.func.isRequired,
    onTrackFeedbackShown: PropTypes.func.isRequired
};

export default PulseSurveyActivityFollowUpQuestionStep;