import React, { useState } from "react";

import type { GuidString } from "@/types/utilityTypes";
import { AngleLeftIcon } from "@hopper-ui/icons";
import Textarea from "@igloo-ui/textarea";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";
import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import GhostButton from "@components/button/GhostButton";
import PrimaryButton from "@components/button/PrimaryButton";
import InlineLabeledFormField from "@components/form/InlineLabeledFormField";
import LabeledFormField from "@components/form/LabeledFormField";
import PeerSelect from "@components/peer-select/PeerSelect";
import TextArea from "@components/text-area/TextArea";
import VisibilityDisclaimer from "@components/visibility/VisibilityDisclaimer";

import type { ActivePrompt, UserIdentity } from "@api/RecognitionActivityApiClient";
import RecognitionCategoryImage from "@core/enums/RecognitionCategoryImage";
import RecognitionCategoryTheme from "@core/enums/RecognitionCategoryTheme";
import { trackButtonClicked } from "@core/tracking/track";
import TrackingPropertyName from "@core/tracking/TrackingPropertyName";
import * as ensure from "@core/utils/validation";

import RecognitionCardImage from "./recognition/RecognitionCardImage";
import RecognitionSender from "./recognition/RecognitionSender";

import "./recognition-activity-customize-message-step.scss";

const maxLength = 500;

interface Props {
    className?: string;
    correlationId: string;
    selectedPrompt: ActivePrompt;
    searchPeersAsync: (search: string) => Promise<UserIdentity[]>;
    onSubmit: (recipientId: GuidString, message: string | null | undefined) => void;
    onCancel: () => void;
    onAnimationCompleted: () => void;
}

const RecognitionActivityCustomizeMessageStep = ({ className, correlationId, selectedPrompt, searchPeersAsync, onSubmit, onCancel, onAnimationCompleted }: Props) => {
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);

    const { t } = useTranslation("activities");

    const { isNetworkAdmin, isCompanyManager, isTeamManager } = useAuthenticatedUser();

    const [internalRecipientId, setInternalRecipientId] = useState<GuidString | null>(null);
    const [internalMessage, setInternalMessage] = useState("");

    const [showValidationErrors, setShowValidationErrors] = useState(false);
    const validations = {
        recipientId: ensure.isNotNullOrEmpty(internalRecipientId, t("recognitionFrame.recipient-field-required-error"))
    };
    const isValid = validations.recipientId.isValid;

    const handleOnSubmit = () => {
        if (isValid) {
            setShowValidationErrors(false);
            onSubmit(internalRecipientId!, internalMessage.trim());
            onAnimationCompleted();
        } else {
            setShowValidationErrors(true);
        }
    };

    const handleOnBackToCardsClick = () => {
        trackButtonClicked(isNetworkAdmin, isCompanyManager, isTeamManager, "Recognition customize message", "Back to cards", { [TrackingPropertyName.CorrelationId]: correlationId });

        onCancel();
    };

    const handleOnCancelClick = () => {
        trackButtonClicked(isNetworkAdmin, isCompanyManager, isTeamManager, "Recognition customize message", "Cancel", { [TrackingPropertyName.CorrelationId]: correlationId });

        onCancel();
    };

    const messageFieldLabel = (
        <div className="recognition-activity-customize-message-step__form-label">
            <Trans ns="activities"
                i18nKey="recognitionFrame.message-field-label"
                components={[<span key={0} className="recognition-activity-customize-message-step__optional-label">REPLACED BY I18N</span>]}
            />
        </div>
    );

    const classes = classNames(
        "recognition-activity-customize-message-step",
        className
    );

    return (
        <div className={classes}>
            <GhostButton className="recognition-activity-customize-message-step__back-button"
                theme={isWorkleapBrandEnabled ? GhostButton.Theme.Dark : GhostButton.Theme.Light}
                iconLeading={<AngleLeftIcon size="md" />}
                onClick={handleOnBackToCardsClick}
            >
                {t("recognitionFrame.back-to-cards-button-label")}
            </GhostButton>
            <div className="recognition-activity-customize-message-step__card">
                <div className="recognition-activity-customize-message-step__prompt-section">
                    <RecognitionCardImage className="recognition-activity-customize-message-step__image" selectedPrompt={selectedPrompt} />
                </div>
                <div className="recognition-activity-customize-message-step__message-section">
                    <div className="recognition-activity-customize-message-step__form">
                        <InlineLabeledFormField className="recognition-activity-customize-message-step__recipient-field" label={<div className="recognition-activity-customize-message-step__form-inline-label">{t("recognitionFrame.recipient-field-label")}</div>} htmlFor="peer-select-input">
                            <PeerSelect
                                id="peer-select-input"
                                placeholder={t("recognitionFrame.recipient-field-search-placeholder")}
                                noResultsLabel={t("recognitionFrame.recipient-field-empty-state-label")}
                                searchPeersAsync={searchPeersAsync}
                                onChange={r => setInternalRecipientId(r)}
                                error={showValidationErrors && !validations.recipientId.isValid}
                            />
                        </InlineLabeledFormField>
                        {!isWorkleapBrandEnabled &&
                            <InlineLabeledFormField className="recognition-activity-customize-message-step__sender-field" label={<div className="recognition-activity-customize-message-step__form-inline-label">{t("recognitionFrame.sender-field-label")}</div>}>
                                <RecognitionSender />
                            </InlineLabeledFormField>
                        }
                        <LabeledFormField className="recognition-activity-customize-message-step__message-field" label={messageFieldLabel} htmlFor="message-input">
                            {isWorkleapBrandEnabled
                                ? <Textarea id="message-input"
                                    className="recognition-activity-customize-message-step__message-input"
                                    value={internalMessage}
                                    placeholder={t("recognitionFrame.message-field-placeholder")}
                                    onChange={e => setInternalMessage(e.target.value)}
                                    maxLength={maxLength}
                                />
                                : <TextArea id="message-input"
                                    className="recognition-activity-customize-message-step__message-input"
                                    value={internalMessage}
                                    onChange={e => setInternalMessage(e.target.value)}
                                    maxLength={maxLength}
                                />}
                        </LabeledFormField>
                        <VisibilityDisclaimer className="recognition-activity-customize-message-step__visibility-disclaimer">
                            {t("recognitionFrame.visibility-disclaimer-footer-label")}
                        </VisibilityDisclaimer>
                    </div>
                    <div className="recognition-activity-customize-message-step__call-to-actions">
                        <PrimaryButton onClick={handleOnSubmit}>
                            {t("recognitionFrame.send-button-label")}
                        </PrimaryButton>
                        <GhostButton onClick={handleOnCancelClick}>
                            {t("recognitionFrame.cancel-button-label")}
                        </GhostButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

RecognitionActivityCustomizeMessageStep.propTypes = {
    className: PropTypes.string,
    correlationId: PropTypes.string.isRequired,
    selectedPrompt: PropTypes.shape({
        categoryImage: PropTypes.oneOf(Object.values(RecognitionCategoryImage)),
        categoryName: PropTypes.string.isRequired,
        categoryTheme: PropTypes.oneOf(Object.values(RecognitionCategoryTheme)).isRequired,
        prompt: PropTypes.string.isRequired
    }).isRequired,
    searchPeersAsync: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onAnimationCompleted: PropTypes.func.isRequired
};

export default RecognitionActivityCustomizeMessageStep;