import React, { useContext, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";
import useQueryParams from "@hooks/useQueryParams";
import useRemoveQueryParams from "@hooks/useRemoveQueryParams";

import ActivitiesContext from "@contexts/activities/ActivitiesContext";
import TipsContext from "@contexts/tips/TipsContext";

import removeParamsFromQueryString from "@core/utils/removeParamsFromQueryString";

import ActivitiesPage from "./components/ActivitiesPage";

const queryParamsToRemove = ["questionId", "score", "value"];

const ContinuePulseSurveyPage = () => {
    const { questionId, score, value } = useQueryParams();
    const removeQueryParams = useRemoveQueryParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { initializeContinueSurveyAsync } = useContext(ActivitiesContext);
    const { initializeTipsContextAsync } = useContext(TipsContext);
    const isPulseSurveyCustomTextualQuestionsEnabled = useIsFeatureEnabled(feature => feature.pulseSurveyCustomTextualQuestions);

    useEffect(() => {
        const scoreNumber = Number(score);
        const valueNumber = Number(value);

        if (!questionId || (!scoreNumber && scoreNumber !== 0) || (!valueNumber && valueNumber !== 0)) {
            const cleanLocationSearch = removeParamsFromQueryString(location.search, queryParamsToRemove);

            navigate(`/survey?${cleanLocationSearch}`, { replace: true });

            return;
        }

        removeQueryParams(queryParamsToRemove);

        initializeContinueSurveyAsync(questionId, scoreNumber, valueNumber, isPulseSurveyCustomTextualQuestionsEnabled);
        initializeTipsContextAsync();
    }, [isPulseSurveyCustomTextualQuestionsEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ActivitiesPage />
    );
};

export default ContinuePulseSurveyPage;