import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";

import { AngleDownIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import PropTypes from "prop-types";

import useClickOutside from "@hooks/useClickOutside";
import useKeyboard, { Handled, NotHandled } from "@hooks/useKeyboard";

import Popover from "@components/popover/Popover";

import KeyCode from "@core/enums/KeyCodes";

import SelectOption from "./SelectOption";

import "./select.scss";

interface Props<TValue> {
    className?: string;
    value: TValue;
    renderValue: (value: TValue) => React.ReactNode;
    options: (TValue | TValue[])[];
    renderOption: (option: TValue, isKeyboardFocused: boolean, onClick: () => void, index: number) => React.ReactNode;
    onChange: (value: TValue) => void;
    tabIndex?: number;
}

// Danging comma is required here due to a clash between syntax for TS generics and JSX elements
// eslint-disable-next-line @typescript-eslint/comma-dangle
const Select = <TValue, >({ className, value, renderValue, options, renderOption, onChange, tabIndex }: Props<TValue>) => {
    const controlRef = useRef<HTMLButtonElement>(null);
    const popoverRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [keyboardFocus, setKeyboardFocus] = useState<number | null>(null);

    const allOptions = useMemo(() => options.flat(1) as TValue[], [options]);

    const getTotalOptions = () => {
        return allOptions.length;
    };

    const findSelectedIndex = () => {
        const result = allOptions.indexOf(value);

        return result === -1 ? null : result;
    };

    const totalOptions = useMemo(getTotalOptions, [allOptions]);
    const defaultKeyboardFocus = useMemo(findSelectedIndex, [allOptions, value]);

    useClickOutside([controlRef, popoverRef], () => {
        setIsOpen(false);
    });

    useEffect(() => {
        if (isOpen) {
            setKeyboardFocus(null);
        }
    }, [isOpen]);

    useKeyboard({
        [`${KeyCode.Enter}|${KeyCode.Space}|${KeyCode.Tab}`]: () => {
            if (!isOpen) {
                return NotHandled;
            }

            if (keyboardFocus === null) {
                setIsOpen(false);

                return Handled;
            }

            setIsOpen(false);
            onChange(allOptions[keyboardFocus]);

            return Handled;
        },
        [KeyCode.ArrowUp]: () => {
            if (!isOpen) {
                return NotHandled;
            }

            let currentValue: number | null = keyboardFocus;

            if (currentValue === null) {
                currentValue = defaultKeyboardFocus;
            }

            if (currentValue === null || currentValue === 0) {
                setKeyboardFocus(totalOptions - 1);
            } else {
                setKeyboardFocus(currentValue - 1);
            }

            return Handled;
        },
        [KeyCode.ArrowDown]: () => {
            if (!isOpen) {
                return NotHandled;
            }

            let currentValue = keyboardFocus;

            if (currentValue === null) {
                currentValue = defaultKeyboardFocus;
            }

            if (currentValue === null || currentValue === totalOptions - 1) {
                setKeyboardFocus(0);
            } else {
                setKeyboardFocus(currentValue + 1);
            }

            return Handled;
        },
        [KeyCode.Escape]: () => {
            if (!isOpen) {
                return NotHandled;
            }

            setIsOpen(false);

            return Handled;
        }
    }, controlRef);

    const handleOnOptionClick = (option: TValue) => {
        setIsOpen(false);
        onChange(option);
    };

    const renderOptions = () => {
        let iterator = 0;

        const render = (item: TValue) => {
            const currentIndex = iterator;
            iterator++;

            return renderOption(item, keyboardFocus === currentIndex, () => handleOnOptionClick(item), currentIndex);
        };

        return options.map((o, i) => {
            if (Array.isArray(o)) {
                // Grouped result
                const isLastGroup = i === options.length - 1;

                return (
                    // There is no identifying information about these options, so we must use the index as a key
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={i}>
                        {o.map(item => render(item))}
                        {!isLastGroup && <hr className="select__option-group-separator" />}
                    </Fragment>
                );
            }

            // Ungrouped result
            return render(o);
        });
    };

    const classes = classNames(
        "select",
        className, {
            "select--open": isOpen
        }
    );

    return (
        <div className={classes}>
            <button type="button" ref={controlRef} className="select__control" onClick={() => setIsOpen(o => !o)} tabIndex={tabIndex}>
                {renderValue(value)}
                <AngleDownIcon size="sm" className="select__control-arrow" />
            </button>
            {isOpen && (
                <Popover ref={popoverRef} className="select__popover">
                    {renderOptions()}
                </Popover>
            )}
        </div>
    );
};

Select.propTypes = {
    className: PropTypes.string,
    value: PropTypes.any,
    renderValue: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    renderOption: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    tabIndex: PropTypes.number
};

Select.Option = SelectOption;

export default Select;