import React, { useContext, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import useDelayedEffect from "@hooks/useDelayedEffect";

import ActivitiesContext from "@contexts/activities/ActivitiesContext";
import SignupContext from "@contexts/signup/SignupContext";
import { useUserContext } from "@contexts/user/UserContext";

import { trackButtonClicked } from "@core/tracking/track";
import TrackingPropertyName from "@core/tracking/TrackingPropertyName";
import sleep from "@core/utils/sleep";

import SignupCommunicationPreferencesSlide from "./components/SignupCommunicationPreferencesSlide";
import SignupPasswordSlide from "./components/SignupPasswordSlide";
import SignupProfileSlide from "./components/SignupProfileSlide";
import SignupPulseSurveyAnswersExplanationSlide from "./components/SignupPulseSurveyAnswersExplanationSlide";
import SignupPulseSurveyExplanationSlide from "./components/SignupPulseSurveyExplanationSlide";
import SignupPulseSurveyFeedbackExplanationSlide from "./components/SignupPulseSurveyFeedbackExplanationSlide";
import SignupSurveySettingsSlide from "./components/SignupSurveySettingsSlide";

import "./signup-flow.scss";

const SignupSlide = {
    Profile: "Profile",
    CommunicationPreferences: "CommunicationPreferences",
    Password: "Password",
    PulseSurveyExplanation: "PulseSurveyExplanation",
    PulseSurveyAnswersExplanation: "PulseSurveyAnswersExplanation",
    PulseSurveyFeedbackExplanation: "PulseSurveyFeedbackExplanation",
    SurveySettings: "SurveySettings"
};

const AnimationState = {
    Intro: "intro",
    Idle: "idle",
    Pending: "pending",
    SlideIn: "slide-in",
    SlideOut: "slide-out",
    SlideInReverse: "slide-in-reverse",
    SlideOutReverse: "slide-out-reverse"
};

const introDuration = 1000;
const slideInDuration = 400;
const slideOutDuration = 400;

const SignupFlow = ({ className, onNavigateToPulseSurvey }) => {
    const { context, setSurveyLanguage, saveProfileAsync, saveCommunicationPreferencesAsync, saveMemberOnboardingAsync, savePasswordAsync, onSignupActivityEnded, loginAsync } = useContext(SignupContext);
    const { markSignupAsCompleted, context: userContext } = useUserContext();
    const { initializeCompleteSurveyAsync } = useContext(ActivitiesContext);

    const [animationState, setAnimationState] = useState(AnimationState.Intro);

    useDelayedEffect(() => {
        setAnimationState(AnimationState.Idle);
    }, [], introDuration);

    const orchestrateSlides = () => {
        if (userContext.isMigratedToWorkleap) {
            return [
                SignupSlide.PulseSurveyExplanation,
                SignupSlide.PulseSurveyAnswersExplanation,
                SignupSlide.SurveySettings
            ];
        }

        return [
            SignupSlide.Profile,
            (context.isPasswordLoginDisabledForNetwork
                ? null
                : SignupSlide.Password),
            SignupSlide.CommunicationPreferences,
            SignupSlide.PulseSurveyExplanation,
            SignupSlide.PulseSurveyAnswersExplanation,
            SignupSlide.PulseSurveyFeedbackExplanation
        ].filter(x => x);
    };

    const [slides] = useState(orchestrateSlides());
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const goToNextSlide = () => {
        setCurrentSlideIndex(i => i + 1);
    };

    const animateToNextSlide = async() => {
        setAnimationState(AnimationState.SlideOut);
        await sleep(slideOutDuration);
        setAnimationState(AnimationState.Pending);
        goToNextSlide();
        setAnimationState(AnimationState.SlideIn);
        await sleep(slideInDuration);
        setAnimationState(AnimationState.Idle);
    };

    const handleOnNextSlideClick = async() => {
        if (animationState !== AnimationState.Idle) {
            return;
        }

        trackButtonClicked(userContext.isNetworkAdmin, userContext.isCompanyManager, userContext.isGroupManager, "Signup", "Go to next step", { [TrackingPropertyName.NextSlide]: slides[currentSlideIndex + 1] });
        animateToNextSlide();
    };

    const goToPreviousSlide = () => {
        setCurrentSlideIndex(i => i - 1);
    };

    const animateToPreviousSlide = async() => {
        setAnimationState(AnimationState.SlideOutReverse);
        await sleep(slideOutDuration);
        setAnimationState(AnimationState.Pending);
        goToPreviousSlide();
        setAnimationState(AnimationState.SlideInReverse);
        await sleep(slideInDuration);
        setAnimationState(AnimationState.Idle);
    };

    const handleOnPreviousSlideClick = async() => {
        if (animationState !== AnimationState.Idle) {
            return;
        }

        trackButtonClicked(userContext.isNetworkAdmin, userContext.isCompanyManager, userContext.isGroupManager, "Signup", "Go to previous step", { [TrackingPropertyName.PreviousSlide]: slides[currentSlideIndex - 1] });
        animateToPreviousSlide();
    };

    const handleOnSignupCompleted = async() => {
        await onSignupActivityEnded();
        markSignupAsCompleted();

        // Preload survey to avoid a 2nd loading screen in a row
        await initializeCompleteSurveyAsync();
        onNavigateToPulseSurvey();
    };

    const saveMemberOnboardingAndCompleteSignup = async(data) => {
        await saveMemberOnboardingAsync(data);
        handleOnSignupCompleted();
    };

    const savePasswordAndLoginAsync = async(internalPassword) => {
        await savePasswordAsync(internalPassword);
        await loginAsync(context.workEmail, internalPassword);
    };

    const renderCurrentSlide = () => {
        switch (slides[currentSlideIndex]) {
            case SignupSlide.Profile:
                return (
                    <SignupProfileSlide className="signup-flow__slide"
                        firstName={context.firstName}
                        lastName={context.lastName}
                        email={context.workEmail}
                        userName={userContext.userName}
                        communicationPreference={context.deliveryChannel}
                        surveyLanguage={context.surveyLanguage}
                        canEditDashboardLanguage={context.hasAccessToManagerDashboard}
                        canEditName={!context.isMemberProfileEditingDisabled}
                        dashboardLanguage={context.dashboardLanguage}
                        onSubmit={saveProfileAsync}
                        onSurveyLanguageChange={setSurveyLanguage}
                        slideIndex={slides.indexOf(SignupSlide.Profile)}
                        totalSlides={slides.length}
                        onNextSlideClick={handleOnNextSlideClick} />
                );
            case SignupSlide.CommunicationPreferences:
                return (
                    <SignupCommunicationPreferencesSlide className="signup-flow__slide"
                        deliveryChannel={context.deliveryChannel}
                        defaultDeliveryChannel={context.defaultDeliveryChannel}
                        workEmail={context.workEmail}
                        personalEmail={context.personalEmail}
                        mobilePhone={context.mobilePhone}
                        onSubmit={saveCommunicationPreferencesAsync}
                        slideIndex={slides.indexOf(SignupSlide.CommunicationPreferences)}
                        totalSlides={slides.length}
                        onPreviousSlideClick={handleOnPreviousSlideClick}
                        onNextSlideClick={handleOnNextSlideClick} />
                );
            case SignupSlide.Password:
                return (
                    <SignupPasswordSlide className="signup-flow__slide"
                        onSubmit={savePasswordAndLoginAsync}
                        slideIndex={slides.indexOf(SignupSlide.Password)}
                        totalSlides={slides.length}
                        onPreviousSlideClick={handleOnPreviousSlideClick}
                        onNextSlideClick={handleOnNextSlideClick}
                        canEdit={!context.isPasswordSet} />
                );
            case SignupSlide.SurveySettings:
                return (
                    <SignupSurveySettingsSlide className="signup-flow__slide"
                        surveyLanguage={context.surveyLanguage}
                        canEditDashboardLanguage={context.hasAccessToManagerDashboard}
                        dashboardLanguage={context.dashboardLanguage}
                        onSurveyLanguageChange={setSurveyLanguage}
                        deliveryChannel={context.deliveryChannel}
                        defaultDeliveryChannel={context.defaultDeliveryChannel}
                        workEmail={context.workEmail}
                        personalEmail={context.personalEmail}
                        mobilePhone={context.mobilePhone}
                        onSubmit={saveMemberOnboardingAndCompleteSignup}
                        slideIndex={slides.indexOf(SignupSlide.SurveySettings)}
                        totalSlides={slides.length}
                        onPreviousSlideClick={handleOnPreviousSlideClick} />
                );
            case SignupSlide.PulseSurveyExplanation:
                return (
                    <SignupPulseSurveyExplanationSlide className="signup-flow__slide"
                        slideIndex={slides.indexOf(SignupSlide.PulseSurveyExplanation)}
                        totalSlides={slides.length}
                        onPreviousSlideClick={handleOnPreviousSlideClick}
                        onNextSlideClick={handleOnNextSlideClick} />
                );
            case SignupSlide.PulseSurveyAnswersExplanation:
                return (
                    <SignupPulseSurveyAnswersExplanationSlide className="signup-flow__slide"
                        slideIndex={slides.indexOf(SignupSlide.PulseSurveyAnswersExplanation)}
                        totalSlides={slides.length}
                        onPreviousSlideClick={handleOnPreviousSlideClick}
                        onNextSlideClick={handleOnNextSlideClick} />
                );
            case SignupSlide.PulseSurveyFeedbackExplanation:
                return (
                    <SignupPulseSurveyFeedbackExplanationSlide className="signup-flow__slide"
                        onSubmit={handleOnSignupCompleted}
                        slideIndex={slides.indexOf(SignupSlide.PulseSurveyFeedbackExplanation)}
                        totalSlides={slides.length}
                        onPreviousSlideClick={handleOnPreviousSlideClick} />
                );
        }
    };

    const classes = classNames(
        "signup-flow",
        `signup-flow--${animationState}`,
        className
    );

    return (
        <div className={classes}>
            {renderCurrentSlide()}
        </div>
    );
};

SignupFlow.propTypes = {
    className: PropTypes.string,
    onNavigateToPulseSurvey: PropTypes.func.isRequired
};

export default SignupFlow;