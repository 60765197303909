import React from "react";

import classNames from "classnames";

import "./select-option.scss";

interface Props {
    className?: string;
    children?: React.ReactNode;
    onClick?: () => void;
    selected?: boolean;
    isKeyboardFocused?: boolean;
}

const SelectOption = React.forwardRef<HTMLButtonElement, Props>(({ className, children, onClick, selected, isKeyboardFocused = false }, ref) => {
    const classes = classNames(
        "select-option",
        className, {
            "select-option--selected": selected,
            "select-option--keyboard-focused": isKeyboardFocused
        }
    );

    return (
        <button type="button" ref={ref} className={classes} onClick={onClick}>
            {children}
        </button>
    );
});

export default SelectOption;