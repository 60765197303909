import React, { useContext, useEffect } from "react";

import { useParams } from "react-router-dom";

import ActivitiesContext from "@contexts/activities/ActivitiesContext";

import ActivitiesPage from "./components/ActivitiesPage";

const TestPulseSurveyQuestionPage = () => {
    const { questionId } = useParams();
    const { initializeTestPulseSurveyQuestionAsync } = useContext(ActivitiesContext);

    useEffect(() => {
        initializeTestPulseSurveyQuestionAsync(questionId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ActivitiesPage />
    );
};

export default TestPulseSurveyQuestionPage;