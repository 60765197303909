import upperFirst from "lodash/upperFirst";
import type { TFunction } from "react-i18next";

import type { DeibSurveyActivityResponse } from "@api/DeibSurveyActivityClient";

import ActivityType from "../../enums/ActivityType";
import DeibSurveyStepType from "../../enums/DeibSurveyStepType";
import buildDeibSurveyQuestion from "./buildDeibSurveyQuestion";

const buildDeibSurveyQuestions = (activity: DeibSurveyActivityResponse, t: TFunction<"activities">) => {
    const replaceTag = (text: string) => text.replaceAll(`[${activity.customizationWord.sourceWord}]`, activity.customizationWord.replacementWord);

    return activity.questionIds
        .map(questionId => buildDeibSurveyQuestion(questionId, t))
        .map(q => ({
            ...q,
            question: upperFirst(replaceTag(q.question))
        }));
};

const buildDeibSurveyActivity = (activity: DeibSurveyActivityResponse, t: TFunction<"activities">) => {
    const questions = buildDeibSurveyQuestions(activity, t);

    const questionSteps = questions
        .map(q => {
            const askQuestionStep = {
                ...q,
                stepType: DeibSurveyStepType.AskQuestion
            };

            return askQuestionStep;
        });

    return {
        ...activity,
        activityType: ActivityType.DeibSurvey as const,
        steps: [
            { stepType: DeibSurveyStepType.Intro },
            ...questionSteps
        ]
    } ;
};

export default buildDeibSurveyActivity;