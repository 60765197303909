import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import { ReactComponent as Icon } from "@svg/icons/icon-heart.svg";

import "./anonymity-banner.scss";

interface Props {
    className?: string;
    isAnonymous: boolean;
}

const AnonymityBanner = ({ className, isAnonymous }: Props) => {
    const { t } = useTranslation("activities");

    const classes = classNames(
        "anonymity-banner",
        className
    );

    return (
        <div className={classes}>
            <div className="anonymity-banner__content anonymity-banner__content--mobile">
                <Icon className="anonymity-banner__icon" aria-hidden="true" />
                <Trans ns="activities"
                    i18nKey={isAnonymous ? t("banners.general-anonymous-mobile") : t("banners.general-not-anonymous-mobile")}
                    components={[<a key={0} className="anonymity-banner__link anonymity-banner__link--collapsed" href={t("banners.help-center-anonymity-url")} target="_blank" rel="noreferrer" aria-label={t("banners.help-center-anonymity-link-aria-label")}>REPLACED BY I18N</a>]}
                />
            </div>
            <div className="anonymity-banner__content anonymity-banner__content--desktop">
                <Icon className="anonymity-banner__icon" aria-hidden="true" />
                <Trans ns="activities"
                    i18nKey={isAnonymous ? t("banners.general-anonymous") : t("banners.general-not-anonymous")}
                    components={[
                        <a key={0} className="anonymity-banner__link anonymity-banner__link--collapsed" href={t("banners.help-center-anonymity-url")} target="_blank" rel="noreferrer" aria-label={t("banners.help-center-anonymity-link-aria-label")}>REPLACED BY I18N</a>,
                        <a key={1} className="anonymity-banner__link" href={t("banners.privacy-policy-url")} target="_blank" rel="noreferrer" aria-label={t("banners.privacy-policy-link-aria-label")}>REPLACED BY I18N</a>,
                        <a key={2} className="anonymity-banner__link anonymity-banner__link--collapsed" href={t("banners.terms-url")} target="_blank" rel="noreferrer" aria-label={t("banners.terms-link-aria-label")}>REPLACED BY I18N</a>
                    ]}
                />
            </div>
        </div>
    );
};

AnonymityBanner.propTypes = {
    className: PropTypes.string,
    isAnonymous: PropTypes.bool.isRequired
};

export default AnonymityBanner;