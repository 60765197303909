import React, { useState, useEffect } from "react";

import { AngleLeftIcon } from "@hopper-ui/icons";
import { AngleRightIcon } from "@hopper-ui/icons";
import IconChevronLeft from "@igloo-ui/icons/dist/ChevronLeft";
import IconChevronRight from "@igloo-ui/icons/dist/ChevronRight";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import GhostButton from "@components/button/GhostButton";
import PrimaryButton from "@components/button/PrimaryButton";

import ActivityControlBarStepType from "@core/enums/ActivityControlBarStepType";
import ActivityControlBarTheme from "@core/enums/ActivityControlBarTheme";

import ControlBarTrackedQuestions from "./ControlBarTrackedQuestions";

import "./activities-control-bar.scss";

const ActivitiesControlBar = ({ snapshotRefreshKey, className, theme, activities, isHidden, showPreviousButton, onPreviousClick, showSkipButton, onSkipClick, showNextButton, onNextClick, disableNextButton }) => {
    const { t } = useTranslation("activities");
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);

    // Do not display the component if it is hidden for the first step displayed.
    // Otherwise, the hidden animation will play even if the user should not see the component.
    const [isDisplayed, setIsDisplayed] = useState(!isHidden);
    const [controlBarSnapshot, setControlBarSnapshot] = useState({
        showPreviousButton,
        showSkipButton,
        showNextButton,
        theme
    });

    useEffect(() => {
        // Keep the control bar state until the transition has completed, then the snapshot key will be updated
        setControlBarSnapshot({
            showPreviousButton,
            showSkipButton,
            showNextButton,
            theme
        });
    }, [snapshotRefreshKey, showSkipButton]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isHidden && !isDisplayed) {
            setIsDisplayed(true);
        }
    }, [isHidden, isDisplayed]);

    const handleOnClickSkip = () => {
        setControlBarSnapshot({ ...controlBarSnapshot, disableSkipButton: true });
        onSkipClick();
    };

    const renderFlowStep = (step, index) => {
        if (!step.isInProgress) {
            return null;
        }

        switch (step.type) {
            case ActivityControlBarStepType.None:
                return null;

            case ActivityControlBarStepType.TrackedQuestionsStep:
                return (
                    <ControlBarTrackedQuestions {...step}
                        key={index}
                        className="activities-control-bar__flow-step" />
                );

            default:
                throw new Error(`Step Type (${step.type}) not supported`);
        }
    };

    if (!isDisplayed) {
        return null;
    }

    const classes = classNames(
        "activities-control-bar",
        `activities-control-bar--${controlBarSnapshot.theme}`,
        className, {
            "activities-control-bar--hidden": isHidden
        }
    );

    const skipButton = theme === ActivityControlBarTheme.Floating
        ? <GhostButton
            className="activities-control-bar__skip-button"
            iconTrailing={isWorkleapBrandEnabled ? <AngleRightIcon size="lg" /> : <IconChevronRight size="medium" />}
            theme={isWorkleapBrandEnabled ? GhostButton.Theme.Dark : GhostButton.Theme.Light}
            onClick={handleOnClickSkip}
            disabled={controlBarSnapshot.disableSkipButton}
            showOnlyIconOnMobile={!isWorkleapBrandEnabled}>
            {t("pulseSurveyFrame.progress-bar-skip")}
        </GhostButton>
        : <PrimaryButton
            iconTrailing={isWorkleapBrandEnabled ? <AngleRightIcon size="md" /> : <IconChevronRight size="medium" />}
            onClick={handleOnClickSkip}
            disabled={controlBarSnapshot.disableSkipButton}
            showOnlyIconOnMobile>
            {t("pulseSurveyFrame.progress-bar-skip")}
        </PrimaryButton>;

    return (
        <div className={classes}>
            <div className="activities-control-bar__left-column">
                {controlBarSnapshot.showPreviousButton && (
                    <PrimaryButton
                        iconLeading={isWorkleapBrandEnabled ? <AngleLeftIcon size="md"/> : <IconChevronLeft size="medium" />}
                        onClick={onPreviousClick}
                        showOnlyIconOnMobile>
                        {t("pulseSurveyFrame.progress-bar-previous")}
                    </PrimaryButton>
                )}
            </div>
            <div className="activities-control-bar__middle-column">
                {!isHidden && <div className="activities-control-bar__flow-steps">
                    {activities.map(renderFlowStep)}
                </div>}
            </div>
            <div className="activities-control-bar__right-column">
                {controlBarSnapshot.showSkipButton && skipButton}
                {controlBarSnapshot.showNextButton && (
                    <PrimaryButton
                        iconTrailing={isWorkleapBrandEnabled ? <AngleRightIcon size="md" /> : <IconChevronRight size="medium" />}
                        onClick={onNextClick}
                        disabled={disableNextButton}
                        showOnlyIconOnMobile>
                        {t("pulseSurveyFrame.progress-bar-next")}
                    </PrimaryButton>
                )}
            </div>
        </div>
    );
};

ActivitiesControlBar.propTypes = {
    className: PropTypes.string,
    snapshotRefreshKey: PropTypes.number.isRequired,
    theme: PropTypes.oneOf(Object.values(ActivityControlBarTheme)).isRequired,
    activities: PropTypes.array.isRequired,
    isHidden: PropTypes.bool.isRequired,
    showPreviousButton: PropTypes.bool.isRequired,
    onPreviousClick: PropTypes.func.isRequired,
    showSkipButton: PropTypes.bool.isRequired,
    onSkipClick: PropTypes.func.isRequired,
    showNextButton: PropTypes.bool.isRequired,
    onNextClick: PropTypes.func.isRequired,
    disableNextButton: PropTypes.bool.isRequired
};

export default ActivitiesControlBar;