import React, { useEffect } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { useLoadingContext } from "@contexts/loading/LoadingContext";

import "./officevibe-loading-wave.scss";

const tickDuration = 10;
const loopTicks = 1100 / tickDuration;
const animationTicks = loopTicks / 2;
const pauseTicks = 300 / tickDuration;
const activeTicks = animationTicks - pauseTicks;
const delayTicks = 200 / tickDuration;

interface Props {
    className?: string;
}

const OfficevibeLoadingWave = ({ className }: Props) => {
    const { timer, onTick } = useLoadingContext();

    useEffect(() => {
        const t = setInterval(onTick, tickDuration);

        return () => {
            clearInterval(t);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getTimer1 = () => {
        let ticks = timer;

        ticks -= delayTicks;
        if (ticks < 0) {
            return null;
        }

        const i = ticks % loopTicks;

        if (i > animationTicks) {
            return 0;
        }

        const p = i >= activeTicks
            ? 1
            : i / activeTicks;

        return 1 - p;
    };

    const getTimer2 = () => {
        let ticks = timer;

        ticks -= delayTicks;
        if (ticks < 0) {
            return null;
        }

        let i = ticks % loopTicks;

        if (i < animationTicks) {
            return 0;
        }

        i -= animationTicks;

        return i >= activeTicks
            ? 1
            : i / activeTicks;
    };

    const timer1 = getTimer1();
    const timer2 = getTimer2();

    const classes = classNames(
        "officevibe-loading-wave",
        className
    );

    const line1Styles = {
        strokeDasharray: timer1 === null ? undefined : `${131 * timer1} 131`
    };

    const line2Styles = {
        strokeDasharray: timer2 === null ? undefined : `${131 * timer2} 131`
    };

    return (
        <svg className={classes} viewBox="0 0 120 48">
            <path className="officevibe-loading-wave__line-1" style={line1Styles} fill="none" strokeWidth="24" strokeMiterlimit="10" d="M107.4 11.8s.6 24.2-26 24.3c-20.6 0-22.3-23.9-43-23.9-26.1 0-25.9 24-25.9 24" />
            <path className="officevibe-loading-wave__line-2" style={line2Styles} fill="none" strokeWidth="24" strokeMiterlimit="10" d="M12.6 36.2s-.2-24 25.9-24c20.7 0 22.4 23.9 43 23.9 26.6-.1 26-24.3 26-24.3" />
        </svg>
    );
};

OfficevibeLoadingWave.propTypes = {
    className: PropTypes.string
};

export default OfficevibeLoadingWave;