import React from "react";

import classNames from "classnames";

import "./peer-select-value.scss";

interface Props {
    className?: string;
    fullName: string;
    imageUrl: string;
}

const PeerSelectValue = ({ className, fullName, imageUrl }: Props) => {
    const classes = classNames(
        "peer-select-value",
        className
    );

    return (
        <div className={classes}>
            <img className="peer-select-value__thumbnail" src={imageUrl} alt="" />
            <div className="peer-select-value__fullname">
                {fullName}
            </div>
        </div>
    );
};

export default PeerSelectValue;