import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./signup-slide-explanation.scss";

const SignupSlideExplanation = ({ className, title, description, children }) => {
    const classes = classNames(
        "signup-slide-explanation",
        className
    );

    return (
        <div className={classes}>
            <div className="signup-slide-explanation__title">
                {title}
            </div>
            <div className="signup-slide-explanation__description">
                {description}
            </div>
            {children && (
                <div className="signup-slide-explanation__footer">
                    {children}
                </div>
            )}
        </div>
    );
};

SignupSlideExplanation.propTypes = {
    className: PropTypes.string,
    title: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
    children: PropTypes.node
};

export default SignupSlideExplanation;