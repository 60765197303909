import type { TFunction } from "react-i18next";

import ActivityType from "@core/enums/ActivityType";
import OnboardingSurveyStepType from "@core/enums/OnboardingSurveyStepType";

import buildOnboardingSurveyQuestion from "./buildOnboardingSurveyQuestion";
import type { OnboardingQuestionID } from "./types";

const buildTestOnboardingSurveyQuestionActivity = (
    questionId: OnboardingQuestionID,
    t: TFunction<"activities">
) => {
    const question = {
        ...buildOnboardingSurveyQuestion(questionId, t),
        askFollowUpQuestion: true
    };

    const { followUpQuestion, ...stepData } = question;

    const askQuestionStep = {
        ...stepData,
        stepType: OnboardingSurveyStepType.AskQuestion
    };

    const askFollowUpQuestionStep = {
        questionId: askQuestionStep.questionId,
        followUpQuestion,
        stepType: OnboardingSurveyStepType.AskFollowUpQuestion
    };

    return {
        activityType: ActivityType.TestOnboardingSurvey as const,
        correlationId: "test-correlation-id",
        questionIds: [askQuestionStep.questionId],
        questionIdsWithFollowUp: [askQuestionStep.questionId],
        steps: [askQuestionStep, askFollowUpQuestionStep]
    };
};

export default buildTestOnboardingSurveyQuestionActivity;
