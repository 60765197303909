import React, { useEffect } from "react";

import type { OnboardingSurveyActivityType, OrchestratedOnboardingSurveyFollowUpQuestionStep, OrchestratedOnboardingSurveyQuestionStep, OrchestratedOnboardingSurveyStep } from "@/types/activities/onboarding-survey";
import PropTypes from "prop-types";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";

import ContinueOnboardingSurveyIntro from "@components/onboarding-survey-question/ContinueOnboardingSurveyIntro";

import ActivityType from "@core/enums/ActivityType";
import OnboardingSurveyStepType from "@core/enums/OnboardingSurveyStepType";
import { trackActivityCompleted, trackActivityStarted, trackFeedbackGiven, trackFeedbackShown, trackLinkClicked, trackQuestionAnswered } from "@core/tracking/track";
import TrackingInteractionType from "@core/tracking/TrackingInteractionType";

import OnboardingSurveyActivityFollowUpQuestionStep from "./OnboardingSurveyActivityFollowUpQuestionStep";
import OnboardingSurveyActivityQuestionStep from "./OnboardingSurveyActivityQuestionStep";

interface Props {
    className?: string;
    activityType: OnboardingSurveyActivityType;
    isFirstActivity: boolean;
    isLastActivity: boolean;
    isIntroAnimated: boolean;
    correlationId: string;
    steps: OrchestratedOnboardingSurveyStep[];
    currentStepIndex: number;
    onSliderQuestionAnswered: () => void;
    onLikertQuestionAnswered: () => void;
    onMultipleChoiceQuestionAnswered: () => void;
    onFollowUpFeedbackLeft: (correlationId: string, questionId: string, feedback: string) => void;
    onGoToPreviousStep: () => void;
    onGoToNextStep: () => void;
    onGoToNextActivity: () => void;
    onGoToOutro: () => void;
    onActivityEnded: (correlationId: string) => void;
    onIntroAnimationCompleted: () => void;
}

const OnboardingSurveyActivity = ({
    className,
    activityType,
    isFirstActivity,
    isLastActivity,
    correlationId,
    steps,
    currentStepIndex,
    onSliderQuestionAnswered,
    onLikertQuestionAnswered,
    onMultipleChoiceQuestionAnswered,
    onFollowUpFeedbackLeft,
    onGoToPreviousStep,
    onGoToNextStep,
    onGoToNextActivity,
    onGoToOutro,
    onActivityEnded,
    isIntroAnimated,
    onIntroAnimationCompleted
}: Props) => {
    const { isNetworkAdmin, isCompanyManager, isTeamManager } = useAuthenticatedUser();
    const currentStep = steps[currentStepIndex];

    useEffect(() => {
        trackActivityStarted(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, isFirstActivity);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isQuestionStep = (step: OrchestratedOnboardingSurveyStep): step is OrchestratedOnboardingSurveyQuestionStep => "questionId" in step;

    const handleOnQuestionAnswered = (callback: (correlationId: string, questionId: string, answer: number, score: number) => void) => (answer: number, score: number) => {
        if (!isQuestionStep(currentStep)) {
            return;
        }

        trackQuestionAnswered(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, TrackingInteractionType.Answered, currentStep.questionId);

        callback(correlationId, currentStep.questionId, answer, score);
    };

    const handleOnFollowUpQuestionAnswered = (feedback: string) => {
        if (!isQuestionStep(currentStep)) {
            return;
        }

        trackFeedbackGiven(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, TrackingInteractionType.Answered, false, currentStep.questionId);

        onFollowUpFeedbackLeft(correlationId, currentStep.questionId, feedback);
    };

    const handleOnTrackFeedbackShown = () => {
        if (!isQuestionStep(currentStep)) {
            return;
        }

        trackFeedbackShown(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, currentStep.questionId);
    };

    const handleOnGoBack = () => {
        trackLinkClicked(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, "Edit Answer");

        onGoToPreviousStep();
    };

    const handleOnAnimationCompleted = () => {
        if (currentStep.isLastStep) {
            trackActivityCompleted(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, isFirstActivity);
            onActivityEnded(correlationId);

            if (isLastActivity) {
                onGoToOutro();
            } else {
                onGoToNextActivity();
            }
        } else {
            onGoToNextStep();
        }
    };

    const renderIntroStep = () => {
        const handleOnDisplayTimeoutEnded = () => {
            onIntroAnimationCompleted();
            handleOnAnimationCompleted();
        };

        return (
            <ContinueOnboardingSurveyIntro
                isIntroAnimated={isIntroAnimated}
                onDisplayTimeoutEnd={handleOnDisplayTimeoutEnded}
            />
        );
    };

    const renderQuestionStep = (step: OrchestratedOnboardingSurveyQuestionStep) =>
        <OnboardingSurveyActivityQuestionStep
            {...step}
            onSliderQuestionAnswered={handleOnQuestionAnswered(onSliderQuestionAnswered)}
            onLikertQuestionAnswered={handleOnQuestionAnswered(onLikertQuestionAnswered)}
            onMultipleChoiceQuestionAnswered={handleOnQuestionAnswered(onMultipleChoiceQuestionAnswered)}
            isIntroAnimated={isIntroAnimated}
            onIntroAnimationCompleted={onIntroAnimationCompleted}
            onAnimationCompleted={handleOnAnimationCompleted}
        />;

    const renderFollowUpQuestionStep = (step: OrchestratedOnboardingSurveyFollowUpQuestionStep) =>
        <OnboardingSurveyActivityFollowUpQuestionStep
            {...step}
            followUpQuestion={step.followUpQuestion}
            onSubmit={handleOnFollowUpQuestionAnswered}
            onAnimationCompleted={handleOnAnimationCompleted}
            onGoBack={handleOnGoBack}
            onTrackFeedbackShown={handleOnTrackFeedbackShown}
        />;

    const renderCurrentStep = () => {
        const { stepType } = currentStep;

        switch (stepType) {
            // TODO: rework the step types so that TypeScript can make sense of the discriminator [EP-1904]
            case OnboardingSurveyStepType.Intro:
                return renderIntroStep();
            case OnboardingSurveyStepType.AskQuestion:
                return renderQuestionStep(currentStep as OrchestratedOnboardingSurveyQuestionStep);
            case OnboardingSurveyStepType.AskFollowUpQuestion:
                return renderFollowUpQuestionStep(currentStep as OrchestratedOnboardingSurveyFollowUpQuestionStep);
            default:
                throw new Error(`Onboarding Survey Step Type (${stepType}) not supported`);
        }
    };

    return (
        <div className={className}>
            {renderCurrentStep()}
        </div>
    );
};

OnboardingSurveyActivity.propTypes = {
    className: PropTypes.string,
    activityType: PropTypes.oneOf(Object.values(ActivityType)).isRequired,
    isFirstActivity: PropTypes.bool.isRequired,
    isLastActivity: PropTypes.bool.isRequired,
    isIntroAnimated: PropTypes.bool.isRequired,
    correlationId: PropTypes.string,
    steps: PropTypes.array.isRequired,
    currentStepIndex: PropTypes.number.isRequired,
    onSliderQuestionAnswered: PropTypes.func.isRequired,
    onLikertQuestionAnswered: PropTypes.func.isRequired,
    onMultipleChoiceQuestionAnswered: PropTypes.func.isRequired,
    onFollowUpFeedbackLeft: PropTypes.func.isRequired,
    onGoToPreviousStep: PropTypes.func.isRequired,
    onGoToNextStep: PropTypes.func.isRequired,
    onGoToNextActivity: PropTypes.func.isRequired,
    onGoToOutro: PropTypes.func.isRequired,
    onActivityEnded: PropTypes.func.isRequired,
    onIntroAnimationCompleted: PropTypes.func.isRequired
};

export default OnboardingSurveyActivity;