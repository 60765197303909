import React, { useContext } from "react";

import PropTypes from "prop-types";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import ActivitiesContext from "@contexts/activities/ActivitiesContext";
import { useUserContext } from "@contexts/user/UserContext";

import ActivitiesLayout from "@layouts/activities/ActivitiesLayout";
import LoadingPage from "@pages/loading/LoadingPage";

import ActivitiesBackground from "./ActivitiesBackground";
import ActivitiesFlow from "./ActivitiesFlow";

const ActivitiesPage = ({ className }) => {
    const {
        isLoading,
        getCurrentActivity, getControlBarState, getBannerState, getBackgroundState, getCurrentTip, outro,
        onPulseSurveyQuestionAnswered, onPulseSurveyQuestionSkipped, onPulseSurveyFeedbackAnonymityChanged, onPulseSurveyFollowUpAnswerLeft, onPulseSurveyCustomTextQuestionAnswered, onPulseSurveyActivityEnded,
        onCustomPollTextQuestionAnswered, onCustomPollOpinionScaleQuestionAnswered, onCustomPollMultipleChoiceQuestionAnswered, onCustomPollMultipleChoiceQuestionWithMultiSelectionAnswered,
        onCustomPollLikertScaleQuestionAnswered, onCustomPollQuestionSkipped, onCustomPollFeedbackAnonymityChanged, onCustomPollFollowUpAnswerLeft, onCustomPollActivityEnded,
        onSmartQuestionFeedbackAnonymityChanged, onSmartQuestionSkipped, onSmartQuestionFeedbackLeft, onSmartQuestionActivityEnded,
        onRecognitionPromptSelected, onRecognitionSkipped, searchPeersAsync, onRecognitionLeft, onRecognitionActivityEnded,
        onOnboardingSurveyQuestionAnswered, onOnboardingSurveyQuestionSkipped, onOnboardingSurveyFeedbackAnonymityChanged, onOnboardingSurveyFollowUpAnswerLeft, onOnboardingSurveyActivityEnded,
        onDeibSurveyQuestionAnswered, onDeibSurveyQuestionSkipped, onDeibSurveyFeedbackAnonymityChanged, onDeibSurveyActivityEnded,
        onGoToPreviousStep, onGoToNextStep,
        onGoToNextActivity,
        onGoToOutro
    } = useContext(ActivitiesContext);

    const {context: userContext} = useUserContext();

    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);
    const isNotificationUrlWithTokenEnabled = useIsFeatureEnabled(x => x.notificationUrlWithToken);

    if (isLoading) {
        return <LoadingPage />;
    }

    const currentActivity = getCurrentActivity();
    const controlBarState = getControlBarState();
    const bannerState = getBannerState();
    const backgroundState = getBackgroundState(isWorkleapBrandEnabled);
    const currentTip = getCurrentTip();

    const callbacks = {
        onPulseSurveyQuestionAnswered, onPulseSurveyQuestionSkipped, onPulseSurveyFeedbackAnonymityChanged, onPulseSurveyFollowUpAnswerLeft, onPulseSurveyCustomTextQuestionAnswered, onPulseSurveyActivityEnded,
        onCustomPollTextQuestionAnswered, onCustomPollOpinionScaleQuestionAnswered, onCustomPollMultipleChoiceQuestionAnswered, onCustomPollMultipleChoiceQuestionWithMultiSelectionAnswered,
        onCustomPollLikertScaleQuestionAnswered, onCustomPollQuestionSkipped, onCustomPollFeedbackAnonymityChanged, onCustomPollFollowUpAnswerLeft, onCustomPollActivityEnded,
        onSmartQuestionFeedbackAnonymityChanged, onSmartQuestionSkipped, onSmartQuestionFeedbackLeft, onSmartQuestionActivityEnded,
        onRecognitionPromptSelected, searchPeersAsync, onRecognitionLeft, onRecognitionSkipped, onRecognitionActivityEnded,
        onOnboardingSurveyQuestionAnswered, onOnboardingSurveyQuestionSkipped, onOnboardingSurveyFeedbackAnonymityChanged, onOnboardingSurveyFollowUpAnswerLeft, onOnboardingSurveyActivityEnded,
        onDeibSurveyQuestionAnswered, onDeibSurveyQuestionSkipped, onDeibSurveyFeedbackAnonymityChanged, onDeibSurveyActivityEnded,
        onGoToPreviousStep, onGoToNextStep, onGoToNextActivity,
        onGoToOutro
    };

    const handleOnRedirectToDashboard = () => {
        window.location.href = isNotificationUrlWithTokenEnabled ? `${window.env.APP_DASHBOARD_URL}?token=${userContext.organisationId}` : window.env.APP_DASHBOARD_URL;
    };

    return (
        <ActivitiesLayout className={className}>
            <ActivitiesBackground backgroundState={backgroundState} />
            <ActivitiesFlow
                {...callbacks}
                activity={currentActivity}
                controlBarState={controlBarState}
                bannerState={bannerState}
                backgroundState={backgroundState}
                currentTip={currentTip}
                outro={outro}
                onRedirectToDashboard={handleOnRedirectToDashboard} />
        </ActivitiesLayout>
    );
};

ActivitiesPage.propTypes = {
    className: PropTypes.string
};

export default ActivitiesPage;