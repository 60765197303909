import React, { useContext, useEffect } from "react";

import { useParams } from "react-router-dom";

import ActivitiesContext from "@contexts/activities/ActivitiesContext";

import ActivitiesPage from "./components/ActivitiesPage";

const TestSmartQuestionPage = () => {
    const { metricId, subMetricId } = useParams();
    const { initializeTestSmartQuestionAsync } = useContext(ActivitiesContext);

    useEffect(() => {
        initializeTestSmartQuestionAsync(metricId, subMetricId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ActivitiesPage />
    );
};

export default TestSmartQuestionPage;