import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import EmptyLayout from "@layouts/empty/EmptyLayout";

import "./activities-layout.scss";

interface Props {
    className?: string;
    children: React.ReactNode;
}

const ActivitiesLayout = ({ className, children }: Props) => {
    const classes = classNames("activities-layout", className);

    return (
        <EmptyLayout className={classes}>
            {children}
        </EmptyLayout>
    );
};

ActivitiesLayout.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired
};

export default ActivitiesLayout;