import QuestionDisplayType from "./QuestionDisplayType";

type DeibSurveyQuestionDisplayType = QuestionDisplayType.MultipleChoice | QuestionDisplayType.TwoWaySlider | QuestionDisplayType.LikertScale;

const DeibSurveyQuestionDisplayType = {
    [QuestionDisplayType.MultipleChoice]: QuestionDisplayType.MultipleChoice,
    [QuestionDisplayType.TwoWaySlider]: QuestionDisplayType.TwoWaySlider,
    [QuestionDisplayType.LikertScale]: QuestionDisplayType.LikertScale
} as const;

export default DeibSurveyQuestionDisplayType;