import React, { useEffect } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import useDelayedEffect from "@hooks/useDelayedEffect";
import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import EmptyLayout from "@layouts/empty/EmptyLayout";

import { ReactComponent as Illustration } from "@svg/illustrations/illustration-survey-continues.svg";
import { ReactComponent as IllustrationLegacy } from "@svg/illustrations/legacy/illustration-survey-continues.svg";

import "./recognition-intro-step.scss";

interface Props {
    className?: string;
    onAnimationCompleted: () => void;
}

const RecognitionIntroStep = ({ className, onAnimationCompleted }: Props) => {
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);
    const { t } = useTranslation("activities");

    useDelayedEffect(() => {
        onAnimationCompleted();
    }, [], 4000);

    useEffect(() => {
        document.body.classList.add("with-recognition-intro");

        return () => {
            document.body.classList.remove("with-recognition-intro");
        };
    }, []);

    const classes = classNames(
        "recognition-intro-step",
        className
    );

    const modalRoot = document.getElementById("modal-root");
    if (!modalRoot) {
        throw new Error("'#modal-root' element not found.");
    }

    return (
        <div className={classes}>
            {createPortal(
                <EmptyLayout className="recognition-intro-step__overlay" withFixedHeight>
                    <div className="recognition-intro-step__survey-sent-slide">
                        {isWorkleapBrandEnabled
                            ? <Illustration className="recognition-intro-step__survey-sent-illustration" />
                            : <IllustrationLegacy className="recognition-intro-step__survey-sent-illustration" />}
                        <div className="recognition-intro-step__survey-sent-title">
                            {t("recognitionFrame.survey-sent-title")}
                        </div>
                    </div>
                    <div className="recognition-intro-step__make-someones-day-slide">
                        {isWorkleapBrandEnabled && <div className="recognition-intro-step__survey-sent-placeholder"></div>}
                        <div className="recognition-intro-step__make-someones-day-title">
                            {t("recognitionFrame.make-someones-day-title")}
                        </div>
                    </div>
                </EmptyLayout>,
                modalRoot
            )}
        </div>
    );
};

RecognitionIntroStep.propTypes = {
    className: PropTypes.string,
    onAnimationCompleted: PropTypes.func.isRequired
};

export default RecognitionIntroStep;