import React from "react";

import { DismissIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import PropTypes from "prop-types";

import DismissableTipType from "./DismissableTipType";

import { ReactComponent as Tip } from "@svg/tip.svg";

import "./dismissable-tip.scss";

interface Props {
    className?: string;
    title: string;
    subtitle: string;
    type: DismissableTipType;
    onClick: () => void;
    onDismiss: () => void;
}

const DismissableTip = ({ className, title, subtitle, type, onClick, onDismiss }: Props) => {
    const handleOnDismiss: React.MouseEventHandler<HTMLButtonElement> = e => {
        e.stopPropagation();
        e.preventDefault();
        onDismiss();
    };

    const classes = classNames(
        "dismissable-tip",
        className
    );

    const iconClasses = classNames(
        "dismissable-tip__icon",
        `dismissable-tip__icon--${type}`,
        className
    );

    return (
        <button type="button" className={classes} onClick={onClick}>
            <div className="dismissable-tip__container">
                <div className={iconClasses}>
                    <Tip />
                </div>
                <div className="dismissable-tip__content">
                    <div className="dismissable-tip__title">
                        {title}
                    </div>
                    <div className="dismissable-tip__subtitle">
                        {subtitle}
                    </div>
                </div>
            </div>
            <button type="button" className="dismissable-tip__dismiss-button" onClick={handleOnDismiss}>
                <DismissIcon size="md" />
            </button>
        </button>
    );
};

DismissableTip.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(DismissableTipType)).isRequired,
    onClick: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired
};

DismissableTip.Type = DismissableTipType;

export default DismissableTip;