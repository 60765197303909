import React, { useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";

import InteractionBox from "@components/interaction-box/InteractionBox";

import CustomPollQuestionAskedBy from "./CustomPollQuestionAskedBy";

import "./custom-poll-text-question.scss";

interface Props {
    className?: string;
    question: string;
    isSentByCompany: boolean;
    sentByImageUrl?: string;
    isAnswered?: boolean;
    initialFeedback?: string | null;
    initialIsAnonymous?: boolean;
    onChangeAnonymity: (isAnonymous: boolean) => void;
    onSubmit: (feedback: string, isAnonymous: boolean) => void;
    onAnimationCompleted: () => void;
}

const CustomPollTextQuestion = ({
    className,
    question,
    isSentByCompany,
    sentByImageUrl,
    isAnswered,
    initialFeedback,
    initialIsAnonymous,
    onChangeAnonymity,
    onSubmit,
    onAnimationCompleted
}: Props) => {
    const { t } = useTranslation("activities");
    const { imageUrl, fullName } = useAuthenticatedUser();

    const [isAnsweredSnapshot] = useState(isAnswered);

    const [confirmed, setConfirmed] = useState(false);

    const handleOnSubmit = async(feedback: string, isAnonymous: boolean) => {
        if (confirmed) {
            return;
        }

        setConfirmed(true);
        onSubmit(feedback, isAnonymous);
        onAnimationCompleted();
    };

    const handleOnChangeAnonymity = (newIsAnonymous: boolean) => {
        if (confirmed) {
            return;
        }

        onChangeAnonymity(newIsAnonymous);
    };

    const classes = classNames("custom-poll-text-question", className, {
        "custom-poll-text-question--confirmed": confirmed
    });

    return (
        <div className={classes}>
            <CustomPollQuestionAskedBy
                className="custom-poll-text-question__header"
                question={question}
                isSentByCompany={isSentByCompany}
                sentByImageUrl={sentByImageUrl}
            />
            <InteractionBox
                initialFeedback={initialFeedback}
                initialIsAnonymous={initialIsAnonymous}
                authorImageUrl={imageUrl}
                authorFullName={fullName}
                placeholder={t("customPollFrame.feedback-input-placeholder")}
                submitLabel={
                    isAnsweredSnapshot
                        ? t("customPollFrame.update-feedback-button-label")
                        : t("customPollFrame.send-feedback-button-label")
                }
                onSubmit={handleOnSubmit}
                onChangeIsAnonymous={handleOnChangeAnonymity}
                disabled={confirmed}
                allowEmptyFeedback={isAnsweredSnapshot}
            />
        </div>
    );
};

CustomPollTextQuestion.propTypes = {
    className: PropTypes.string,
    question: PropTypes.string.isRequired,
    isSentByCompany: PropTypes.bool.isRequired,
    sentByImageUrl: PropTypes.string,
    isAnswered: PropTypes.bool.isRequired,
    initialFeedback: PropTypes.string,
    initialIsAnonymous: PropTypes.bool,
    onChangeAnonymity: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onAnimationCompleted: PropTypes.func.isRequired
};

export default CustomPollTextQuestion;
