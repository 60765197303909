import type { BuiltSmartQuestionActivity } from "@/types/activities/smart-question";

export default (activity: BuiltSmartQuestionActivity, isFirstActivity: boolean, isLastActivity: boolean) => ({
    isFirstActivity,
    isLastActivity,
    feedback: null,
    isAnonymous: true,
    isAnswered: false,
    currentStepIndex: 0,
    steps: [],
    ...activity
});