import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import PrimaryButtonSize from "./PrimaryButtonSize";
import PrimaryButtonTheme from "./PrimaryButtonTheme";

import "./primary-icon-button.scss";

interface IconComponentProps {
    className?: string;
}

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    size?: PrimaryButtonSize;
    theme?: PrimaryButtonTheme;
    disabled?: boolean;
    loading?: boolean;
    icon: React.DetailedReactHTMLElement<IconComponentProps, HTMLElement>;
}

const PrimaryIconButton = ({
    className,
    size = PrimaryButtonSize.Regular,
    theme = PrimaryButtonTheme.Dandelion,
    icon,
    disabled = false,
    loading = false,
    onClick,
    ...props
}: Props) => {
    const handleOnClick: React.MouseEventHandler<HTMLButtonElement> = e => {
        if (onClick && !loading && !disabled) {
            onClick(e);
        }
    };

    const classes = classNames(
        "primary-icon-button",
        `primary-icon-button--${size}`,
        `primary-icon-button--${theme}`,
        className,
        {
            "primary-icon-button--loading": loading,
            "primary-icon-button--disabled": disabled
        }
    );

    return (
        <button
            type="button"
            className={classes}
            disabled={disabled}
            onClick={handleOnClick}
            {...props}
        >
            {React.cloneElement(icon, {
                className: classNames(
                    "primary-icon-button__icon",
                    icon.props.className
                )
            })}
            {loading && <div className="primary-icon-button__loader" />}
        </button>
    );
};

PrimaryIconButton.propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    theme: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.node.isRequired,
    loading: PropTypes.bool,
    onClick: PropTypes.func
};

PrimaryIconButton.Size = PrimaryButtonSize;
PrimaryIconButton.Theme = PrimaryButtonTheme;

export default PrimaryIconButton;
