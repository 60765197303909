import React from "react";

import PropTypes from "prop-types";

import Select from "@components/select/Select";

import SupportedDashboardLanguage from "@core/enums/SupportedDashboardLanguage";

const DashboardLanguageSelect = ({ className, value, onChange }) => {
    const options = [
        SupportedDashboardLanguage.English,
        SupportedDashboardLanguage.French
    ];

    const getLabel = (option) => {
        switch (option) {
            case SupportedDashboardLanguage.English:
                return "English (U.S.)";
            case SupportedDashboardLanguage.French:
                return "Français (Canada)";
            default:
                throw new Error(`Language (${option}) not supported`);
        }
    };

    const renderValue = (option) => {
        return getLabel(option);
    };

    const renderOption = (option, isKeyboardFocused, onClick) => {
        return (
            <Select.Option key={option} onClick={onClick} selected={value === option} isKeyboardFocused={isKeyboardFocused}>
                {getLabel(option)}
            </Select.Option>
        );
    };

    return (
        <Select className={className}
            value={value}
            renderValue={renderValue}
            options={options}
            renderOption={renderOption}
            onChange={onChange} />
    );
};

DashboardLanguageSelect.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default DashboardLanguageSelect;