import React, { useEffect, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useKeyboard, { Handled } from "@hooks/useKeyboard";

import LabeledFormField from "@components/form/LabeledFormField";
import ValidatedFormField from "@components/form/ValidatedFormField";
import Input from "@components/input/Input";

import ApiException from "@core/api-client/ApiException";
import KeyCode from "@core/enums/KeyCodes";
import * as ensure from "@core/utils/validation";

import SignupSlide from "./SignupSlide";

import "./signup-password-slide.scss";

const dummyReadOnlyPassword = "password";

const SignupPasswordSlide = ({ className, canEdit, slideIndex, totalSlides, onSubmit, onPreviousSlideClick, onNextSlideClick}) => {
    const { t } = useTranslation("activities");
    const [isPasswordError, setIsPasswordError] = useState(false);
    const [internalPassword, setInternalPassword] = useState(canEdit ? null : dummyReadOnlyPassword);
    const [internalConfirmPassword, setInternalConfirmPassword] = useState(canEdit ? null : dummyReadOnlyPassword);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [showValidationErrors, setShowValidationErrors] = useState(false);
    const validations = {
        password: canEdit
            ? ensure.all(
                ensure.isNotNullOrEmpty(internalPassword, t("onboarding.passwordFieldRequiredError")),
                ensure.isTrue(internalPassword?.length >= 12, t("onboarding.passwordFieldMinLengthError")),
                ensure.isTrue(internalPassword?.length <= 50, t("onboarding.passwordFieldMaxLengthError")),
                ensure.isTrue(!isPasswordError, t("onboarding.passwordCannotBeUsed")))
            : ensure.success(),
        confirmPassword: canEdit
            ? ensure.isTrue(internalConfirmPassword && internalConfirmPassword === internalPassword, t("onboarding.confirmPasswordFieldNotMatchingError"))
            : ensure.success()
    };
    const isValid = validations.password.isValid && validations.confirmPassword.isValid;

    const handleOnSubmit = async() => {
        if (!canEdit) {
            onNextSlideClick();

            return;
        }

        if (isValid) {
            try {
                setShowValidationErrors(false);
                setIsSubmitting(true);

                await onSubmit(internalPassword);

                if (isPasswordError) {
                    return;
                }
                
                onNextSlideClick();
            }
            catch (ex) {
                setIsSubmitting(false);
                if (ex instanceof ApiException && ex.errorCode === "save_password_failed") {
                    setIsPasswordError(true);
                    setShowValidationErrors(true);
                }
                else {
                    throw ex;
                }
            }
        }
        else {
            setShowValidationErrors(true);
        }
    };

    useKeyboard({
        [KeyCode.Enter]: () => {
            handleOnSubmit();

            return Handled;
        }
    });

    useEffect(() => {
        setIsPasswordError(false);
    }, [internalPassword, setIsPasswordError]);

    const classes = classNames(
        "signup-password-slide",
        className
    );

    return (
        <SignupSlide className={classes}>
            <SignupSlide.TitleHeader className="signup-password-slide__header"
                title={t("onboarding.passwordSlideTitle")}
                subtitle={t("onboarding.passwordSlideSubtitle")} />
            <SignupSlide.Form className="signup-password-slide__form">
                <div className="signup-password-slide__form-row">
                    <LabeledFormField label={t("onboarding.passwordFieldLabel")}>
                        <ValidatedFormField message={validations.password.message} isValid={validations.password.isValid} enabled={showValidationErrors}>
                            <Input type="password" placeholder={t("onboarding.passwordFieldPlaceholder")} value={internalPassword || ""} onChange={e => setInternalPassword(e.target.value)} disabled={!canEdit} error={showValidationErrors && !validations.password.isValid} id="st-password-input"/>
                        </ValidatedFormField>
                    </LabeledFormField>
                </div>
                <div className="signup-password-slide__form-row">
                    <LabeledFormField label={t("onboarding.confirmPasswordFieldLabel")}>
                        <ValidatedFormField message={validations.confirmPassword.message} isValid={validations.confirmPassword.isValid} enabled={showValidationErrors}>
                            <Input type="password" placeholder={t("onboarding.confirmPasswordFieldPlacholder")} value={internalConfirmPassword || ""} onChange={e => setInternalConfirmPassword(e.target.value)} disabled={!canEdit} error={showValidationErrors && !validations.confirmPassword.isValid} id="st-confirm-password-input"/>
                        </ValidatedFormField>
                    </LabeledFormField>
                </div>
            </SignupSlide.Form>
            <SignupSlide.Footer className="signup-password-slide__footer"
                currentStepIndex={slideIndex}
                totalSteps={totalSlides}
                showNextButton
                showPreviousButton
                onPreviousClick={onPreviousSlideClick}
                onNextClick={handleOnSubmit}
                isSubmitting={isSubmitting}
                nextButtonId="st-password-slide-next-button" />
        </SignupSlide>
    );
};

SignupPasswordSlide.propTypes = {
    className: PropTypes.string,
    canEdit: PropTypes.bool.isRequired,
    slideIndex: PropTypes.number.isRequired,
    totalSlides: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onPreviousSlideClick: PropTypes.func.isRequired,
    onNextSlideClick: PropTypes.func.isRequired
};

export default SignupPasswordSlide;