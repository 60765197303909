import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import useDelayedEffect from "@hooks/useDelayedEffect";

import TwoWaySliderQuestion from "@components/slider-question/TwoWaySliderQuestion";

import BaseDeibSurveyQuestion from "./BaseDeibSurveyQuestion";

import "./deib-survey-two-way-slider-question.scss";

interface Props {
    className?: string;
    question: string;
    initialValue?: number | null;
    minLabel: React.ReactNode;
    maxLabel: React.ReactNode;
    onChange: (value: number) => void;
    onAnimationCompleted: () => void;
    onIntroAnimationCompleted: () => void;
    imageUrls: string[];
}

const DeibSurveyTwoWaySliderQuestion = ({
    className,
    question,
    initialValue,
    minLabel,
    maxLabel,
    onChange,
    onAnimationCompleted,
    onIntroAnimationCompleted,
    imageUrls
}: Props) => {
    useDelayedEffect(() => {
        onIntroAnimationCompleted();
    }, [], 900);

    const classes = classNames(
        "deib-survey-two-way-slider-question",
        className,
        "deib-survey-two-way-slider-question--animated-intro"
    );

    return (
        <BaseDeibSurveyQuestion className={classes} question={question}>
            <TwoWaySliderQuestion className="deib-survey-two-way-slider-question__slider"
                initialValue={initialValue}
                imageUrls={imageUrls}
                minLabel={minLabel}
                maxLabel={maxLabel}
                onChange={onChange}
                onAnimationCompleted={onAnimationCompleted}
            />
        </BaseDeibSurveyQuestion>
    );
};

DeibSurveyTwoWaySliderQuestion.propTypes = {
    className: PropTypes.string,
    question: PropTypes.string.isRequired,
    initialValue: PropTypes.number,
    minLabel: PropTypes.node.isRequired,
    maxLabel: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    onAnimationCompleted: PropTypes.func.isRequired,
    onIntroAnimationCompleted: PropTypes.func.isRequired,
    imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default DeibSurveyTwoWaySliderQuestion;