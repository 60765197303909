
import type { BuiltCustomPollActivity, CustomPollFollowUpQuestionStep, CustomPollIntroStep, CustomPollQuestionStep } from "@/types/activities/custom-polls";

import ActivityType from "@core/enums/ActivityType";
import CustomPollStepType from "@core/enums/CustomPollStepType";

import type { TestCustomPollDetails } from "./buildTestCustomPollActivity";
import EmptyCustomPollException from "./EmptyCustomPollException";

const buildCustomPollActivity = <TCustomPollDetails extends TestCustomPollDetails = TestCustomPollDetails>(customPoll: TCustomPollDetails): BuiltCustomPollActivity => {
    if (!customPoll) {
        throw new EmptyCustomPollException();
    }

    const questionSteps = customPoll.questions.map(q => {
        const questionStep: CustomPollQuestionStep = {
            questionId: q.questionId,
            displayType: q.type,
            question: q.question,
            minLabel: q.minLabel,
            maxLabel: q.maxLabel,
            choices: q.choices,
            hasCustomChoice: q.hasCustomChoice,
            allowsMultipleChoices: q.allowsMultipleChoices,
            stepType: CustomPollStepType.AskQuestion,
            askFollowUpQuestion: !!q.followUpQuestion,
            isSentByCompany: customPoll.isSentByCompany,
            sentByName: customPoll.sentByName,
            sentByImageUrl: customPoll.sentByImageUrl
        };

        if (questionStep.askFollowUpQuestion) {
            const askFollowUpQuestionStep: CustomPollFollowUpQuestionStep = {
                questionId: q.questionId,
                followUpQuestion: q.followUpQuestion!,
                stepType: CustomPollStepType.AskFollowUpQuestion,
                isSentByCompany: customPoll.isSentByCompany,
                sentByName: customPoll.sentByName,
                sentByImageUrl: customPoll.sentByImageUrl
            };

            return [questionStep, askFollowUpQuestionStep];
        }

        return questionStep;
    });

    return {
        customPoll,
        activityType: ActivityType.CustomPoll as const,
        customPollId: customPoll.customPollId,
        steps: [
            {
                stepType: CustomPollStepType.Intro,
                title: customPoll.title,
                description: customPoll.description,
                isSentByCompany: customPoll.isSentByCompany,
                sentByName: customPoll.sentByName,
                sentByImageUrl: customPoll.sentByImageUrl,
                isSentByAuthorDeleted: !!customPoll.isSentByAuthorDeleted,
                totalQuestions: customPoll.totalQuestions
            } satisfies CustomPollIntroStep,
            ...questionSteps
        ].flat()
    };
};

export default buildCustomPollActivity;
