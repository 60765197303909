import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import { ReactComponent as Illustration } from "@svg/illustrations/illustration-preview-completed.svg";
import { ReactComponent as IllustrationLegacy } from "@svg/illustrations/legacy/illustration-preview-completed.svg";

import "./preview-completed-outro.scss";

interface Props {
    className?: string;
}

const PreviewCompletedOutro = ({ className }: Props) => {
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);
    const { t } = useTranslation("activities");

    const classes = classNames(
        "preview-completed-outro",
        className
    );

    return (
        <div className={classes}>
            <div className="preview-completed-outro__content">
                {isWorkleapBrandEnabled
                    ? <Illustration className="preview-completed-outro__image" />
                    : <IllustrationLegacy className="preview-completed-outro__image" />}
                <div className="preview-completed-outro__title">
                    {t("outro.preview-completed-outro-title")}
                </div>
                <div className="preview-completed-outro__subtitle">
                    {t("outro.preview-completed-outro-subtitle")}
                </div>
            </div>
        </div>
    );
};

PreviewCompletedOutro.propTypes = {
    className: PropTypes.string
};

export default PreviewCompletedOutro;