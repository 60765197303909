import type { TFunction } from "react-i18next";

import ActivityType from "@core/enums/ActivityType";
import PulseSurveyStepType from "@core/enums/PulseSurveyStepType";

import buildPulseSurveyQuestion from "./pulseSurveyQuestionBuilder";
import type { PulseSurveyQuestionId } from "./types";

const buildTestPulseSurveyQuestionActivity = (
    questionId: PulseSurveyQuestionId,
    t: TFunction<"activities">
) => {
    const question = {
        ...buildPulseSurveyQuestion(questionId, t),
        askFollowUpQuestion: true
    };

    const {
        constructiveFollowUpQuestion,
        positiveFollowUpQuestion,
        ...stepData
    } = question;

    const askQuestionStep = {
        ...stepData,
        stepType: PulseSurveyStepType.AskQuestion
    };

    const askFollowUpQuestionStep = {
        questionId: askQuestionStep.questionId,
        constructiveFollowUpQuestion,
        positiveFollowUpQuestion,
        stepType: PulseSurveyStepType.AskFollowUpQuestion
    };

    return {
        activityType: ActivityType.TestPulseSurveyQuestion as const,
        correlationId: "test-correlation-id",
        steps: [askQuestionStep, askFollowUpQuestionStep],
        questionIds: [question.questionId],
        customizationWord: { sourceWord: "test", replacementWord: "test" },
        isPostSignupSurveyComplete: true,
        microCustomizations: []
    };
};

export default buildTestPulseSurveyQuestionActivity;
