import React from "react";

import PropTypes from "prop-types";

import Tag from "@components/tag/Tag";

import RecognitionCategoryTheme from "@core/enums/RecognitionCategoryTheme";

interface Props extends React.ComponentProps<typeof Tag> {
    categoryName: string;
    categoryTheme: RecognitionCategoryTheme;
}

const RecognitionCategoryTag = ({ categoryName, categoryTheme, ...otherProps }: Props) => {
    return (
        <Tag {...otherProps} theme={categoryTheme}>
            {categoryName}
        </Tag>
    );
};

RecognitionCategoryTag.propTypes = {
    categoryName: PropTypes.string.isRequired,
    categoryTheme: PropTypes.oneOf(Object.values(RecognitionCategoryTheme)).isRequired
};

export default RecognitionCategoryTag;