import React from "react";

import PropTypes from "prop-types";

interface Props{
    className?: string;
    prompt: string;
}

const RecognitionPrompt = ({ className, prompt }: Props) => {
    return (
        <span className={className}>
            {prompt}
        </span>
    );
};

RecognitionPrompt.propTypes = {
    className: PropTypes.string,
    prompt: PropTypes.string.isRequired
};

export default RecognitionPrompt;