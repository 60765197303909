import React from "react";

import IconChevronLeft from "@igloo-ui/icons/dist/ChevronLeft";
import IconChevronRight from "@igloo-ui/icons/dist/ChevronRight";
import classNames from "classnames";
import PropTypes from "prop-types";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import PrimaryIconButton from "@components/button/PrimaryIconButton";
import Stepper from "@components/stepper/Stepper";

import "./signup-slide-footer.scss";

const SignupSlideFooter = ({ className, currentStepIndex, showPreviousButton, showNextButton, totalSteps, onPreviousClick, onNextClick, isSubmitting, nextButtonId }) => {
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);

    const classes = classNames(
        "signup-slide-footer",
        className, {
            "signup-slide-footer--with-previous-button": showPreviousButton,
            "signup-slide-footer--with-next-button": showNextButton
        }
    );

    return (
        <div className={classes}>
            <div className="signup-slide-footer__right-section">
                <PrimaryIconButton className="signup-slide-footer__next-button"
                    icon={<IconChevronRight size={isWorkleapBrandEnabled ? "large" : "medium" } />}
                    size={PrimaryIconButton.Size.Small}
                    onClick={onNextClick}
                    loading={isSubmitting}
                    id={nextButtonId} />
            </div>
            <div className="signup-slide-footer__middle-section">
                <Stepper currentStepIndex={currentStepIndex} totalSteps={totalSteps} />
            </div>
            <div className="signup-slide-footer__left-section">
                <PrimaryIconButton className="signup-slide-footer__previous-button"
                    icon={<IconChevronLeft size={isWorkleapBrandEnabled ? "large" : "medium" } />}
                    size={PrimaryIconButton.Size.Small}
                    onClick={onPreviousClick}
                    disabled={isSubmitting} />
            </div>
        </div>
    );
};

SignupSlideFooter.propTypes = {
    className: PropTypes.string,
    showPreviousButton: PropTypes.bool,
    showNextButton: PropTypes.bool,
    currentStepIndex: PropTypes.number.isRequired,
    totalSteps: PropTypes.number.isRequired,
    onPreviousClick: PropTypes.func,
    onNextClick: PropTypes.func,
    isSubmitting: PropTypes.bool,
    nextButtonId: PropTypes.string
};

export default SignupSlideFooter;