import type { BuiltRecognitionActivity } from "@/types/activities/recognition";

export default (
    activity: BuiltRecognitionActivity,
    isFirstActivity: boolean,
    isLastActivity: boolean
) => ({
    isFirstActivity,
    isLastActivity,
    currentStepIndex: 0,
    promptId: null,
    category: null,
    message: null,
    ...activity,
    steps: activity.steps.map((s, i) => ({
        ...s,
        isFirstStep: i === 0,
        isLastStep: i === activity.steps.length - 1
    }))
});
