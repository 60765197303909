import React, { useEffect } from "react";

import type { SmartQuestionActivityType } from "@/types/activities/smart-question";
import PropTypes from "prop-types";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";

import SmartQuestion from "@components/smart-question/SmartQuestion";

import ActivityType from "@core/enums/ActivityType";
import { trackActivityCompleted, trackActivityStarted, trackFeedbackGiven, trackFeedbackShown } from "@core/tracking/track";
import TrackingInteractionType from "@core/tracking/TrackingInteractionType";

interface Props {
    className?: string;
    activityType: SmartQuestionActivityType;
    isFirstActivity: boolean;
    isLastActivity: boolean;
    subMetricId: string;
    metricName: string;
    question: string;
    isAnswered: boolean;
    onChangeFeedbackAnonymity: (isAnonymous: boolean) => void;
    onFeedbackLeft: (subMetricId: string, feedback: string, isAnonymous: boolean) => void;
    onActivityEnded: (subMetricId: string) => void;
    onGoToNextActivity: () => void;
    onGoToOutro: () => void;
    isIntroAnimated: boolean;
    onIntroAnimationCompleted: () => void;
}

const SmartQuestionActivity = ({ className, activityType, isFirstActivity, isLastActivity, subMetricId, metricName, question, isAnswered, onFeedbackLeft, onChangeFeedbackAnonymity, onActivityEnded, onGoToNextActivity, onGoToOutro, isIntroAnimated, onIntroAnimationCompleted }: Props) => {
    const { isNetworkAdmin, isCompanyManager, isTeamManager } = useAuthenticatedUser();

    useEffect(() => {
        trackActivityStarted(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, isFirstActivity);
        trackFeedbackShown(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, undefined, subMetricId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOnFeedbackLeft = (feedback: string, isAnonymous: boolean) => {
        trackFeedbackGiven(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, TrackingInteractionType.Answered, isAnonymous, undefined, subMetricId);

        onFeedbackLeft(subMetricId, feedback, isAnonymous);
    };

    const handleOnAnonymityChanged = (isAnonymous: boolean) => {
        onChangeFeedbackAnonymity(isAnonymous);
    };

    const handleOnAnimationCompleted = () => {
        trackActivityCompleted(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, isFirstActivity);
        onActivityEnded(subMetricId);

        if (isLastActivity) {
            onGoToOutro();
        } else {
            onGoToNextActivity();
        }
    };

    return (
        <div className={className}>
            <SmartQuestion
                isIntroAnimated={isIntroAnimated}
                onIntroAnimationCompleted={onIntroAnimationCompleted}
                question={question}
                metricName={metricName}
                isAnswered={isAnswered}
                onChangeIsAnonymous={handleOnAnonymityChanged}
                onSubmit={handleOnFeedbackLeft}
                onAnimationCompleted={handleOnAnimationCompleted}
            />
        </div>
    );
};

SmartQuestionActivity.propTypes = {
    className: PropTypes.string,
    activityType: PropTypes.oneOf(Object.values(ActivityType)).isRequired,
    isFirstActivity: PropTypes.bool.isRequired,
    isLastActivity: PropTypes.bool.isRequired,
    subMetricId: PropTypes.string.isRequired,
    metricName: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    isAnswered: PropTypes.bool.isRequired,
    onChangeFeedbackAnonymity: PropTypes.func.isRequired,
    onFeedbackLeft: PropTypes.func.isRequired,
    onActivityEnded: PropTypes.func.isRequired,
    onGoToNextActivity: PropTypes.func.isRequired,
    onGoToOutro: PropTypes.func.isRequired,
    isIntroAnimated: PropTypes.bool.isRequired,
    onIntroAnimationCompleted: PropTypes.func.isRequired
};

export default SmartQuestionActivity;