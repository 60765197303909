import React from "react";

import classNames from "classnames";
import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";

import { useResponsiveContext } from "@contexts/responsive/ResponsiveContext";

import type { ActivePrompt } from "@api/RecognitionActivityApiClient";
import RecognitionCategoryImage from "@core/enums/RecognitionCategoryImage";
import RecognitionCategoryTheme from "@core/enums/RecognitionCategoryTheme";

import RecognitionCategorySvgImage from "./RecognitionCategorySvgImage";
import RecognitionCategoryTag from "./RecognitionCategoryTag";
import RecognitionPrompt from "./RecognitionPrompt";

import "./recognition-card-image.scss";

interface Props {
    className?: string;
    selectedPrompt: ActivePrompt;
}

const RecognitionCardImage = ({ className, selectedPrompt }: Props) => {
    const { small } = useResponsiveContext();

    const classes = classNames(
        "recognition-card-image",
        `recognition-card-image--${kebabCase(selectedPrompt.categoryTheme)}`,
        className
    );

    return (
        <div className={classes}>
            <RecognitionCategoryTag categoryName={selectedPrompt.categoryName} categoryTheme={selectedPrompt.categoryTheme} isCompact={!small} />
            <RecognitionPrompt className="recognition-card-image__prompt" prompt={selectedPrompt.prompt} />
            {selectedPrompt.categoryImage && <RecognitionCategorySvgImage className="recognition-card-image__image" image={selectedPrompt.categoryImage} />}
        </div>
    );
};

RecognitionCardImage.propTypes = {
    className: PropTypes.string,
    selectedPrompt: PropTypes.shape({
        categoryImage: PropTypes.oneOf(Object.values(RecognitionCategoryImage)),
        categoryName: PropTypes.string.isRequired,
        categoryTheme: PropTypes.oneOf(Object.values(RecognitionCategoryTheme)).isRequired,
        prompt: PropTypes.string.isRequired
    }).isRequired
};

export default RecognitionCardImage;