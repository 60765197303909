import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./signup-slide-form.scss";

const SignupSlideForm = ({ className, children }) => {
    const classes = classNames(
        "signup-slide-form",
        className
    );

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

SignupSlideForm.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired
};

export default SignupSlideForm;