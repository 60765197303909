import React, { useState, useEffect } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";
import useDelayedEffect from "@hooks/useDelayedEffect";
import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import PrimaryButton from "@components/button/PrimaryButton";

import { trackLinkClicked, trackRedirected } from "@core/tracking/track";

import { ReactComponent as Illustration } from "@svg/illustrations/illustration-survey-completed.svg";

import "./recognition-outro.scss";

interface Props {
    className?: string;
    onRedirectToDashboard: () => void;
    isRecognitionSkipped: boolean;
}

const RecognitionOutro = ({ className, onRedirectToDashboard, isRecognitionSkipped = false }: Props) => {
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);
    const { t } = useTranslation("activities");
    const [shouldStartTimer, setShouldStartTimer] = useState(false);
    const [redirectTimer, setRedirectTimer] = useState(5);

    const { isNetworkAdmin, isCompanyManager, isTeamManager } = useAuthenticatedUser();

    useDelayedEffect(() => {
        setShouldStartTimer(true);
    }, [], 1000);

    useEffect(() => {
        const startTimer = async() => {
            if (redirectTimer > 0) {
                const timeout = setTimeout(
                    () => setRedirectTimer(rt => rt - 1),
                    1000
                );

                return () => {
                    clearTimeout(timeout);
                };
            }

            await trackRedirected(isNetworkAdmin, isCompanyManager, isTeamManager, "RecognitionOutro");
            onRedirectToDashboard();
        };

        if (shouldStartTimer) {
            startTimer();
        }
    }, [shouldStartTimer, redirectTimer]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOnClick = async() => {
        await trackLinkClicked(isNetworkAdmin, isCompanyManager, isTeamManager, "RecognitionOutro", "Go to Officevibe");
        onRedirectToDashboard();
    };

    const classes = classNames(
        "recognition-outro",
        className
    );

    return (
        <div className={classes}>
            <div className="recognition-outro__content">
                {isWorkleapBrandEnabled && (
                    isRecognitionSkipped
                        ? <div className="recognition-outro__placeholder"></div>
                        : <Illustration className="recognition-outro__image" />
                )}
                <div className="recognition-outro__title">
                    {isRecognitionSkipped
                        ? t("outro.recognition-outro-title-skipped")
                        : t("outro.recognition-outro-title")
                    }
                </div>
                <div className="recognition-outro__redirect-timer-label">
                    <Trans ns="activities"
                        i18nKey="outro.recognition-outro-redirection-label"
                        values={{ count: redirectTimer }}
                        components={[<span key={0} className="recognition-outro__remaining-seconds-before-redirect">REPLACED BY I18N</span>]}
                    />
                </div>
                <PrimaryButton
                    className="recognition-outro__go-to-officevibe-button"
                    onClick={handleOnClick}
                >
                    {t("outro.recognition-outro-go-to-officevibe")}
                </PrimaryButton>
            </div>
        </div>
    );
};

RecognitionOutro.propTypes = {
    className: PropTypes.string,
    onRedirectToDashboard: PropTypes.func.isRequired,
    isRecognitionSkipped: PropTypes.bool
};

export default RecognitionOutro;