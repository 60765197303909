import React, { createRef, Fragment, type RefObject, useEffect, useRef, useState } from "react";

import { AngleUpIcon, SearchIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import PropTypes from "prop-types";

import useClickOutside from "@hooks/useClickOutside";
import useDelayedEffect from "@hooks/useDelayedEffect";
import useKeyboard, { Handled, NotHandled } from "@hooks/useKeyboard";

import Popover from "@components/popover/Popover";

import type { UserIdentity } from "@api/RecognitionActivityApiClient";
import KeyCode from "@core/enums/KeyCodes";

import PeerSelectOption from "./components/PeerSelectOption";
import PeerSelectPlaceholder from "./components/PeerSelectPlaceholder";
import PeerSelectPopoverEmptyState from "./components/PeerSelectPopoverEmptyState";
import PeerSelectPopoverLoading from "./components/PeerSelectPopoverLoading";
import PeerSelectValue from "./components/PeerSelectValue";

import "@core/utils/linqExtensions";

import "./peer-select.scss";

interface Props {
    id?: string;
    className?: string;
    placeholder: string;
    searchPeersAsync: (search: string) => Promise<UserIdentity[]>;
    onChange: (userId: string) => void;
    noResultsLabel: React.ReactNode;
    error?: boolean;
}

const PeerSelect = ({ id, className, placeholder, searchPeersAsync, onChange, noResultsLabel, error }: Props) => {
    const controlRef = useRef<HTMLButtonElement>(null);
    const searchBarRef = useRef<HTMLInputElement>(null);
    const popoverRef = useRef<HTMLDivElement>(null);

    const searchedOptionsRefs = useRef<RefObject<HTMLButtonElement>[]>([]);

    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState<string | null>(null);
    const [isSearching, setIsSearching] = useState(false);
    const [searchedPeers, setSearchedPeers] = useState<UserIdentity[]>([]);
    const [value, setValue] = useState<UserIdentity | null>(null);
    const [keyboardFocus, setKeyboardFocus] = useState<number | null>(null);

    const shouldDisplaySearchResultsLoadingState = !!search && isSearching;
    const shouldDisplaySearchResults = !!search && !isSearching;
    const shouldDisplaySearchResultsEmptyState = !!search && !isSearching && searchedPeers.length === 0;
    const shouldDisplayChevronIcon = !!search;
    const shouldDisplaySearchIcon = !value || isOpen;

    const totalSearchedPeers = searchedPeers ? searchedPeers.length : 0;
    useEffect(() => {
        searchedOptionsRefs.current = Array.from({ length: totalSearchedPeers }, (_, i) => searchedOptionsRefs.current[i] || createRef());
    }, [totalSearchedPeers]);

    const scrollIntoView = (index: number) => {
        if (shouldDisplaySearchResults) {
            searchedOptionsRefs.current[index].current?.scrollIntoView({ block: "nearest" });
        }
    };

    const getTotalOptions = () => {
        if (shouldDisplaySearchResults) {
            return totalSearchedPeers;
        }

        return 0;
    };

    const getSelectedIndex = () => {
        if (!value) {
            return null;
        }

        if (shouldDisplaySearchResults) {
            const result = searchedPeers.findIndex(x => x.userId === value.userId);

            return result === -1 ? null : result;
        }

        return null;
    };

    const manageDuplicatedNames = (peers: UserIdentity[]) => {
        const duplicatedNamesMap = Object.groupBy(peers, ({ fullName }) => fullName);

        return peers.map(p => {
            const isDuplicate = duplicatedNamesMap[p.fullName]!.length > 1;

            return {
                ...p,
                isDuplicate
            };
        });
    };

    useEffect(() => {
        setSearch(null);
        setKeyboardFocus(null);
    }, [isOpen]);

    useDelayedEffect(() => {
        const doSearch = async(s: string) => {
            try {
                const results = await searchPeersAsync(s);

                setSearchedPeers(manageDuplicatedNames(results));
                setIsSearching(false);
            } catch (ex) {
                setSearchedPeers([]);
                setIsSearching(false);
            }
        };

        if (search) {
            doSearch(search);
        }
    }, [search], 400);

    useClickOutside([controlRef, popoverRef], () => {
        setIsOpen(false);
    }, false);

    const handleOnControlFocus = () => {
        setIsOpen(true);
        searchBarRef.current?.focus();
    };

    const handleOnControlClick = () => {
        if (!isOpen) {
            setIsOpen(true);
            searchBarRef.current?.focus();
        }
    };

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        const newSearch = e.target.value;

        setKeyboardFocus(null);
        setSearch(newSearch);

        if (newSearch) {
            setIsSearching(true);
        } else {
            setIsSearching(false);
            setSearchedPeers([]);
        }
    };

    const handleOnChange = (peer: UserIdentity) => {
        setValue(peer);
        setIsOpen(false);

        onChange(peer.userId);
    };

    const closeDropdown = () => {
        setIsOpen(false);
        searchBarRef.current?.blur();
    };

    useKeyboard({
        [`${KeyCode.Enter}|${KeyCode.Tab}`]: () => {
            if (!isOpen) {
                return NotHandled;
            }

            if (keyboardFocus === null) {
                closeDropdown();

                return NotHandled;
            }

            if (shouldDisplaySearchResults) {
                handleOnChange(searchedPeers[keyboardFocus]);
                searchBarRef.current?.blur();

                return Handled;
            }

            return NotHandled;
        },
        [KeyCode.ArrowUp]: () => {
            if (!isOpen || getTotalOptions() === 0) {
                return NotHandled;
            }

            let currentValue = keyboardFocus;

            if (currentValue === null) {
                currentValue = getSelectedIndex();
            }

            if (currentValue === null || currentValue === 0) {
                setKeyboardFocus(getTotalOptions() - 1);
                scrollIntoView(getTotalOptions() - 1);
            } else {
                setKeyboardFocus(currentValue - 1);
                scrollIntoView(currentValue - 1);
            }

            return Handled;
        },
        [KeyCode.ArrowDown]: () => {
            if (!isOpen || getTotalOptions() === 0) {
                return NotHandled;
            }

            let currentValue = keyboardFocus;

            if (currentValue === null) {
                currentValue = getSelectedIndex();
            }

            if (currentValue === null || currentValue === getTotalOptions() - 1) {
                setKeyboardFocus(0);
                scrollIntoView(0);
            } else {
                setKeyboardFocus(currentValue + 1);
                scrollIntoView(currentValue + 1);
            }

            return Handled;
        },
        [KeyCode.Escape]: () => {
            if (!isOpen) {
                return NotHandled;
            }

            closeDropdown();

            return Handled;
        }
    }, controlRef);

    const renderPopover = () => {
        if (!isOpen) {
            return null;
        }

        let content = null;

        if (shouldDisplaySearchResultsLoadingState) {
            content = <PeerSelectPopoverLoading />;
        } else if (shouldDisplaySearchResultsEmptyState) {
            content = (
                <PeerSelectPopoverEmptyState>
                    {noResultsLabel}
                </PeerSelectPopoverEmptyState>
            );
        } else if (shouldDisplaySearchResults) {
            content = (
                <Fragment>
                    {searchedPeers.map((p, i) => (
                        <PeerSelectOption {...p}
                            key={p.userId}
                            ref={searchedOptionsRefs.current[i]}
                            selected={!!value && p.userId === value.userId}
                            isKeyboardFocused={i === keyboardFocus}
                            onClick={() => handleOnChange(p)}
                        />
                    ))}
                </Fragment>
            );
        }

        if (!content) {
            return null;
        }

        return (
            <Popover ref={popoverRef} className="peer-select__popover">
                {content}
            </Popover>
        );
    };

    const renderValue = () => {
        if (isOpen) {
            return null;
        }

        if (value) {
            return <PeerSelectValue {...value} className="peer-select__value" />;
        }

        return (
            <PeerSelectPlaceholder className="peer-select__placeholder">
                {placeholder}
            </PeerSelectPlaceholder>
        );
    };

    const classes = classNames(
        "peer-select",
        className, {
            "peer-select--open": isOpen,
            "peer-select--readonly": !isOpen,
            "peer-select--error": error
        }
    );

    return (
        <div className={classes}>
            <button type="button" ref={controlRef} className="peer-select__control" onFocus={handleOnControlFocus} onClick={handleOnControlClick}>
                {shouldDisplaySearchIcon && (
                    <div className="peer-select__icon">
                        <SearchIcon size="sm" />
                    </div>
                )}
                {renderValue()}
                <input id={id}
                    ref={searchBarRef}
                    className="peer-select__search-bar"
                    placeholder={placeholder}
                    value={search || ""}
                    onChange={handleOnSearchChange}
                    tabIndex={-1}
                />
                {shouldDisplayChevronIcon && (
                    <button type="button" className="peer-select__control-arrow" onClick={closeDropdown}>
                        <AngleUpIcon size="sm" />
                    </button>
                )}
            </button>
            {renderPopover()}
        </div>
    );
};

PeerSelect.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.node.isRequired,
    searchPeersAsync: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    noResultsLabel: PropTypes.node.isRequired,
    error: PropTypes.bool
};

PeerSelect.defaultProps = {
    error: false
};

export default PeerSelect;