import React from "react";

import PropTypes from "prop-types";

import PulseSurveyLikertQuestion from "@components/pulse-survey-question/PulseSurveyLikertQuestion";
import PulseSurveyMultipleChoiceQuestion from "@components/pulse-survey-question/PulseSurveyMultipleChoiceQuestion";
import PulseSurveySliderQuestion from "@components/pulse-survey-question/PulseSurveySliderQuestion";
import PulseSurveyStarRatingQuestion from "@components/pulse-survey-question/PulseSurveyStarRatingQuestion";
import PulseSurveyTwoWaySliderQuestion from "@components/pulse-survey-question/PulseSurveyTwoWaySliderQuestion";

import mapPulseSurveyValueToScore from "@core/activities/pulse-survey/mapPulseSurveyValueToScore";
import type PulseSurveyQuestionDisplayType from "@core/enums/PulseSurveyQuestionDisplayType";
import DisplayType from "@core/enums/PulseSurveyQuestionDisplayType";

interface Props {
    displayType: PulseSurveyQuestionDisplayType;
    questionId: string;
    question: string;
    minLabel?: string;
    maxLabel?: string;
    labels?: string[];
    imageUrls?: string[];
    answer?: number | null;
    onSliderQuestionAnswered: (value: number, score: number) => void;
    onTwoWaySliderQuestionAnswered: (value: number, score: number) => void;
    onStarQuestionAnswered: (value: number, score: number) => void;
    onLikertQuestionAnswered: (value: number, score: number) => void;
    onMultipleChoiceQuestionAnswered: (value: number, score: number) => void;
    isIntroAnimated: boolean;
    onIntroAnimationCompleted: () => void;
    onAnimationCompleted: () => void;
}

const PulseSurveyActivityQuestionStep = ({
    displayType,
    questionId,
    question,
    answer,
    labels,
    imageUrls,
    minLabel,
    maxLabel,
    onSliderQuestionAnswered,
    onTwoWaySliderQuestionAnswered,
    onStarQuestionAnswered,
    onLikertQuestionAnswered,
    onMultipleChoiceQuestionAnswered,
    isIntroAnimated,
    onIntroAnimationCompleted,
    onAnimationCompleted
}: Props) => {
    const handleOnSubmit = (callback: (value: number, score: number) => void) => (value: number) => {
        callback(value, mapPulseSurveyValueToScore(displayType, value));
    };

    switch (displayType) {
        // HACK: Our props are polymorphic based on displayType. This wasn't an
        // issue when we were in plain JavaScript, but this understandably makes
        // TypeScript balk. Until we decide to refactor this, we'll just use
        // non-null assertions (!). [EP-1904]
        case DisplayType.Slider:
            return (
                <PulseSurveySliderQuestion
                    key={questionId}
                    question={question}
                    minLabel={minLabel}
                    maxLabel={maxLabel}
                    onChange={handleOnSubmit(onSliderQuestionAnswered)}
                    initialValue={answer}
                    isIntroAnimated={isIntroAnimated}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        case DisplayType.TwoWaySlider:
            return (
                <PulseSurveyTwoWaySliderQuestion
                    key={questionId}
                    question={question}
                    minLabel={minLabel}
                    maxLabel={maxLabel}
                    imageUrls={imageUrls!}
                    onChange={handleOnSubmit(onTwoWaySliderQuestionAnswered)}
                    initialValue={answer}
                    isIntroAnimated={isIntroAnimated}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        case DisplayType.Stars:
            return (
                <PulseSurveyStarRatingQuestion
                    key={questionId}
                    question={question}
                    onChange={handleOnSubmit(onStarQuestionAnswered)}
                    initialValue={answer}
                    isIntroAnimated={isIntroAnimated}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        case DisplayType.LikertScale:
            return (
                <PulseSurveyLikertQuestion
                    key={questionId}
                    question={question}
                    labels={labels!}
                    onChange={handleOnSubmit(onLikertQuestionAnswered)}
                    initialValue={answer}
                    isIntroAnimated={isIntroAnimated}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        case DisplayType.MultipleChoice:
            return (
                <PulseSurveyMultipleChoiceQuestion
                    key={questionId}
                    question={question}
                    labels={labels!}
                    imageUrls={imageUrls!}
                    onChange={handleOnSubmit(onMultipleChoiceQuestionAnswered)}
                    initialValue={answer}
                    isIntroAnimated={isIntroAnimated}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        default:
            throw new Error(`Display Type (${displayType}) not supported`);
    }
};

PulseSurveyActivityQuestionStep.propTypes = {
    displayType: PropTypes.string.isRequired,
    questionId: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    minLabel: PropTypes.string,
    maxLabel: PropTypes.string,
    labels: PropTypes.array,
    imageUrls: PropTypes.array,
    answer: PropTypes.any,
    onSliderQuestionAnswered: PropTypes.func.isRequired,
    onTwoWaySliderQuestionAnswered: PropTypes.func.isRequired,
    onStarQuestionAnswered: PropTypes.func.isRequired,
    onLikertQuestionAnswered: PropTypes.func.isRequired,
    onMultipleChoiceQuestionAnswered: PropTypes.func.isRequired,
    isIntroAnimated: PropTypes.bool.isRequired,
    onIntroAnimationCompleted: PropTypes.func.isRequired,
    onAnimationCompleted: PropTypes.func.isRequired
};

export default PulseSurveyActivityQuestionStep;