import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import SignupSlideImageHeaderTheme from "./SignupSlideImageHeaderTheme";

import "./signup-slide-image-header.scss";

const SignupSlideImageHeader = ({ className, image, theme }) => {
    const classes = classNames(
        "signup-slide-image-header",
        `signup-slide-image-header--${theme}`,
        className
    );

    return (
        <div className={classes}>
            {React.cloneElement(image, { className: classNames("signup-slide-image-header__image", image.props.className) })}
        </div>
    );
};

SignupSlideImageHeader.propTypes = {
    className: PropTypes.string,
    image: PropTypes.node.isRequired,
    theme: PropTypes.string
};

SignupSlideImageHeader.Theme = SignupSlideImageHeaderTheme;

SignupSlideImageHeader.defaultProps = {
    theme: SignupSlideImageHeaderTheme.Coral
};

export default SignupSlideImageHeader;