import React from "react";

import type { GuidString } from "@/types/utilityTypes";

export interface ActivitiesUserContext {
    userId: GuidString;
    email: string;
    fullName: string;
    userName: string;
    imageUrl: string;
    isNetworkAdmin: boolean;
    isCompanyManager: boolean;
    isTeamManager: boolean;
    isNetworkCreator: boolean;
    intercomIdentityToken: string;
    language: string;
    organisationId: GuidString;
    isSignupComplete: boolean;
    isOnboardingComplete: boolean;
    isRecognitionDisabled: boolean;
    isMigratedToWorkleap: boolean;
}

export interface UserContextState {
    isInitialized: boolean;
    isLoading: boolean;
    context: ActivitiesUserContext | null;
    initializeUserContextAsync: () => Promise<void>;
    markSignupAsCompleted: () => void;
}

const UserContext = React.createContext<UserContextState | null>(null);

export default UserContext;

export const useUserContext = () => {
    const context = React.useContext(UserContext);

    if (!context) {
        throw new Error("UserContext not found; are you missing UserContextProvider?");
    }

    return context;
};