import React from "react";

import { AngleRightIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useDelayedEffect from "@hooks/useDelayedEffect";
import useImageResizeUrl from "@hooks/useImageResizeUrl";
import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import { useUserContext } from "@contexts/user/UserContext";

import PrimaryButton from "@components/button/PrimaryButton";
import ImageSizes from "@components/user-image/ImageSizes";

import { ReactComponent as DeletedAvatar } from "@svg/deleted-avatar.svg";
import { ReactComponent as DeletedAvatarLegacy } from "@svg/legacy/deleted-avatar.svg";

import "./custom-poll-intro.scss";

interface Props {
    className?: string;
    isSentByCompany: boolean;
    sentByName: string;
    sentByImageUrl?: string;
    isSentByAuthorDeleted?: boolean;
    title: string;
    description?: string;
    totalQuestions: number;
    onGoToNextStep: () => void;
    isIntroAnimated: boolean;
    onIntroAnimationCompleted: () => void;
}

const CustomPollIntro = ({
    className,
    isSentByCompany,
    sentByName,
    sentByImageUrl,
    isSentByAuthorDeleted,
    title,
    description,
    totalQuestions,
    onGoToNextStep,
    isIntroAnimated,
    onIntroAnimationCompleted
}: Props) => {
    const { t } = useTranslation("activities");
    const defaultImageResizeUrl = useImageResizeUrl(
        sentByImageUrl,
        ImageSizes.XLarge
    );
    const isWorkleapBrandEnabled = useIsFeatureEnabled(
        feature => feature.useWorkleapBrand
    );
    const { context: userContext } = useUserContext();

    useDelayedEffect(
        () => {
            if (isIntroAnimated) {
                onIntroAnimationCompleted();
            }
        },
        [],
        1300
    );

    const classes = classNames("custom-poll-intro", className, {
        "custom-poll-intro--animated-intro": isIntroAnimated
    });

    // WL - To know if the custom poll author is deleted, we check if sentByAuthorDeleted is true
    // OV - To know if the custom poll author is deleted, we have to check if there's a sentByImageUrl
    const areCustomPollSentByAuthorAndAuthorDeleted =
    userContext?.isMigratedToWorkleap
        ? !isSentByCompany && isSentByAuthorDeleted
        : !isSentByCompany && !sentByImageUrl;

    const imageUrl = defaultImageResizeUrl ?? `${window.env.CDN_URL}/assets/default-profile-icon.png`;

    return (
        <div className={classes}>
            {areCustomPollSentByAuthorAndAuthorDeleted ? (
                isWorkleapBrandEnabled ? (
                    <DeletedAvatar className="custom-poll-intro__author-thumbnail" />
                ) : (
                    <DeletedAvatarLegacy className="custom-poll-intro__author-thumbnail" />
                )
            ) : (
                <img
                    className={
                        isSentByCompany
                            ? "custom-poll-intro__company-logo-thumbnail"
                            : "custom-poll-intro__author-thumbnail"
                    }
                    src={imageUrl}
                    aria-hidden="true"
                    alt=""
                />
            )}
            <div className="custom-poll-intro__title">
                {t("customPollFrame.custom-poll-intro-page-title")}
            </div>
            <div className="custom-poll-intro__description">
                {t("customPollFrame.custom-poll-intro-page-description", {
                    senderName: areCustomPollSentByAuthorAndAuthorDeleted
                        ? t("customPollFrame.custom-poll-intro-page-deleted-author", {
                            senderName: sentByName
                        })
                        : sentByName
                })}
            </div>
            {!isWorkleapBrandEnabled && (
                <div className="custom-poll-intro__separator" />
            )}
            <div className="custom-poll-intro__details">
                <div className="custom-poll-intro__poll-title">{title}</div>
                {description && (
                    <div className="custom-poll-intro__poll-description">
                        {description}
                    </div>
                )}
            </div>
            <div className="custom-poll-intro__footer">
                <PrimaryButton
                    className="custom-poll-intro__answer-button"
                    iconTrailing={<AngleRightIcon size="md" />}
                    onClick={onGoToNextStep}
                >
                    {t("customPollFrame.custom-poll-intro-page-cta")}
                </PrimaryButton>
                <div className="custom-poll-intro__total-questions">
                    {t("customPollFrame.custom-poll-intro-page-questions-count", {
                        count: totalQuestions
                    })}
                </div>
            </div>
        </div>
    );
};

CustomPollIntro.propTypes = {
    className: PropTypes.string,
    isIntroAnimated: PropTypes.bool.isRequired,
    isSentByCompany: PropTypes.bool.isRequired,
    sentByName: PropTypes.string.isRequired,
    sentByImageUrl: PropTypes.string,
    isSentByAuthorDeleted: PropTypes.bool,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    totalQuestions: PropTypes.number.isRequired,
    onGoToNextStep: PropTypes.func.isRequired,
    onIntroAnimationCompleted: PropTypes.func.isRequired
};

export default CustomPollIntro;
