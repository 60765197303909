import React, { useCallback, useState } from "react";

import classNames from "classnames";

import QuestionAskedBy from "@components/question-asked-by/QuestionAskedBy";

import { ReactComponent as DeletedAvatar } from "@svg/deleted-avatar.svg";

import "./custom-poll-question-asked-by.scss";

interface Props {
    className?: string;
    question: string;
    isSentByCompany: boolean;
    sentByImageUrl?: string;
}

const CustomPollQuestionAskedBy = ({ className, question, isSentByCompany, sentByImageUrl }: Props) => {
    // To know if the custom poll author is deleted, we have to check if there's a sentByImageUrl
    const areCustomPollSentByAuthorAndAuthorDeleted = !isSentByCompany && !sentByImageUrl;
    const [imageOrientationClass, setImageOrientationClass] = useState("custom-poll-question-asked-by__author--landscape");

    const imageDimensionRef = useCallback((node: HTMLImageElement) => {
        if (node !== null) {
            const rect = node.getBoundingClientRect();
            if (rect.height > rect.width) {
                setImageOrientationClass("custom-poll-question-asked-by__author--portrait");
            }
        }
    }, []);

    const authorThumbnail = areCustomPollSentByAuthorAndAuthorDeleted
        ? <DeletedAvatar className="custom-poll-question-asked-by__author" />
        : <img ref={imageDimensionRef} className={classNames("custom-poll-question-asked-by__author", imageOrientationClass)} src={sentByImageUrl} alt="" aria-hidden="true" />;

    const classes = classNames(
        "custom-poll-question-asked-by",
        className
    );

    return (
        <QuestionAskedBy
            className={classes}
            question={question}
            icon={authorThumbnail}
        />
    );
};

export default CustomPollQuestionAskedBy;