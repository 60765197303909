import React, { useState } from "react";

import type { CustomPollMultipleChoiceAnswer } from "@/types/activities/custom-polls";
import { CheckmarkIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import PrimaryButton from "@components/button/PrimaryButton";

import MultipleChoiceConstants from "@core/enums/CustomPollMultipleChoiceQuestion";
import InputType from "@core/enums/InputType";
import KeyCodes from "@core/enums/KeyCodes";
import sleep from "@core/utils/sleep";

import BaseCustomPollQuestion from "./BaseCustomPollQuestion";
import MultipleChoiceQuestionCustomChoice from "./custom-choice/MultipleChoiceQuestionCustomChoice";
import MultipleChoiceQuestionChoice from "./MultipleChoiceQuestionChoice";

import "./custom-poll-multiple-choice-question-with-multi-selection.scss";

interface Props {
    className?: string;
    question: string;
    initialSelectedChoices?: CustomPollMultipleChoiceAnswer[];
    initialCustomAnswer?: string | null;
    choices: string[];
    hasCustomChoice: boolean;
    onSubmit: (selectedChoices: CustomPollMultipleChoiceAnswer[], customAnswer: string | null) => void;
    onAnimationCompleted: () => void;
}

const CustomPollMultipleChoiceQuestionWithMultiSelection = ({
    className,
    question,
    initialSelectedChoices,
    initialCustomAnswer,
    choices,
    hasCustomChoice,
    onSubmit,
    onAnimationCompleted
}: Props) => {
    const isWorkleapBrandEnabled = useIsFeatureEnabled(
        feature => feature.useWorkleapBrand
    );
    const { t } = useTranslation("activities");
    const [confirmed, setConfirmed] = useState(false);
    const [selectedChoices, setSelectedChoices] = useState(
        initialSelectedChoices ?? []
    );
    const [customAnswer, setCustomAnswer] = useState(initialCustomAnswer ?? null);
    const [isCustomAnswerInEditMode, setIsCustomAnswerInEditMode] =
    useState(false);
    const [focusedValue, setFocusedValue] = useState<number | null>(null);

    const handleConfirm = async() => {
        setConfirmed(true);

        onSubmit(selectedChoices, customAnswer);
        await sleep(700);
        onAnimationCompleted();
    };

    const handleOnChoiceToggle = (choiceValue: CustomPollMultipleChoiceAnswer) => {
        if (confirmed) {
            return;
        }

        setSelectedChoices(current => {
            return current.includes(choiceValue)
                ? current.filter(v => v !== choiceValue)
                : [...current, choiceValue];
        });
    };

    const handleConfirmOnClick = (value: CustomPollMultipleChoiceAnswer, e: React.MouseEvent) => {
        e.preventDefault();
        handleOnChoiceToggle(value);
    };

    const handleConfirmOnEnter = (value: CustomPollMultipleChoiceAnswer, e: React.KeyboardEvent) => {
        if (e.keyCode === KeyCodes.Enter) {
            handleOnChoiceToggle(value);
        }
    };

    const handleCustomAnswerConfirm = (newCustomAnswer: string) => {
        setSelectedChoices(current => {
            return current.includes(MultipleChoiceConstants.CustomChoiceId)
                ? current
                : [...current, MultipleChoiceConstants.CustomChoiceId];
        });
        setCustomAnswer(newCustomAnswer);
        setIsCustomAnswerInEditMode(false);
    };

    const handleCustomAnswerClear = () => {
        setSelectedChoices(current =>
            current.filter(v => v !== MultipleChoiceConstants.CustomChoiceId)
        );
        setCustomAnswer(null);
        setIsCustomAnswerInEditMode(false);
    };

    const handleOnInputBlur = () => {
        setFocusedValue(null);
    };

    const renderChoice = (label: string, i: number) => {
        const currentValue = i;

        return (
            <div
                key={`multiple-choice-choice-${currentValue}`}
                className="custom-poll-multiple-choice-question-with-multi-selection__choice"
                role={InputType.Checkbox}
                aria-checked={selectedChoices.includes(currentValue)}
            >
                <MultipleChoiceQuestionChoice
                    inputType={InputType.Checkbox}
                    value={currentValue}
                    label={label}
                    isConfirmed={confirmed}
                    isSelected={selectedChoices.includes(currentValue)}
                    isFocused={currentValue === focusedValue}
                    checked={
                        focusedValue !== null
                            ? currentValue === focusedValue
                            : selectedChoices.includes(currentValue)
                    }
                    onFocus={() => setFocusedValue(currentValue)}
                    onBlur={handleOnInputBlur}
                    onKeyDown={e => handleConfirmOnEnter(currentValue, e)}
                    onClick={e => handleConfirmOnClick(currentValue, e)}
                />
            </div>
        );
    };

    const classes = classNames(
        "custom-poll-multiple-choice-question-with-multi-selection",
        className,
        {
            "custom-poll-multiple-choice-question-with-multi-selection--confirmed":
        confirmed,
            "custom-poll-multiple-choice-question-with-multi-selection--dense":
        isWorkleapBrandEnabled && choices.length >= 10
        }
    );

    return (
        <BaseCustomPollQuestion className={classes} question={question}>
            <div className="custom-poll-multiple-choice-question-with-multi-selection__multi-select-info">
                {t(
                    "customPollMultipleChoiceQuestionWithMultiSelection.questionSubtitle"
                )}
            </div>
            <div
                className="custom-poll-multiple-choice-question-with-multi-selection__input"
                role="group"
            >
                {choices.map(renderChoice)}
                {hasCustomChoice && (
                    <MultipleChoiceQuestionCustomChoice
                        inputType={InputType.Checkbox}
                        initialCustomAnswer={customAnswer}
                        isInEditMode={isCustomAnswerInEditMode}
                        onConfirmAnswer={handleCustomAnswerConfirm}
                        onClearAnswer={handleCustomAnswerClear}
                        onToggleEditMode={isEditMode =>
                            setIsCustomAnswerInEditMode(isEditMode)
                        }
                        isSelected={selectedChoices.includes(
                            MultipleChoiceConstants.CustomChoiceId
                        )}
                        isConfirmed={confirmed}
                    />
                )}
            </div>
            <PrimaryButton
                className="custom-poll-multiple-choice-question-with-multi-selection__confirm"
                size={PrimaryButton.Size.Small}
                theme={
                    isWorkleapBrandEnabled
                        ? PrimaryButton.Theme.Dandelion
                        : PrimaryButton.Theme.Coral
                }
                iconLeading={<CheckmarkIcon size="md" />}
                onClick={handleConfirm}
                disabled={
                    isCustomAnswerInEditMode || (selectedChoices?.length ?? 0) < 1
                }
            >
                {t(
                    "customPollMultipleChoiceQuestionWithMultiSelection.confirmButtonLabel"
                )}
            </PrimaryButton>
        </BaseCustomPollQuestion>
    );
};

CustomPollMultipleChoiceQuestionWithMultiSelection.propTypes = {
    className: PropTypes.string,
    question: PropTypes.string.isRequired,
    initialSelectedChoices: PropTypes.arrayOf(PropTypes.number),
    initialCustomAnswer: PropTypes.string,
    choices: PropTypes.arrayOf(PropTypes.string).isRequired,
    hasCustomChoice: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onAnimationCompleted: PropTypes.func.isRequired
};

export default CustomPollMultipleChoiceQuestionWithMultiSelection;
