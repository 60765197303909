import type { GuidString } from "@/types/utilityTypes";

import { buildApiClient } from "@core/api-client/RetryableBearerApiClient";
import type QuestionDisplayType from "@core/enums/QuestionDisplayType";

export interface OpinionScaleDetails {
    minLabel: string;
    maxLabel: string;
    choices: null;
    type: QuestionDisplayType.OpinionScale;
}

export interface MultipleChoiceDetails {
    choices: string[];
    type: QuestionDisplayType.MultipleChoice;
    minLabel: null;
    maxLabel: null;
}

export interface LikertScaleDetails {
    choices: string[];
    type: QuestionDisplayType.LikertScale;
    minLabel: null;
    maxLabel: null;
}

export interface TextDetails {
    type: QuestionDisplayType.Text;
    choices: null;
    minLabel: null;
    maxLabel: null;
}

export type QuestionDetails = {
    questionId: GuidString;
    question: string;
    followUpQuestion: string | null;
    hasCustomChoice: boolean;
    allowsMultipleChoices: boolean;
} & (
    | OpinionScaleDetails
    | MultipleChoiceDetails
    | LikertScaleDetails
    | TextDetails
);

export interface CustomPollDetails {
    customPollId: GuidString;
    title: string;
    description: string;
    isSentByCompany: boolean;
    sentByName: string;
    sentByImageUrl: string;
    isSentByAuthorDeleted?: boolean;
    totalQuestions: number;
    questions: QuestionDetails[];
}

export interface CustomPollList {
    customPolls: CustomPollDetails[];
}

export async function listActivitiesAsync(): Promise<CustomPollList> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).getAsync(
        "/api/v1/activities/custom-polls"
    );
}

export interface CustomPollActivityResponse {
    customPoll: CustomPollDetails;
}

export async function getActivityAsync(
    customPollId: string
): Promise<CustomPollActivityResponse> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).getAsync(
        `/api/v1/activities/custom-polls/${customPollId}`
    );
}

export async function getPreviewActivityAsync(
    customPollId: string
): Promise<CustomPollActivityResponse> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).getAsync(
        `/api/v1/activities/custom-polls/${customPollId}/preview`
    );
}

export type CustomPollsActivityControllerSaveAnswerRequest = {
    isAnonymous?: boolean;
} & (
    | { value?: number } // Opinion Scale questions
    | { choices: number[]; customChoiceAnswer: string | null } // Multiple choice questions with optional custom choice
    | { feedback: string } // Text questions
);

export async function saveAnswerAsync(
    customPollId: string,
    questionId: string,
    answer: CustomPollsActivityControllerSaveAnswerRequest
): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(
        `/api/v1/activities/custom-polls/${customPollId}/${questionId}`,
        answer
    );
}

export async function skipAnswerAsync(
    customPollId: string,
    questionId: string
): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(
        `/api/v1/activities/custom-polls/${customPollId}/${questionId}/skip`
    );
}

export async function deleteTextAnswerAsync(
    customPollId: string,
    questionId: string
): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).deleteAsync(
        `/api/v1/activities/custom-polls/${customPollId}/${questionId}`
    );
}

interface CustomPollsActivityControllerSaveFollowUpRequest {
    feedback: string;
    isAnonymous: boolean;
}

export async function saveFollowUpAnswerAsync(
    customPollId: string,
    questionId: string,
    feedback: string,
    isAnonymous: boolean
): Promise<void> {
    const request: CustomPollsActivityControllerSaveFollowUpRequest = {
        feedback,
        isAnonymous
    };

    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(
        `/api/v1/activities/custom-polls/${customPollId}/${questionId}/follow-up`,
        request
    );
}

export async function skipFollowUpAnswerAsync(
    customPollId: string,
    questionId: string
): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(
        `/api/v1/activities/custom-polls/${customPollId}/${questionId}/follow-up/skip`
    );
}

export async function deleteFollowUpAnswerAsync(
    customPollId: string,
    questionId: string
): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).deleteAsync(
        `/api/v1/activities/custom-polls/${customPollId}/${questionId}/follow-up`
    );
}

export async function activityEndedAsync(customPollId: string): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(
        `/api/v1/activities/custom-polls/${customPollId}/ended`
    );
}
