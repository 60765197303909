import React from "react";

import PropTypes from "prop-types";

import LikertQuestion from "@components/likert-question/LikertQuestion";

import BaseCustomPollQuestion from "./BaseCustomPollQuestion";

interface Props {
    className?: string;
    question: string;
    initialValue?: number;
    choices: string[];
    onSubmit: (value: number) => void;
    onAnimationCompleted: () => void;
}

const CustomPollLikertScaleQuestion = ({
    className,
    question,
    initialValue,
    choices,
    onSubmit,
    onAnimationCompleted
}: Props) => (
    <BaseCustomPollQuestion className={className} question={question}>
        <LikertQuestion
            initialValue={initialValue}
            labels={choices}
            onChange={onSubmit}
            onAnimationCompleted={onAnimationCompleted}
        />
    </BaseCustomPollQuestion>
);

CustomPollLikertScaleQuestion.propTypes = {
    className: PropTypes.string,
    question: PropTypes.string.isRequired,
    initialValue: PropTypes.number,
    choices: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onAnimationCompleted: PropTypes.func.isRequired
};

export default CustomPollLikertScaleQuestion;
