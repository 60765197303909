import DisplayType from "@core/enums/DeibSurveyQuestionDisplayType";

import type { DeibQuestionID } from "./types";

const map: Record<DeibQuestionID, DisplayType> = {
    "DSQ-1": DisplayType.MultipleChoice, // Does your organization show that diversity is important through concrete actions?
    "DSQ-2": DisplayType.LikertScale, // I believe [network.displayname] is a diverse organization.
    "DSQ-3": DisplayType.TwoWaySlider, // On a scale from 0-10, how much does your organization respect people’s individual differences?.
    "DSQ-4": DisplayType.LikertScale, // Our organization makes sure that the opinions of individuals from different backgrounds are heard.
    "DSQ-5": DisplayType.LikertScale, // Working at our organization taught me to appreciate people’s individual differences.
    "DSQ-6": DisplayType.MultipleChoice, // Do you think everyone gets treated fairly within our organization?
    "DSQ-7": DisplayType.MultipleChoice, // Are you treated fairly within our organization?.
    "DSQ-8": DisplayType.LikertScale, // Team members who help our organization achieve its objectives are rewarded and recognized fairly.
    "DSQ-9": DisplayType.LikertScale, // Everyone in our organization has equal opportunities to succeed..
    "DSQ-10": DisplayType.LikertScale, // Managers offer equal opportunities to people of all different backgrounds.
    "DSQ-11": DisplayType.TwoWaySlider, // On a scale from 0-10, how inclusive is our organization’s inclusive culture?
    "DSQ-12": DisplayType.LikertScale, // I am valued for my uniqueness.
    "DSQ-13": DisplayType.TwoWaySlider, // On a scale from 0-10, how comfortable are you sharing opinions at work that differ from the group?
    "DSQ-14": DisplayType.MultipleChoice, // Do you think that people with different points of view are valued in your team?
    "DSQ-15": DisplayType.TwoWaySlider, // On a scale from 0-10, how much effort does your manager put into creating an inclusive environment for your team?
    "DSQ-16": DisplayType.LikertScale, // I feel like I belong at our organization.
    "DSQ-17": DisplayType.LikertScale, // People in my organization care about me.
    "DSQ-18": DisplayType.TwoWaySlider, // On a scale from 0-10, how connected do you feel to your organization?
    "DSQ-19": DisplayType.MultipleChoice, // Do you feel accepted as a member of your team?
    "DSQ-20": DisplayType.MultipleChoice // Do you feel you can bring your authentic self to work?
};

const mapQuestionIdToDisplayType = (questionId: DeibQuestionID): DisplayType => {
    if (Object.prototype.hasOwnProperty.call(map, questionId)) {
        return map[questionId];
    }

    throw new Error(`Question Id (${questionId}) not found in mapping`);
};

export default mapQuestionIdToDisplayType;