import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";

import InteractionBox from "@components/interaction-box/InteractionBox";
import QuestionAskedBy from "@components/question-asked-by/QuestionAskedBy";

import { ReactComponent as OvAvatar } from "@svg/ov-avatar.svg";

import "./pulse-survey-custom-text-question.scss";

export interface Props {
    className?: string;
    question: string;
    isAnswered: boolean;
    initialFeedback?: string;
    initialIsAnonymous?: boolean;
    onChangeAnonymity: (newIsAnonymous: boolean) => void;
    onSubmit: (feedback: string, isAnonymous: boolean) => void;
    onIntroAnimationCompleted: () => void;
    onAnimationCompleted: () => void;
}

const PulseSurveyCustomTextQuestion = ({
    className,
    question,
    isAnswered,
    initialFeedback,
    initialIsAnonymous,
    onChangeAnonymity,
    onSubmit,
    onIntroAnimationCompleted,
    onAnimationCompleted
}: Props) => {
    const { t } = useTranslation("activities");
    const { imageUrl, fullName } = useAuthenticatedUser();

    const [confirmed, setConfirmed] = useState(false);

    const handleOnSubmit = async(feedback: string, isAnonymous: boolean) => {
        if (confirmed) {
            return;
        }

        setConfirmed(true);
        onSubmit(feedback, isAnonymous);
        onAnimationCompleted();
    };

    const handleOnChangeAnonymity = (newIsAnonymous: boolean) => {
        if (confirmed) {
            return;
        }

        onChangeAnonymity(newIsAnonymous);
    };

    const classes = classNames(
        "pulse-survey-custom-text-question",
        className, {
            "pulse-survey-custom-text-question--confirmed": confirmed
        }
    );

    useEffect(() => {
        onIntroAnimationCompleted();
    }, [onIntroAnimationCompleted]);

    return (
        <div className={classes}>
            <QuestionAskedBy
                className="pulse-survey-custom-text-question__header"
                question={question}
                icon={<OvAvatar />}
            />
            <InteractionBox
                initialFeedback={initialFeedback}
                initialIsAnonymous={initialIsAnonymous}
                authorImageUrl={imageUrl}
                authorFullName={fullName}
                placeholder={t("pulseSurveyCustomTextQuestion.feedback-input-placeholder")}
                submitLabel={isAnswered ? t("pulseSurveyCustomTextQuestion.update-feedback-button-label") : t("pulseSurveyCustomTextQuestion.send-feedback-button-label")}
                onSubmit={handleOnSubmit}
                onChangeIsAnonymous={handleOnChangeAnonymity}
                disabled={confirmed}
                allowEmptyFeedback={isAnswered}
            />
        </div>
    );
};

export default PulseSurveyCustomTextQuestion;